import React, { useState, useRef, useEffect } from 'react';
import Prism from 'prismjs';
import '../../../../constants/prism.css';	// downloaded from https://prismjs.com/download.html

/*Class Prefix = code-block*/
import './styles.css'


Prism.hooks.add('before-sanity-check', function (env) {
    env.code = env.element.innerText;
});

const CodeBlock = (props) => {
	const { content: initialContent, onChange, editing, viewOnly } = props;	// extract props
	const courseColor = "blue";

	// instantiate state variables
	const [displayLanguage, setDisplayLanguage] = useState('javascript');
	const [content, setContent] = useState('');
	const inputRef = useRef(null);

	// handle clicking on the block
	const handleClick = () => {
		if (editing || viewOnly)	return;	// if currently editing do nothing

		// otherwise set up starting to edit
		setDisplayLanguage('text');
		inputRef.current.focus();
	}

	// handles editing the block
	const handleChange = (event) => {
		if (!editing)
			return true;
		const value = event.target.innerText;
		setContent(value);
		onChange({ content: value });
	}

	// callback for keyDown
	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {	// check if the key pressed was Tab
			e.preventDefault();	// maintain focus on text area
			const newValue = content + '\t';
			setContent(newValue);
			onChange({ content: newValue });

			// this inserts a tab somehow
			const selection = window.getSelection();
			const range = selection.getRangeAt(0);
			const tabNode = document.createTextNode('\t');
			range.insertNode(tabNode);
			range.setStartAfter(tabNode);
			range.setEndAfter(tabNode);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}


	useEffect(() => {
		if (initialContent !== content) {
			inputRef.current.innerText = initialContent;	// set the value
			// inputRef.current.blur();
			// inputRef.current.focus();	// if editing focus the div
		}
		// run highlighting
		Prism.highlightAll(false);
	}, [initialContent])

	return (
		<div
			onClick={handleClick}
			className={`code-block-root ${editing ? 'code-block-root-edit' : ''}`}
			style={{ borderColor: courseColor }}
		>
			<pre >
				<code
					// className={`code-block-input`}
					className={`code-block-input language-${displayLanguage}`}
					defaultValue={initialContent}
					contentEditable
					ref={inputRef}
					onInput={handleChange}
					onKeyDown={handleKeyDown}
					tabIndex={0}
				/>
			</pre>
		</div>);
}

export default CodeBlock; 