import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const LINED_PAPER_HEIGHT = 20;

const BASE_THEME = {
	typography: {
		fontFamily: [
			'Poppins',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Oxygen',
    	'Ubuntu',
			'Cantarell',
			'Fira Sans',
			'Droid Sans',
			'Helvetica Neue',
			'sans-serif',
		],
	},
	overrides: {
		MuiButton: {
			root: {
				fontWeight: 500,
			},
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 16,
			},
		},
	},
	props: {
		MuiTypography: {
			color: 'textPrimary',	
		},
		MuiCircularProgress: {
			color: 'primary',
		},
		MuiTooltip: {
			enterDelay: 1000,
			enterNextDelay: 1000,
		},
	}
}

const JOTLY_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			// main: '#6FA278',
			// main: '#006241',
			// main: '#27474E',	// dark slate grey
			// main: '#ADA9B7',	// silver metallic
			main: '#53A2BE',	// 
			contrastText: '#fff',
		},
		secondary: {
			// main: '#aa47bc',
			// main: '#334195',	// dark conrflower blue
			main: '#27474E',	// dark slate grey
			// main: '#FBF2C0',	// blond
			// main: '#ffb101',	// bumblbee
			// main: '#3D5A6C',	// buff
			// main: '#272727',	//
			contrastText: '#fff',
		},
		// text: {
		// 	primary: "#333",
		// },
	},
});

const DARK_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		type: 'dark',
		primary: {
			main: '#616161',
			contrastText: '#fff',
		},
		secondary: {
			// main: '#43a047',
			main: '#53A2BE',	// 
			contrastText: '#000',
		},
	},
});

const FLORAL_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#ffa4b6',
			contrastText: '#333',
		},
		secondary: {
			main: '#165baa',	// 
			contrastText: '#fff',
		},
	},
});

const PROFESSIONAL_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#8c9ea3',
			contrastText: '#333',
		},
		secondary: {
			main: '#2a4158',	// 
			contrastText: '#fff',
		},
		background: {
			default: '#e5e5ee',
		},
	},
});

const RUSTIC_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#895a4d',
			contrastText: '#333',
		},
		secondary: {
			main: '#85865f',	// 
			contrastText: '#333',
		},
		background: {
			default: '#fafafa',
		},
	},
});

const ROBOTIC_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#78909c',
			contrastText: '#000'
		},
		secondary: {
			main: '#ef6c00',
			contrastText: '#000'
		},
		background: {
			default: '#e5e5ee',
		},
	},
});

const LEGACY_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#6FA278',
			contrastText: '#333'
		},
		secondary: {
			main: '#aa47bc',
			contrastText: '#fff'
		},
		background: {
			default: '#e5e5ee',
		},
	},
});

const MIKE_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#78909c',
			contrastText: '#000'
		},
		secondary: {
			main: '#ef6c00',
			contrastText: '#000'
		},
	},
})

const TREV_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#d8b572',
			contrastText: '#000'
		},
		secondary: {
			main: '#96b3c9',
			contrastText: '#000'
		},
	},
});

const CLEMSON_THEME = createMuiTheme({
	...BASE_THEME,
	palette: {
		primary: {
			main: '#F56600',
			contrastText: '#000'
		},
		secondary: {
			main: '#522D80',
			contrastText: '#fff'
		},

	},
});

const themes = {
	'jotly': JOTLY_THEME,
	'dark': DARK_THEME,
	'mike': MIKE_THEME,
	'trev': TREV_THEME,
	'floral': FLORAL_THEME,
	'professional': PROFESSIONAL_THEME,
	'rustic': RUSTIC_THEME,
	'robotic': ROBOTIC_THEME,
	'legacy': LEGACY_THEME,
	'clemson': CLEMSON_THEME,
}

export const getMUIThemeFromName = (name) => {
	return themes[name] || JOTLY_THEME;
}