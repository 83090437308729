import { DEFAULT_ENRICHMENT_PREFS } from '../../components/EnrichmentPrefs';
import Mixpanel from '../../utils/mixpanel';
import * as actions from './actions';

// we are going to store a user's profile in here, it will look like the following
/*
	profile: {
		email: <string>,
		firstName: <string>,
		lastName: <string>,
		enrichmentPrefs: <object> // pref: isEnabled, like <string>: <bool>
		isLoading: <bool>,
		errorLoading: <bool>,
	}
*/

// first let's define the initialState for instantiation of the store
const initialState = {
	email: '',
	firstName: '',
	lastName: '',
	enrichmentPrefs: DEFAULT_ENRICHMENT_PREFS,  // since it's strings to boolean and empty object is like false for the values, kind of
	isLoading: false,
	awaitingLoad: true,
	errorLoading: false,
}


// keep in mind state is the object 'profile' points to in the above comment
const profileReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
	switch (action.type) {
		case actions.FETCHING_PROFILE:
			return { ...state, isLoading: true, errorLoading: false, awaitingLoad: false };  // destructure the previous/initial state and overwrite changing values
		case actions.FETCH_PROFILE_SUCCESS:
			if (action.payload.theme) {
				document.documentElement.setAttribute('data-theme', action.payload.theme);
			}
			
			Mixpanel.identify(action.payload.id)
			// if (action.payload.isTestAccount) {
			// 	Mixpanel.opt_out_tracking();
			// } else {
			// 	Mixpanel.identify(action.payload.id)
			// }
			return { ...state, isLoading: false, errorLoading: false, ...action.payload };  // action.payload should contain the new profile
		case actions.FETCH_PROFILE_FAIL:
			return { ...initialState, isLoading: false, errorLoading: true } // going to revert to initialState on failure, idk if that's smart or not
		case actions.CREATE_PROFILE_SUCCESS:
			const { email, firstName, lastName } = action.payload;
			return { ...state, email, firstName, lastName, awaitingLoad: false, isLoading: false  };
		case actions.CHANGE_ENRICHMENT_PREFS:
			return {
				...state,
				enrichmentPrefs: {
					...state.enrichmentPrefs,
					...action.payload,
				}
			}
		case actions.CHANGE_EMAIL:
			return { ...state, email: action.payload };
		case actions.CHANGE_THEME:
			document.documentElement.setAttribute('data-theme', action.payload)
			return { ...state, theme: action.payload };
		case actions.UPDATE_PROFILE:
			return { ...state, ...action.payload };
		case actions.SIGN_OUT:
			return { ...initialState }
		default:
			return state; // the action doesn't affect the profile store so nothing chaning
	}
}

export default profileReducer;