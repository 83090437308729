import katex from 'katex';
import React, { useRef, useEffect } from 'react';

// extraction of logic for rendering the katex output
const KatexOutput = (props) => {
	const { content, onClick } = props;
	const rootRef = useRef(null);

	const update = () => {
		katex.render(content, rootRef.current, { throwOnError: false })
	}

	// when the content changes it updates and re-renders the katex
	useEffect(() => {
		update();
	}, [content])

	return (<span ref={rootRef} onClick={onClick} />);
}

export default KatexOutput;