import { serverURL } from '../utils/utils';	
import { convertToRaw } from 'draft-js';
import firebase from '../Firebase';
import Bugsnag from '@bugsnag/js';


//----- Every endpoint will need to be chained with firebase.authenticateUser() so that the server knows whos knocking at the door -----------

const conversionUrl = serverURL() + '/convert-flashcards';
export async function convertContentStateToFlashcardDeck(contentState) {
	return firebase.authenticateUser().then(idToken => {
		return Promise.resolve(fetch(conversionUrl, {
				method: "POST",
				body: JSON.stringify({
					state: convertToRaw(contentState)
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": `Bearer ${idToken}`,
				},
	  	}
		));
	}).catch(err => {
		Bugsnag.notify(err);
		return Promise.reject(err);
	});
}

const openConversionUrl = serverURL() + '/convert-flashcards-open'
export async function openlyConvertContentStateToFlashcardDeck(contentState) {

	return fetch(openConversionUrl, {
		method: "POST",
		body: JSON.stringify({
			state: convertToRaw(contentState)
		}),
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		},
	}).catch(err => {
		Bugsnag.notify(err);
		return Promise.reject(err);
	});;
}