
export const FETCHING_COURSES = 'FETCHING_COURSES';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAIL = 'FETCH_COURSES_FAIL';

export const fetchingCourses = () => {
  return {
    type: FETCHING_COURSES,
  }
}

export const fetchCoursesSuccess = (courses) => {
  return {
    type: FETCH_COURSES_SUCCESS,
    payload: courses,
  }
}

export const fetchCoursesFail = (err) => {
  return {
    type: FETCH_COURSES_FAIL,
    payload: err,
  };
}

export const CHANGE_COURSE_NAME = 'CHANGE_COURSE_NAME';

export const changeCourseName = (courseId, name) => {
  return {
    type: CHANGE_COURSE_NAME,
    payload: {
      courseId,
      name,
    },
  }
}

export const CHANGE_COURSE_COLOR = 'CHANGE_COURSE_COLOR';

export const changeCourseColor = (courseId, color) => {
  return {
    type: CHANGE_COURSE_COLOR,
    payload: {
      courseId,
      color,
    },
  }
}

export const ADD_COURSE = 'ADD_COURSE';

export const addCourse = (course) => {
  return {
    type: ADD_COURSE,
    payload: {
      course,
    }
  }
}

export const DELETE_COURSE = 'DELETE_COURSE';

export const deleteCourse = (courseId) => {
  return {
    type: DELETE_COURSE,
    payload: {
      courseId,
    }
  }
}