import * as ROUTES from './routes';

// validates that a user is signed in otherwise redirects to login
export const hasAuthCondition = {
	validation: (authUser) => !!authUser,
	redirect: ROUTES.SIGN_IN,
};

// validates that a user is NOT signed in, redirects to /courses
export const cantHaveAuthCondition = {
	validation: (authUser) => !authUser,
	redirect: ROUTES.HOME,
}


// this is kinda twisted but basically if they don't have auth
export const redirectWithAuth = {
	validation: (authUser) => !authUser,
	redirect: `${ROUTES.HOME}`,
}

export const redirectWithoutAuth = {
	validation: (authUser) => !!authUser,
	redirect: ROUTES.LANDING,
}