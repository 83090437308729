import React, { useMemo, useState } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import Bugsnag from "@bugsnag/js";

import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {
  Box,
  IconButton,
  Divider,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatAlignCenter from "@material-ui/icons/FormatAlignCenter";
import HighlightIcon from "@material-ui/icons/Brush";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/CheckCircle";
import GridIcon from "@material-ui/icons/GridOnOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import CropSquareIcon from "@material-ui/icons/CropSquare";

// import logger from '../../../../utils/logger';
import firebase from "../../../../Firebase";
import Mixpanel, { EVENTS } from "../../../../utils/mixpanel";
import logger from "../../../../utils/logger";

import { getFilePreferences } from "../../../../data/metaData/selectors";
import {
  SHARE_FILE_DIALOG_KEY,
  PREMIUM_PLANS_DIALOG_KEY,
} from "../../../../data/dialogs/keys";

import { showDialog } from "../../../../data/dialogs/actions";
import {
  getProfile,
  getUserLimitReached,
} from "../../../../data/profile/selectors";
import { updateMetaData } from "../../../../data/metaData/actions";
import { getActiveTab } from "../../../../data/tabs/selectors";
import { changeNotebookName } from "../../../../data/notebooks/actions";
import { changeEnrichmentPrefs } from "../../../../data/profile/actions";

import NavBar from "../../../../components/NavBar";
import FontPicker from "../../../../components/FontPicker";
import { FONT_SIZES } from "../../styleMap";

import AtomicBlockTypes from "../AtomicBlockTypes";

/*Class Prefix = control-panel*/
import "./styles.css";
import EnrichmentPrefs from "../../../../components/EnrichmentPrefs";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    boxShadow: "none",
  },
  notebookHeader: {
    // maxWidth: '75%',
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& input": {
      ...theme.typography.h6,
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
    },
  },
}));

const ControlPanel = (props) => {
  const {
    onStyleChange,
    currentInlineStyle,
    onChangeAlignment,
    onListSelect,
    onChangeFontSize,
    notebookName,
    notebookId,
    onInsertAtomicBlock,
    onConvertRequest,
    handleUndo,
    handleRedo,
    handleAddLink,
    onPublish,
    course,
    currentBlock,
  } = props;

  const userLimitReached = useSelector(getUserLimitReached);
  const activeTab = useSelector(getActiveTab);
  const profile = useSelector(getProfile);
  const { enrichmentPrefs } = profile;
  const prefs = useSelector((state) =>
    getFilePreferences(state, {
      courseId: course.id,
      subsection: "notebooks",
      fileId: notebookId,
    })
  );
  const { background, showTOC, enableOtto, fontFamily, enableAutoCorrect } =
    prefs;

  const [insertsAnchorEl, setInsertsAnchorEl] = useState(null);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [showFontSizes, setShowFontSizes] = useState(false);
  const [editNotebookName, setEditNotebookName] = useState(false);
  const [localNotebookName, setLocalNotebookName] = useState(notebookName);
  const [showEnrichmentPrefs, setShowEnrichmentPrefs] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleStyleChange = (style) => (event) => {
    event.preventDefault();
    onStyleChange([style]);
  };

  const handleListSelect = (listType) => (event) => {
    event.preventDefault();
    onListSelect(listType);
  };

  const handleInsertOpenMenu = (e) => {
    setInsertsAnchorEl(e.currentTarget);
  };
  const handleCloseInsertMenu = (e) => {
    setInsertsAnchorEl(null);
  };

  const handleOptionsOpenMenu = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };
  const handleCloseOptionsMenu = (e) => {
    setOptionsAnchorEl(null);
  };

  const handleInsertClick = (value) => () => {
    Mixpanel.track(EVENTS.insertAtomic, { key: value });
    onInsertAtomicBlock(value);
    handleCloseInsertMenu();
  };

  const handleShare = () => {
    const { courseId, fileId, name } = activeTab;
    Mixpanel.track(EVENTS.openShareFileDialog);
    dispatch(
      showDialog("share-notebook", SHARE_FILE_DIALOG_KEY, {
        courseId,
        fileId,
        fileName: name,
        fileType: "notebooks",
      })
    );
  };

  // preferencesObj should be the object to store under metaData.customMetaData.preferences
  const handlePrefsChange = (pref, value) => (_e) => {
    const newMetaDataForFirebase = {
      customMetadata: { ...prefs, [pref]: value },
    };
    const newMetaDataForRedux = { preferences: { ...prefs, [pref]: value } };
    const oldMetaDataForRedux = { preferences: { ...prefs } };
    dispatch(
      updateMetaData(course.id, "notebooks", notebookId, newMetaDataForRedux)
    ); // optimistic updating
    firebase
      .updateFileMetaData(
        course.id,
        "notebooks",
        notebookId,
        newMetaDataForFirebase
      )
      .then((metaData) => {
        logger.log("updated meta data successfully", metaData);
      })
      .catch((error) => {
        dispatch(
          updateMetaData(
            course.id,
            "notebooks",
            notebookId,
            oldMetaDataForRedux
          )
        );
        Bugsnag.notify(error);
        logger.log(error);
      });
  };

  const handleFontFamilyChange = (nextFont, _backupFont) => {
    handlePrefsChange("fontFamily", nextFont)();
  };

  const handleToggleOutline = () => {
    Mixpanel.track(EVENTS.toggleOutline, { toggledTo: !showTOC });
    handlePrefsChange("showTOC", !showTOC)();
  };

  const handleToggleAutocorrect = () => {
    Mixpanel.track(EVENTS.toggleAutocorrect, { toggledTo: !enableAutoCorrect });
    handlePrefsChange("enableAutoCorrect", !enableAutoCorrect)();
  };

  const handleToggleBackground = (newBg) => {
    Mixpanel.track(EVENTS.toggleBackground, { toggledTo: newBg });
    handlePrefsChange("background", newBg)();
  };

  const handleConfirmNotebookName = (e) => {
    const newName = e?.target?.value || e;
    setEditNotebookName(false);
    firebase
      .updateNotebookInCourse(course.id, notebookId, { name: newName })
      .then((resp) => {
        logger.log("updated notebook preferences", resp);
        dispatch(changeNotebookName(notebookId, newName));
      })
      .catch((err) => {
        Bugsnag.notify(err);
        logger.error("Error updating notebook name: ", err);
      });
  };

  let activeFontSize =
    parseInt(
      Array.from(currentInlineStyle)
        .find((style) => style.includes("FONT_SIZE"))
        ?.replace("FONT_SIZE_", "")
    ) || 16;
  activeFontSize = FONT_SIZES.includes(activeFontSize) ? activeFontSize : 16;
  //console.log(Array.from(currentInlineStyle), activeFontSize)

  const handleFontSizeMouseDown = (fs) => (e) => {
    e.preventDefault();
    if (!showFontSizes) {
      setShowFontSizes(true);
      return;
    }
    if (activeFontSize !== fs) {
      onChangeFontSize(fs);
    }
    setShowFontSizes(false);
  };

  const isActive = (key) => {
    return currentInlineStyle.has(key);
  };

  const handleAlignmentChange = (newAlignment) => (e) => {
    e.preventDefault();
    onChangeAlignment(newAlignment);
  };

  const handleEnrichmentPrefsChange = (updatedPrefs) => {
    setShowEnrichmentPrefs(false);
    firebase
      .changeEnrichmentPrefs(updatedPrefs)
      .then((resp) => {
        dispatch(changeEnrichmentPrefs(updatedPrefs));
      })
      .catch((err) => {
        Bugsnag.notify(err);
        logger.log(err);
      });
  };

  const handleToggleOtto = () => {
    Mixpanel.track(EVENTS.toggleAutocomplete, { toggledTo: !enableOtto });
    handlePrefsChange("enableOtto", !enableOtto)();
  };

  const insertOptions = useMemo(
    () =>
      Object.keys(AtomicBlockTypes).map((tK) => ({
        label: AtomicBlockTypes[tK].label,
        value: tK,
      })),
    []
  );

  return (
    <NavBar
      topBar={
        <Box display="flex" id="notebook-options-container">
          <EnrichmentPrefs
            open={showEnrichmentPrefs}
            onClose={() => setShowEnrichmentPrefs(false)}
            onSubmit={handleEnrichmentPrefsChange}
            starterPrefs={enrichmentPrefs}
          />
          <Box
            display="flex"
            width={300}
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              value={localNotebookName}
              onChange={(e) => setLocalNotebookName(e.target.value)}
              onBlur={handleConfirmNotebookName}
              onFocus={() => setEditNotebookName(true)}
              InputProps={{ disableUnderline: true }}
              className={classes.notebookHeader}
              style={{
                borderBottom: editNotebookName ? "1px solid #fff" : "none",
              }}
            />
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setEditNotebookName(!editNotebookName)}
            >
              {editNotebookName ? <CheckIcon /> : <EditIcon />}
            </IconButton>
          </Box>
          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "0 8px" }}
          />
          <Tooltip
            title={"click to insert dynamic elements into your notebook"}
            enterDelay={1000}
            enterNextDelay={500}
          >
            <Button
              style={{ marginLeft: 4 }}
              variant="contained"
              onClick={handleInsertOpenMenu}
              size="small"
              endIcon={<ExpandMoreIcon />}
              color="secondary"
              id="control-panel-inserts-button"
            >
              Insert
            </Button>
          </Tooltip>
          <Menu
            id="insert-menu"
            anchorEl={insertsAnchorEl}
            open={Boolean(insertsAnchorEl)}
            onClose={handleCloseInsertMenu}
          >
            {insertOptions.map(({ label, value }) => (
              <MenuItem
                onClick={handleInsertClick(value)}
                value={value}
                key={`insert-option-${label}`}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>

          <Tooltip
            title={"see options for your notbeook"}
            enterDelay={1000}
            enterNextDelay={500}
          >
            <Button
              style={{ marginLeft: 4 }}
              variant="contained"
              onClick={handleOptionsOpenMenu}
              size="small"
              endIcon={<ExpandMoreIcon />}
              color="secondary"
              id="control-panel-options-button"
            >
              Options
            </Button>
          </Tooltip>
          <Menu
            id="options-menu"
            anchorEl={optionsAnchorEl}
            open={Boolean(optionsAnchorEl)}
            onClose={handleCloseOptionsMenu}
          >
            {false && (
              <Tooltip title={"send this notebook to a friend"}>
                <MenuItem
                  onClick={() => {
                    handleShare();
                    setOptionsAnchorEl(null);
                  }}
                >
                  Share
                </MenuItem>
              </Tooltip>
            )}
            {process.env.NODE_ENV === "development" && (
              <Tooltip title="Publish this notebook">
                <MenuItem
                  onClick={() => {
                    onPublish();
                    setOptionsAnchorEl(null);
                  }}
                >
                  Publish
                </MenuItem>
              </Tooltip>
            )}
            <MenuItem
              onClick={() => {
                setShowEnrichmentPrefs(true);
                setOptionsAnchorEl(null);
              }}
            >
              Edit Enhancements
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleToggleOutline}
                    checked={Boolean(showTOC)}
                  />
                }
                label="Outline"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleToggleOtto}
                    checked={Boolean(enableOtto)}
                  />
                }
                label="Autocomplete"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleToggleAutocorrect}
                    checked={Boolean(enableAutoCorrect)}
                  />
                }
                label="Autocorrect"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <ButtonGroup style={{ marginRight: 8 }}>
                    <Button
                      variant={background === "grid" ? "contained" : "outlined"}
                      onClick={() => handleToggleBackground("grid")}
                      size="small"
                      color="primary"
                    >
                      <GridIcon />
                    </Button>
                    <Button
                      variant={
                        background === "lined" ? "contained" : "outlined"
                      }
                      onClick={() => handleToggleBackground("lined")}
                      size="small"
                      color="primary"
                    >
                      <MenuIcon />
                    </Button>
                    <Button
                      variant={
                        background === "blank" ? "contained" : "outlined"
                      }
                      onClick={() => handleToggleBackground("blank")}
                      size="small"
                      color="primary"
                    >
                      <CropSquareIcon />
                    </Button>
                  </ButtonGroup>
                }
                label="Background"
              />
            </MenuItem>
          </Menu>

          {false && (
            <Select
              styles={{
                container: (provided, _state) => ({
                  ...provided,
                  display: "inline-flex",
                  marginLeft: 5,
                }),
                control: (provided, _state) => ({
                  ...provided,
                  width: 200,
                }),
              }}
              options={insertOptions}
              onChange={handleInsertClick}
              placeholder="Insert..."
              value={null}
            />
          )}

          <Tooltip
            enterDelay={400}
            enterNextDelay={400}
            title={
              "click to automatically convert your notes into flashcards to study"
            }
          >
            <Button
              style={{ marginLeft: 4 }}
              id="control-panel-study-button"
              onClick={onConvertRequest}
              variant="contained"
              color="secondary"
              size="small"
            >
              Study
            </Button>
          </Tooltip>

          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "0 8px", height: 32, alignSelf: "center" }}
          />
          {/* <Button
						id="control-panel-get-pro-button"
						onClick={() => {
							Mixpanel.track(EVENTS.openProDialog, { context: 'notebook' })
							dispatch(showDialog('premium-plans-notebook', PREMIUM_PLANS_DIALOG_KEY));
						}}
						variant="contained"
						color="secondary"
						size="small"
					>
						get pro
					</Button> */}
        </Box>
      }
      bottomBar={
        <Box display="flex" id="notebook-controls-container">
          <ButtonGroup>
            <Button
              color="secondary"
              size="medium"
              variant={isActive("BOLD") ? "contained" : "outlined"}
              onMouseDown={handleStyleChange("BOLD")}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="bold">
                <FormatBoldIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={isActive("UNDERLINE") ? "contained" : "outlined"}
              onMouseDown={handleStyleChange("UNDERLINE")}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="underline">
                <FormatUnderlinedIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={isActive("HIGHLIGHT") ? "contained" : "outlined"}
              onMouseDown={handleStyleChange("HIGHLIGHT")}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="highlight">
                <HighlightIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={isActive("STRIKETHROUGH") ? "contained" : "outlined"}
              onMouseDown={handleStyleChange("STRIKETHROUGH")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="strikethrough"
              >
                <StrikethroughSIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={isActive("ITALIC") ? "contained" : "outlined"}
              onMouseDown={handleStyleChange("ITALIC")}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="italicize">
                <FormatItalicIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ButtonGroup style={{ marginLeft: 4 }}>
            <Button
              color="secondary"
              size="medium"
              variant={
                currentBlock.getType() === "ordered-list-item"
                  ? "contained"
                  : "outlined"
              }
              onMouseDown={handleListSelect("ol")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="make numbered list"
              >
                <FormatListNumberedIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={
                currentBlock.getType() === "unordered-list-item"
                  ? "contained"
                  : "outlined"
              }
              onMouseDown={handleListSelect("ul")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="make bulleted list"
              >
                <FormatListBulletedIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ButtonGroup style={{ marginLeft: 4 }}>
            <Button
              color="secondary"
              size="medium"
              variant={
                currentBlock.getType() === "text-align-left"
                  ? "contained"
                  : "outlined"
              }
              onMouseDown={handleAlignmentChange("left")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="align left"
              >
                <FormatAlignLeftIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={
                currentBlock.getType() === "text-align-center"
                  ? "contained"
                  : "outlined"
              }
              onMouseDown={handleAlignmentChange("center")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="align center"
              >
                <FormatAlignCenter />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant={
                currentBlock.getType() === "text-align-right"
                  ? "contained"
                  : "outlined"
              }
              onMouseDown={handleAlignmentChange("right")}
            >
              <Tooltip
                enterDelay={1000}
                enterNextDelay={500}
                title="align right"
              >
                <FormatAlignRightIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <ButtonGroup style={{ marginLeft: 4 }}>
            <Button
              color="secondary"
              size="medium"
              variant="outlined"
              onMouseDown={handleUndo}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="undo">
                <UndoIcon />
              </Tooltip>
            </Button>
            <Button
              color="secondary"
              size="medium"
              variant="outlined"
              onMouseDown={handleRedo}
            >
              <Tooltip enterDelay={1000} enterNextDelay={500} title="redo">
                <RedoIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>

          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "0 8px", height: 32, alignSelf: "center" }}
          />

          <FontPicker value={fontFamily} onChange={handleFontFamilyChange} />

          <ButtonGroup
            size="small"
            style={{ marginLeft: 4, position: "relative", height: 36 }}
          >
            {FONT_SIZES.map((fs, index) => (
              <Button
                size="medium"
                color="secondary"
                key={`font-size-${fs}`}
                variant={activeFontSize === fs ? "contained" : "outlined"}
                onMouseDown={handleFontSizeMouseDown(fs)}
                style={{
                  fontSize: 14,
                  position: "absolute",
                  borderRadius: showFontSizes ? 0 : 4,
                  width: 42,
                  left: showFontSizes ? 42 * index : 0,
                  top: 0,
                  opacity: showFontSizes || activeFontSize === fs ? 1 : 0,
                  transition: "300ms",
                }}
              >
                {fs}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
      }
    />
  );
};
export default compose(withRouter)(ControlPanel);
