import { serverURL } from '../utils/utils';	
import firebase from '../Firebase';


//----- Every endpoint will need to be chained with firebase.authenticateUser() so that the server knows whos knocking at the door -----------

export async function shareFile(courseId, fileType, fileId, recipientEmail) {
	return firebase.authenticateUser().then(async idToken => {
		await fetch(`${serverURL()}/share-file`, {
				method: "POST",
				body: JSON.stringify({
					courseId,
          fileType,
          originalFileId: fileId,
          recipientEmail,
          uid: firebase.auth.currentUser.uid,
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": `Bearer ${idToken}`, // must pass idToken for every endpoint
				}
	  	}
		);
    return Promise.resolve('shared file');
	}).catch(err => {
		return Promise.reject(err);
	});
	
}

export async function sharedFileExists(fileId) {
	return firebase.authenticateUser().then(async idToken => {
		return fetch(`${serverURL()}/share-file/${fileId}`, {
			method: "HEAD",
			headers: {
				"Authorization": `Bearer ${idToken}`,
			},
		}).then((resp) => {
			switch (resp.status) {
				case 200:
					return Promise.resolve(true);
				case 404:
				default:
					return Promise.reject(resp.statusText);
			}
		}).catch((err) => {
			return Promise.reject(err);
		});
	})
}

export async function getSharedFile(fileId, fileType, courseId, newFileId) {
	return firebase.authenticateUser().then(async idToken => {
		await fetch(`${serverURL()}/share-file/${fileId}`, {
				method: "PUT",
				body: JSON.stringify({
					courseId,
          fileType,
          uid: firebase.auth.currentUser.uid,
					newFileId,
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					"Authorization": `Bearer ${idToken}`, //must pass idToken for every endpoint
				}
	  	}
		);
    return Promise.resolve('shared file');
	}).catch(err => {
		return Promise.reject(err);
	});
}
