import { INITIALIZE_META_DATA, UPDATE_META_DATA } from '../metaData/actions';
import { SIGN_OUT } from '../profile/actions';
import * as actions from './actions';

// going to be flashcards in an object organized by deckId as the key

// first let's define the initialState for instantiation of the store
const initialState = {}

const flashcardsReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
switch (action.type) {
    case actions.INITIALIZE_FLASHCARDS:
      return { ...action.payload };
    case actions.CHANGE_FLASHCARDS_NAME:
      const { deckId, name } = action.payload;
      return {
        ...state,
        [deckId]: { ...state[deckId], name }, // update name
      };
    case actions.ADD_FLASHCARDS:
      return {
        ...state,
        [action.payload.deckId]: {
          courseId: action.payload.courseId,
          id: action.payload.deckId,
          name: action.payload.name,
        }
      }
    case actions.DELETE_FLASHCARDS:
      const stateWithoutFlashcards = { ...state };
      delete stateWithoutFlashcards[action.payload.deckId];
      return stateWithoutFlashcards;
    case INITIALIZE_META_DATA:
      const stateWithMetaData = { ...state };
      Object.keys(action.payload).forEach((dataKey) => {
        const [_courseId, subsection, fileId] = dataKey.split('_');
        if (subsection !== 'flashcards' || !state[fileId]) return state;
        stateWithMetaData[fileId].metaData = { ...action.payload[dataKey] };
      });
      return stateWithMetaData;
    case UPDATE_META_DATA:
      if (action.payload.subsection !== 'notebooks' || !state[action.payload.fileId]) return state;
        return {
          ...state,
          [action.payload.fileId]: {
            ...state[action.payload.fileId],
            metaData: {
              ...action.payload.updatedMetaData
            }
          }
        }
    case actions.UPDATE_FLASHCARDS_SIZE:
      return {
        ...state,
        [action.payload.deckId]: {
          ...state[action.payload.deckId],
          metaData: {
            ...state[action.payload.deckId].metaData,
            size: action.payload.size,
          }
        }
      }
    case SIGN_OUT:
      return {};
    default:
      return state; // the action doesn't affect the profile store so nothing chaning
  }
}

export default flashcardsReducer;