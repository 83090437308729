import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import logger from '../../utils/logger';

const backupFontStrings = {
	'Kiwi Maru': 'serif',
	'Lato': 'sans-serif',
	'Open Sans': 'sans-serif',
	'Roboto': 'sans-serif',
	'Slabo 27px': 'serif',
	'Work Sans': 'sans-serif',
}

const useStyles = makeStyles((_theme) => ({
	kiwiMaru: { fontFamily: `Kiwi Maru, ${backupFontStrings['Kiwi Maru']}` },
	lato: { fontFamily: `Lato, ${backupFontStrings['Kiwi']}` },
	openSans: { fontFamily: `Open Sans, ${backupFontStrings['Open Sans']}` },
	roboto: { fontFamily: `Roboto, ${backupFontStrings['Roboto']}` },
	slabo: { fontFamily: `Slabo 27px, ${backupFontStrings['Slabo 27px']}` },
	workSans: { fontFamily: `Work Sans, ${backupFontStrings['Work Sans']}` },
}));

const FontPicker = function(props) {
	const { onChange, value } = props;
	
	const [font, setFont] = useState(value);
	const [anchorEl, setAnchorEl] = useState(null);
	
	const classes = useStyles();

	const handleChange = useCallback((e) => {
		setFont(e.target.value);
		onChange(e.target.value, backupFontStrings[e.target.value] || 'sans-serif');
	}, [onChange])

	useEffect(() => {
		setFont(value);
	}, [value]);

	const handleSelect = (val) => () => {
		setFont(val);
		onChange(val, backupFontStrings[val] || 'sans-serif');
		setAnchorEl(null);
	}

	return (
		<>
			<Button size="medium" onClick={(e) => setAnchorEl(e.currentTarget) } variant="outlined" endIcon={<KeyboardArrowDownIcon />} >
				{font || 'Roboto'}
			</Button>
			<Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} >
				<MenuItem onClick={handleSelect('Lato')} className={classes.lato} >Lato</MenuItem>
				<MenuItem onClick={handleSelect('Roboto')} className={classes.roboto} >Roboto</MenuItem>
				<MenuItem onClick={handleSelect('Open Sans')} className={classes.openSans}>Open Sans</MenuItem>
				<MenuItem onClick={handleSelect('Slabo 27px')} className={classes.slabo}>Slabo</MenuItem>
				<MenuItem onClick={handleSelect('Kiwi Maru')} className={classes.kiwiMaru}>Kiwi Maru</MenuItem>
				<MenuItem onClick={handleSelect('Work Sans')} className={classes.workSans}>Work Sans</MenuItem>
			</Menu>
		</>
	)

	return (
		<Box>
			<FormControl>
				<Select
					id='font-picker-select'
					value={font || 'Roboto'}
					onChange={handleChange}
					variant='outlined'
					color='secondary'
				>
					
				</Select>
			</FormControl>
		</Box>
	);
}

export default FontPicker;