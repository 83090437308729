import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { removeDialog } from '../../data/dialogs/actions';

// import logger from '../../utils/logger';
// import Mixpanel, { EVENTS } from '../../utils/mixpanel';

import Transition from '../SlideTransition';

const useStyles = makeStyles((_theme) => ({
	input: {
		margin: `8px 0`,
		minWidth: 250,
	},
}));

const RenameDialog = function(props) {
	const { currentName, onSubmit } = props;

	const classes = useStyles();
  const dispatch = useDispatch();

  const [newName, setNewName] = useState('');

  const handleClose = () => {
    props.dialogId && dispatch(removeDialog(props.dialogId));
  }

  const handleSubmit = () => {
    onSubmit(newName);
    handleClose();
  }

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<DialogTitle>
				Rename <b>{currentName}</b> to:
			</DialogTitle>
			<DialogContent>
        <TextField
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          variant="outlined"
          label="New name"
          className={classes.input}
          error={newName === currentName}
          helperText={newName === currentName ? 'the new name must be different' : ''}
        />
			</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!newName || newName.length === 0 || newName === currentName}
        >
          submit
        </Button>
      </DialogActions>
		</Dialog>
	);
}

export default RenameDialog;