import * as actions from './actions';

import { ADD_FLASHCARDS, DELETE_FLASHCARDS } from '../flashcards/actions';
import { ADD_NOTEBOOK, DELETE_NOTEBOOK } from '../notebooks/actions';
import { SIGN_OUT } from '../profile/actions';

// going to be courses in an object organized by courseId as the key

// first let's define the initialState for instantiation of the store
const initialState = {
  awaitingload: true,
  isLoading: false,
  errorLoading: false,
}


const coursesReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
	switch (action.type) {
    case actions.FETCHING_COURSES:
      return { ...state, isLoading: true, errorLoading: false, awaitingload: false, };  // destructure the previous/initial state and overwrite changing values
    case actions.FETCH_COURSES_SUCCESS:
      return { ...action.payload };  // action.payload should contain the new courses
    case actions.FETCH_COURSES_FAIL:
      return { ...initialState, isLoading: false, errorLoading: true } // going to revert to initialState on failure, idk if that's smart or not
    case actions.CHANGE_COURSE_NAME: {
      const { courseId, name } = action.payload;
      const courseOverride = { ...state[courseId], name };
      return { ...state, [courseId]: courseOverride };
    }
    case actions.CHANGE_COURSE_COLOR: {
      const { courseId, color } = action.payload;
      const courseOverride = { ...state[courseId], color };
      return { ...state, [courseId]: courseOverride };
    }
    case actions.ADD_COURSE:
      const { course } = action.payload;
      return { ...state, [course.id]: { ...course } };
    case actions.DELETE_COURSE:
      const { courseId: deletedCourseId } = action.payload;
      const stateWithoutCourse = { ...state };
      delete stateWithoutCourse[deletedCourseId];
      return stateWithoutCourse;
    // ====================== resources inside flashcards ====================== //
    case ADD_FLASHCARDS:
      const stateWithFlashcard = { ...state };
      stateWithFlashcard[action.payload.courseId].flashcards.push(action.payload.deckId);
      return stateWithFlashcard;
    case ADD_NOTEBOOK:
      const stateWithNotebook = { ...state };
      stateWithNotebook[action.payload.courseId].notebooks.push(action.payload.notebookId);
      return stateWithNotebook;
    case DELETE_FLASHCARDS:
      const stateWithoutFlashcards = { ...state };
      // need to duplicate for re-render
      stateWithoutFlashcards[action.payload.courseId] = { ...stateWithoutFlashcards[action.payload.courseId] };
      stateWithoutFlashcards[action.payload.courseId].flashcards = stateWithoutFlashcards[action.payload.courseId].flashcards.filter((deckId) => deckId !== action.payload.deckId);
      return stateWithoutFlashcards;
      case DELETE_NOTEBOOK:
        const stateWithoutNotebook = { ...state };
        // need to duplicate for re-render
        stateWithoutNotebook[action.payload.courseId] = { ...stateWithoutNotebook[action.payload.courseId] };
       stateWithoutNotebook[action.payload.courseId].notebooks =stateWithoutNotebook[action.payload.courseId].notebooks.filter((nId) => nId !== action.payload.notebookId);
      return stateWithoutNotebook;
    case SIGN_OUT:
      return { ...initialState };
    default:
      return state; // the action doesn't affect the profile store so nothing chaning
  }
}

export default coursesReducer;