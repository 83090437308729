import {
	EditorState,
	AtomicBlockUtils,
	Modifier,
	SelectionState,
	ContentState,
	convertFromHTML,
	RichUtils,
} from 'draft-js';
import Bugsnag from '@bugsnag/js';

import AtomicBlockTypes from './components/AtomicBlockTypes';

import logger from '../../utils/logger';

// THIS FILE IS FOR UTILITIES SPECIFICALLY RELATED TO THE NOTEBOOK //

// inserts an atomic block with null data
export function insertAtomicBlockWithData(editorState, atomicSubtype) {
	const contentState = editorState.getCurrentContent();
	const contentStateWithEntity = contentState.createEntity('TOKEN', 'IMMUTABLE', AtomicBlockTypes[atomicSubtype].emptyData);
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
	const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
	const editorStateWithBlock = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, atomicSubtype);
	return editorStateWithBlock;
}

// removes an atomic block given the block's key
export function removeAtomicBlock(editorState, blockKey) {
	const content = editorState.getCurrentContent();
	const block = content.getBlockForKey(blockKey);
	const targetRange = new SelectionState({
		anchorKey: blockKey,
		anchorOffset: 0,
		focusKey: blockKey,
		focusOffset: block.getLength(),
	});
	const withoutAtomic = Modifier.removeRange(content, targetRange, 'backward');
	const resetBlock = Modifier.setBlockType(withoutAtomic, withoutAtomic.getSelectionAfter(), 'unstyled');
	const newState = EditorState.push(editorState, resetBlock, 'remove-range');
	return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
}

// gets the atomic block's subtype which is really just the text which never get's rendered
export function getAtomicBlockSubtype(contentBlock) {
	return contentBlock.getText();
}

// function for completing the prefix of a word
//	- assumes the current selection is bordering the end of the prefix
export function completePrefix(editorState, completeWord) {
	const currentContent = editorState.getCurrentContent();	// extract the content
	const originalSelection = editorState.getSelection();
	const currentBlock = currentContent.getBlockForKey(originalSelection.getEndKey());	// extract current block

	const endOffset = originalSelection.getEndOffset();	// get the end offset from the selection state
	const beginOffset = backtrackWordOffset(currentBlock.getText(), endOffset);	// backtrack the beginning offset

	const prefixSelection = SelectionState.createEmpty(currentBlock.getKey())
		.set('anchorOffset', beginOffset)
		.set('focusOffset', endOffset);
	const newContentState = Modifier.replaceText(currentContent, prefixSelection, completeWord, editorState.getCurrentInlineStyle());
	// const newContentState = Modifier.replaceText(currentContent, prefixSelection, completeWord+' ');
	const retState = EditorState.push(editorState, newContentState, 'spellcheck-change');
	const prefixLength = prefixSelection.getEndOffset() - prefixSelection.getStartOffset();
	const newOffset = originalSelection.getStartOffset() + (completeWord.length - prefixLength);
	// const newOffset = originalSelection.getStartOffset() + (completeWord.length - prefixLength) + 1;
	const newSelection = originalSelection
		.set('anchorOffset', newOffset)
		.set('focusOffset', newOffset)
	return EditorState.forceSelection(retState, newSelection);
}

// passing a different regBOi lets you override it, otherwise it looks for a word
export function backtrackWordOffset(text, endOffset, regBoi=/[a-zA-Z]/) {
	if (!text || text.length === 0) {
		return endOffset;
	}

	let beginOffset = Math.min(endOffset, text.length-1);
	while (beginOffset >= 0 && text[beginOffset].match(regBoi)) {
		beginOffset -= 1;
	}
	return beginOffset + 1;
}

// gets the depth of the block but returns -1 if not indented at all
export function getModifiedDepth(contentBlock) {
	if (contentBlock.getType() === 'ordered-list-item' || contentBlock.getType() === 'unordered-list-item') {
		return contentBlock.getDepth();	// get indented depth starting at 0
	}
	return -1;	// not indented at all
}

export function injectPastedContent(pastedText, pastedHTML, editorState) {
	if (!pastedHTML) {
		return null;
	}

	try {
		const blocksFromHTML = convertFromHTML(pastedHTML).contentBlocks;
		const derivedContentState = ContentState.createFromBlockArray(blocksFromHTML)
		const newState = Modifier.replaceWithFragment(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			derivedContentState.getBlockMap(),
		);
		const newEditorState = EditorState.push(editorState, newState, 'insert-fragment');
		return newEditorState
	} catch (err) {
		logger.error("Error while pasting content: ", err);
		Bugsnag.notify(err);
		return null;
	}
}

const endDashRegex = /^ ?-$/;
// function to call when the space key is pressed, if return null then nothing changes
//		otherwise return [newEditorState, reverseCommandKey]
export function onSpace(editorState) {
	try {
		const contentState = editorState.getCurrentContent();
		const currentBlock = contentState.getBlockForKey(editorState.getSelection().getEndKey());
		const dashMatchArr = endDashRegex.exec(currentBlock.getText());
		if (dashMatchArr) {
			const dashSelection = SelectionState.createEmpty(currentBlock.getKey())
				.set('anchorOffset', dashMatchArr.index)
				.set('focusOffset', dashMatchArr.index+1);
			
			// remove the dash
			let newContentState = Modifier.replaceText(contentState, dashSelection, '');
			// split the block
			// newContentState = Modifier.splitBlock(newContentState, dashSelection.set('anchorOffset', 0).set('focusOffset', 0));
			newContentState = Modifier.setBlockType(newContentState, SelectionState.createEmpty(currentBlock.getKey()), 'unordered-list-item')

			const retState = EditorState.push(editorState, newContentState, 'remove-range')

			const newSelection = dashSelection
				.set('anchorOffset', 0)
				.set('focusOffset', 0);
			return [EditorState.forceSelection(retState, newSelection), 'dash-indent'];
		}
		return [null,''];
	} catch (err) {
		Bugsnag.notify(err);
		logger.error('Error while handling SPACE: ', err);
		return [null,''];
	}
}

///////////////////////////////////////////////////////////////////////////////
//															Regexs																			 //
///////////////////////////////////////////////////////////////////////////////

export const dateRegexes = [
	/(\d?\d\d\d)-(\d?\d\d\d)/g,
	
	/\d\d?\/\d\d?\/[012]\d\d\d/g,
	/\d\d?-\d\d?-[012]\d\d\d/g,

];
export const simpleDateRegexes = [
	/\b[12][0-9]{3}/g,
	/\d\d?\d?\d? ?([Bb].?[Cc].?[Ee]?.?)/g,
	/\d\d?\d?\d? ?([Cc].?[Ee].?)/g,
	/\d\d?\d?\d? ?([Aa].?[Dd].?)/g,
];
///////////////////////////////////////////////////////////////////////////////
//													Enrichments																			 //
///////////////////////////////////////////////////////////////////////////////

// enabledKeys is an object of keys to boolean
export function enrichState(editorState, command, enabledKeys) {
	const contentState = editorState.getCurrentContent();
	const selectionState = editorState.getSelection();

	if (!selectionState.isCollapsed()) {
		return editorState;	// for now let's only deal with collapsed selection, easier to isolate blocks to check
	}

	// based on the command we perform different enrichments
	switch (command) {
		case 'handle-tab':
			return takeTab(editorState, contentState, selectionState, enabledKeys);
		default:
			return editorState;
	}
	
}


function takeTab(editorState, contentState, selectionState, enabledKeys) {
	const currentBlock = contentState.getBlockForKey(selectionState.getEndKey());
	const blockBefore = contentState.getBlockBefore(currentBlock.getKey());

	// bolds the block before as a subject, singleWordIndentStrategy
	if (
		enabledKeys['bold-prev-indent'] &&
		blockBefore &&	// blockBefore exists
		currentBlock.getLength() === 0 &&	// current block is empty
		getModifiedDepth(currentBlock) === getModifiedDepth(blockBefore)+1 &&	// current block is indented 1 more than block before
		!blockBefore.getCharacterList().some((styles) => styles.contains('BOLD')) &&	// blockBefore isn't bolded
		blockBefore.getText().length > 3 &&
		blockBefore.getText().split(' ').length <= 5	// block before has less than 5 words
	) {
		const blockBeforeSelection = SelectionState.createEmpty(blockBefore.getKey())
		.set('anchorOffset', 0)
		.set('focusOffset', blockBefore.getLength());	// select block before entirely
		// the rest explain themselves
		const newContent = Modifier.applyInlineStyle(contentState, blockBeforeSelection, 'BOLD');
		let newEditorState = EditorState.push(editorState, newContent, 'change-inline-style');	// this helps with undo stack
		newEditorState = EditorState.acceptSelection(newEditorState, selectionState);
		newEditorState = RichUtils.toggleInlineStyle(newEditorState, 'BOLD');
		return newEditorState;
	}

	return editorState;
}

