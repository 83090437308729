import Bugsnag from '@bugsnag/js';

import { spellCheck } from './spellcheck';
import { otto } from './otto';
import logger from './logger';

class WordManager{

    constructor(){
        this.words = {};
        this.otto = new otto();
        this.spellChecker = new spellCheck();
        this.ottoNode = document.createElement('OttoElement');
        this.ottoNode.className = 'dec-otto-node';


    }

    extractAndAddWordsFromText = (text) => {
        try {
						const wordsPreTrim = text.slice(0).match(/( ?)("[^"]+"|[\w]+)/g);
						if (!wordsPreTrim) {
							return;
						}
            const words = wordsPreTrim.map((word) => word.trim());
            this.addWords(words);
        } catch (err) {
					Bugsnag.notify(err);
          logger.log("otto failed: ", err);
        }
    }

    add = (word, score=1) => {
        this.words[word] += score; //Update it's score
        this.otto.add(word);
        this.spellChecker.add(word);
    }

    addWords = (words) => {
        words.forEach(word => this.add(word));
    }

    search = (word) => {
        return this.words.has(word);
    }

		hasWord = (word) => {
			return word in this.words;
		}

    autoComplete = (word) => {
        return this.otto.autoComplete(word);
    }

    //Input a word, see if it's a word, if it's not a word,
    //attempt to suggest a word
    spellCheck = (word) => {
        //They entered a valid word, no suggestion needed
        if(word in this.words)
            return "";
        let suggestions = this.spellChecker.getSuggestions(word);
        //They entered a word so vile that our spell-check can't figure it out
        if(suggestions.length === 0)
            return "";

        //These suggestions are tied in edit-distance, so as a tie-breaker, use score
        suggestions.sort((a, b) => { return this.words[b] - this.words[a]});
        
        return suggestions;
    }
}

const wordManager = new WordManager();

export { wordManager }

//=========================================================================================//
//                                       Resources                                         //
//=========================================================================================//

const WORD_MANAGER_ENGLISH_DICTIONARY_RESOURCE = {
	key: "otto-dict-en",
	filePath: 'otto_files/otto-en-scored-dict.txt',
};
const WORD_MANAGER_SPANISH_DICTIONARY_RESOURCE = {
	key: "otto-dict-es",
	filePath: 'otto_files/es-generic_default_dictionary.txt',
};

const onLoadResourceCallback = (resourceKey, rawBlob) => {
	const reader = new FileReader();

    reader.onload = (e) => {
        let words = e.target.result.split('\n');
        words.forEach((wordAndScore) => {
            try {
                const wordScoreSplit = wordAndScore.split(',')
                const word = wordScoreSplit[0]
                const score = wordScoreSplit.length > 1 ? wordScoreSplit[1] : 1
                wordManager.add(word, score)
            } catch (e) {
								Bugsnag.notify(e);
                logger.error("Error adding word", e);
            }
        });
    }

	reader.readAsText(rawBlob);
}

export { WORD_MANAGER_ENGLISH_DICTIONARY_RESOURCE, WORD_MANAGER_SPANISH_DICTIONARY_RESOURCE, onLoadResourceCallback };