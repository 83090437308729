import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FeedbackIcon from "@material-ui/icons/Feedback";
import ProfileIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Box,
  Typography,
  useTheme,
  List,
  Menu,
  ListItemIcon,
  ListItemText,
  ListItem,
  Avatar,
} from "@material-ui/core";

import firebase from "../../Firebase";
import logger from "../../utils/logger";

import * as TAB_KEYS from "../../data/tabs/keys";
import { addTab, changeActiveTab } from "../../data/tabs/actions";
import {
  FEEDBACK_DIALOG_KEY,
  PREMIUM_PLANS_DIALOG_KEY,
} from "../../data/dialogs/keys";
import { showDialog } from "../../data/dialogs/actions";
import { openNewTabView } from "../../data/tabs/actions";
import { getTabsList } from "../../data/tabs/selectors";
import { signOut } from "../../data/profile/actions";

import * as Routes from "../../constants/routes";
import { getButterBarText, getProfile } from "../../data/profile/selectors";
import { useSessionStorage } from "react-use";
import Mixpanel, { EVENTS } from "../../utils/mixpanel";

const useStyles = makeStyles((theme) => ({
  topToolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.primary.contrastText,
  },
  iconButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // width: 120,
    "& button": {
      margin: "0 4px",
      color: theme.palette.primary.contrastText,
    },
  },
  logoImage: {
    height: 80,
    cursor: "pointer",
    width: "auto",
  },
  imgContainer: {
    transition: "300ms",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
  profileAvatar: {
    background: theme.palette.secondary.main,
    boxShadow: theme.shadows[3],
    transition: "300ms",
    "&:hover": {
      background: theme.palette.secondary.dark,
      boxShadow: theme.shadows[4],
    },
  },
  butterBar: {
    position: "absolute",
    bottom: -24,
    width: "100%",
    //height: 24,
    fontSize: 16,
    left: 0,
    lineHeight: "20px",
    textAlign: "center",
    background: "#FFEA99",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "0 4px",
    color: theme.palette.text.primary,
  },
  closeButterBar: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  getPro: {
    cursor: "pointer",
    textDecorationLine: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

const NavBar = function (props) {
  const { topBar, bottomBar, hideSettings } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector(getProfile);
  const tabs = useSelector(getTabsList);
  const butterBarText = useSelector(getButterBarText);

  const [profileMenuAnchor, setProfileMenuAnchor] = useState(false);
  const [
    hasDismissedCloseToLimitButterBar,
    setHasDismissedCloseToLimitButterBar,
  ] = useSessionStorage("has-dismissed-close-to-limit-butter-bar");

  const handleClickLogo = () => {
    history.push(Routes.HOME);
    dispatch(openNewTabView());
  };

  const handleOpenAccountTab = () => {
    setProfileMenuAnchor(null);
    const currentIndex = tabs.findIndex(
      (tab) => tab.key === TAB_KEYS.ACCOUNT_TAB_KEY
    );
    if (currentIndex >= 0) {
      dispatch(changeActiveTab(currentIndex));
    } else {
      dispatch(addTab(TAB_KEYS.ACCOUNT_TAB_KEY, "Account Settings"));
    }
  };

  const handleOpenFeedback = () => {
    setProfileMenuAnchor(null);
    dispatch(showDialog("control-box-feedback", FEEDBACK_DIALOG_KEY));
  };

  const handleToggleProfileMenu = (e) => {
    setProfileMenuAnchor(e.target);
  };

  const handleLogout = () => {
    setProfileMenuAnchor(null);
    firebase.doSignOut(); // tells the firebase object to sign the user out
    dispatch(signOut());
    logger.log("logged out");
    history.push(Routes.SIGN_IN); // redirect to sign in page
  };

  const handleCloseButterBar = () => {
    Mixpanel.track(EVENTS.dismissedCloseToLimitsButterBar);
    setHasDismissedCloseToLimitButterBar(true);
  };

  const handleOpenProDialog = () => {
    Mixpanel.track(EVENTS.openProDialog, { context: "butter-bar" });
    dispatch(showDialog("premium-plans-notebook", PREMIUM_PLANS_DIALOG_KEY));
  };

  useEffect(() => {
    if (butterBarText && !Boolean(hasDismissedCloseToLimitButterBar)) {
      Mixpanel.track(EVENTS.sawCloseToLimitsButterBar);
    }
  }, []);

  return (
    <AppBar style={{ display: "flex", flexDirection: "row" }}>
      <Box
        height={bottomBar ? 96 : 46}
        className={classes.imgContainer}
        display="flex"
        alignItems="center"
      >
        <img
          src={"/assets/logo-final-03.svg"}
          alt="avatar"
          className={classes.logoImage}
          onClick={handleClickLogo}
          style={{ height: bottomBar ? 80 : 40 }}
        />
      </Box>
      <Box flexGrow={1}>
        <Toolbar className={classes.topToolbar} variant="dense">
          {topBar ? topBar : <div />}
          <Box display="flex" alignItems="center" height={48}>
            <Typography
              className={classes.iconButtonsContainer}
              id="control-box-icons-container"
              variant="h4"
              component="div"
            >
              <Tooltip title="profile menu">
                <IconButton
                  color="secondary"
                  aria-label="profile-menu"
                  onClick={handleToggleProfileMenu}
                  size="small"
                >
                  <Avatar className={classes.profileAvatar}>
                    {/*{profile?.firstName?.length >= 1 ? profile.firstName[0]?.toUpperCase() : 'J'}*/}
                    <ProfileIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                open={Boolean(profileMenuAnchor)}
                anchorEl={profileMenuAnchor}
                onClose={() => setProfileMenuAnchor(null)}
              >
                <List disablePadding>
                  <ListItem
                    disabled={hideSettings}
                    onClick={handleOpenAccountTab}
                    button
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </ListItem>

                  <ListItem
                    onClick={handleOpenFeedback}
                    button
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText>Send feedback</ListItemText>
                  </ListItem>

                  <ListItem
                    onClick={handleLogout}
                    button
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </ListItem>
                </List>
              </Menu>
            </Typography>
          </Box>
        </Toolbar>
        {bottomBar && (
          <Toolbar className={classes.pageSpecificToolbar} variant="dense">
            {bottomBar}
          </Toolbar>
        )}
      </Box>
      {butterBarText && !Boolean(hasDismissedCloseToLimitButterBar) && (
        <Box className={classes.butterBar}>
          <div />
          <Typography color="inherit">
            {butterBarText}&nbsp;
            {/* <Typography
								color="inherit"
								component="span"
								onClick={handleOpenProDialog}
								className={classes.getPro}
							>
								Get Pro Now!
							</Typography> */}
          </Typography>
          <CloseIcon
            className={classes.closeButterBar}
            onClick={handleCloseButterBar}
          />
        </Box>
      )}
    </AppBar>
  );
};

export default NavBar;
