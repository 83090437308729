import React, { useState, useMemo, useCallback, useContext, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { ContentState, EditorState, CompositeDecorator } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Bugsnag from '@bugsnag/js';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import firebase from '../../Firebase';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';
import logger from '../../utils/logger';
import { convertContentStateToFlashcardDeck } from '../../conversions';
import { getNotebooksById } from '../../data/notebooks/selectors';


import Transition from '../SlideTransition';
import Editor from '../../components/Editor';
import CheckCardInput from '../../components/CheckCardInput';
import { addFlashcards } from '../../data/flashcards/actions';
import { addTab } from '../../data/tabs/actions';
import { FLASHCARDS_TAB_KEY } from '../../data/tabs/keys';

const useStyles = makeStyles((theme) =>({
	outer: {
		minWidth: '40%',
		overflowY: 'scroll',
		flex: 1,
		background: theme.palette.background.paper,
		height: '100%',
	},
	notebookOverrides: {
		// flex: 1,
		height: '100%',
		// padding: `6px 5px 350px 20px !important`,
		'&:before': {
			left: '5px !important',
		},
	},
	cardsContainer: {
		
	},
}))


const FocusCardContext = React.createContext(null);

export const FocusedSpan = (props) => {
	const { focusCard, allCoveredBlockKeys } = useContext(FocusCardContext);
	const boiRef = useRef(null);

	const styling = useMemo(() => {
		if (!focusCard || !focusCard.metaData.blockChain)	{
			if (allCoveredBlockKeys.has(props.blockKey)) {
				return {
					backgroundColor: 'var(--background-color)',
				};
			}
			return;
		};
		
	
		if (focusCard.metaData.blockChain.includes(props.blockKey)) {
			if (focusCard.metaData.blockChain.indexOf(props.blockKey) === 0 && boiRef.current) {
				boiRef.current.scrollIntoView();
			}
			return {
				backgroundColor: 'var(--primary-light)',
				color: 'var(--dark-text-color)'
			}
		} else if (allCoveredBlockKeys.has(props.blockKey)) {
			return {
				backgroundColor: 'var(--secondary-light)',
				color: 'var(--dark-text-color)',
			}
		}
	}, [focusCard, allCoveredBlockKeys, props.blockKey]);

	return (
		<span style={styling} ref={boiRef} >
			{props.children}
		</span>
	);
}

const CONVERSION_DECORATORS = new CompositeDecorator([
	{
		strategy: (contentBlock, callback) => { callback(0, contentBlock.getLength()) },
		component: FocusedSpan,
	},
]);

const ConversionDialog = function(props) {	// doesn't remove itself
	const { onClose, editorState, courseId, notebookId } = props;

	const classes = useStyles();
	const dispatch = useDispatch();
	const notebooksById = useSelector(getNotebooksById);

	const [tabIndex, setTabIndex] = useState(0);
	const [format, setFormat] = useState('flashcards');
	const [loading, setLoading] = useState(false);
	const [previewState, setPreviewState] = useState(null);
	const [allCoveredBlockKeys, setAllCoveredBlockKeys] = useState(null);
	const [createdDeck, setCreatedDeck] = useState(null);
	const [focusCard, setFocusCard] = useState(null);
	const [focusIndex, setFocusIndex] = useState(null);

	//const handleFormatSelect = useCallback((op) => {
  //  setFormat(op.value);
	//}, []);

	const handleClose = useCallback((e, reason) => {
		if (tabIndex === 1 && reason === 'backdropClick') {
			return;
		}
		onClose(e, reason);
	}, [tabIndex, onClose])

	const handleDiscard = useCallback((index) => {
		const newDeck = createdDeck.slice();
		const removedCard = newDeck.splice(index, 1)[0];
		const allBlockKeys = new Set();
		newDeck.forEach((card) => card.metaData.blockChain.forEach((blockKey) => allBlockKeys.add(blockKey)));
		setAllCoveredBlockKeys(allBlockKeys);
		setCreatedDeck(newDeck);
		if (removedCard && removedCard.metaData && removedCard.metaData.ruleKey) {
			const { ruleKey } = removedCard.metaData;
			// const [_, coverage] = removedCard.metaData.coverage.split('\n');
			Mixpanel.track(EVENTS.flashcardDelete, { ruleKey });
		}
	}, [createdDeck])

	const handleFlip = useCallback((index) => {
		setFocusIndex(index);
		const newDeck = createdDeck.slice();
		const oldCard = createdDeck[index];
		const newCard = { ...oldCard, front: oldCard.back, back: oldCard.front };
		newDeck[index] = newCard;
		setCreatedDeck(newDeck);
		if (oldCard && oldCard.metaData && oldCard.metaData.ruleKey) {
			const { ruleKey } = oldCard.metaData;
			// const newFront = oldCard.back.content;
			// const newBack = oldCard.front.content;
			Mixpanel.track(EVENTS.flashcardSwitch, { ruleKey, context: 'conversion_panel' })
		}
	}, [createdDeck]);

	const handleDeckColumnScroll = useCallback((e) => {
		if (!e.target) return;

		const firstCard = document.getElementById('checking-card-0');
		if (!firstCard) {
			return;
		}
		const cardHeight = Number(window.getComputedStyle(firstCard)?.height.replace('px', '') || 200);
		const newFocusIndex = Math.round((e.target.scrollTop + 64) / (cardHeight + 24))	// add 24 for margin on cci-root
		if (newFocusIndex) {
			setFocusIndex(newFocusIndex);
			setFocusCard(createdDeck[newFocusIndex]);
		}
	}, [createdDeck]);

	const handleSubmitDeck = useCallback(async () => {
		logger.log("submitting flashcards");
		setLoading(true);
		const filteredDeck = createdDeck.filter((card) => !card.metaData.userDiscard);
		const newName = `${notebooksById[notebookId].name} deck ${DateTime.local().toFormat('MM/dd')}`;
		try {
			Mixpanel.track(EVENTS.submitConvertedDeck, { numCards: filteredDeck?.length, context: 'dialog' });
			const resp = await firebase.addFlashcardDeckToCourse(courseId, newName)
			dispatch(addFlashcards(courseId, resp.id, newName));
			const filePath = firebase.getUserDataFilePath(courseId, 'flashcards', resp.id);
			const _saveResp = await firebase.saveStringifiedToStorage(filePath, JSON.stringify(filteredDeck), {  convertedDeck: true })
			dispatch(addTab(FLASHCARDS_TAB_KEY, newName, courseId, resp.id));
		} catch (error) {
			Bugsnag.notify(error);
			logger.error("Error submitting/saving deck: ", error);
		} finally {
			setLoading(false);
			onClose();
		}
	}, [notebookId, createdDeck, courseId, dispatch, notebooksById, onClose]);

	const handleCreateDeck = async (onlySelection) => {
		setLoading(true)
		let contentToConvert = null;
		const editorSelection = editorState.getSelection();

		Mixpanel.track(EVENTS.convertNotes, { format, context: 'dialog' });

		// convert only selection
		if (!editorSelection.isCollapsed() && onlySelection) {
			let include = false;
			const selectedBlockArray = editorState.getCurrentContent().getBlocksAsArray().filter((block) => {
				if (block.getKey() === editorSelection.getStartKey()) include = true;
				if (block.getKey() === editorSelection.getEndKey()) include = false;
				return include || block.getKey() === editorSelection.getEndKey();
			});
			contentToConvert = ContentState.createFromBlockArray(selectedBlockArray, editorState.getCurrentContent().getEntityMap());
		} else {
			contentToConvert = editorState.getCurrentContent();
		}

		if (format === 'flashcards') {
			try {
				const resp = await convertContentStateToFlashcardDeck(contentToConvert);
				const cards = await resp.json();
				setPreviewState(EditorState.createWithContent(contentToConvert, CONVERSION_DECORATORS));
				const allBlockKeys = new Set();
				Mixpanel.track(EVENTS.gotConvertedNotes, { numCards: cards.length, context: 'dialog' })
				cards.forEach((card) => card.metaData.blockChain.forEach((blockKey) => allBlockKeys.add(blockKey)));
				setAllCoveredBlockKeys(allBlockKeys);
				setFocusCard(cards.length > 0 ? cards[0] : null);
				setCreatedDeck(cards);
				setTabIndex(1);
				logger.log(`Successfully converted notebook, found ${cards.length} cards`)
			} catch (error) {
				Bugsnag.notify(error);
				logger.error("ERROR: ", error)
			} finally {
				setLoading(false)
			}
		}

	}

	useEffectOnce(() => {
		const editorSelection = editorState.getSelection();
		if (editorSelection.isCollapsed()) {
			handleCreateDeck(false);
		} else {
			Mixpanel.track(EVENTS.hasSelectionOnConvert);
		}
	})

	const focusCardValue = useMemo(() => ({ focusCard, allCoveredBlockKeys }), [focusCard, allCoveredBlockKeys]);

	const contentFromTab = () => {
		if (loading) {
			return <CircularProgress color="primary" />
		}

		if (tabIndex === 0) {
			return (
				<Box>
					<Typography>
						Do you want to convert the entire notebook or only the part you&apos;ve selected?
					</Typography>
				</Box>
			);
			// return (
			// 	<>
			// 		<Select
			// 			styles={{
			// 				container: (provided, _state) => ({
			// 					...provided,
			// 					display: 'inline-flex',
			// 				}),
			// 				control: (provided, _state) => ({
			// 					...provided,
			// 					width: 200,
			// 				})
			// 			}}
			// 			options={[{ label: 'flashcards', value: 'flashcards' }]}
			// 			onChange={handleFormatSelect}
			// 			placeholder="Select Format..."
			// 			defaultValue={[{ label: 'flashcards', value: 'flashcards' }]}
			// 			/>

			// 		<div style={{ height: 10 }} />
			// 		<Box display="flex" alignItems="center" >
			// 			<Typography variant="body1" >
			// 				Only convert your selection
			// 			</Typography>
			// 			<Switch
			// 				onChange={toggleOnlySelection}
			// 				checked={onlySelection}
			// 				color="primary"
			// 			/>
			// 		</Box>
			// 	</>
			// )
		} else if (tabIndex === 1) {
			if (createdDeck.length === 0) {
				return <div>Sorry, we couldn&apos;t make any flashcards from the notes you submitted.</div>
			}
			return (
				<FocusCardContext.Provider value={focusCardValue} >
					<Box display="flex" height='100%' >
						<div className={classes.outer} >
							<div
								className={`notebook-editor-container notebook-editor-lined ${classes.notebookOverrides}`}
							>
								<Editor
									editorState={previewState}
									preferences={{ showTOC: false, background: 'lined', enableOtto: false }}
									onChange={() => true}
									readOnly
									height="100%"
									context="conversion dialog"
								/>
							</div>
						</div>
						<div className={classes.outer} onScroll={handleDeckColumnScroll} >
							<div className={classes.cardsContainer} >
							{createdDeck.map((card, index) => (
								<CheckCardInput
									index={index}
									key={`check-card-${index}`}
									card={card}
									isFocused={index === focusIndex}
									//onDiscard={handleDiscard}
									//onFlip={handleFlip}
								/>
							))}
							</div>
						</div>
					</Box>
				</FocusCardContext.Provider>
			);
		}
	}

	const actionsFromTab = useMemo(() => {
		if (loading) {
			return null;
		}

		if (tabIndex === 0) {
			return (
				<>
					<Button
						onClick={handleClose}
						color="primary"
					>
						cancel
					</Button>
					<Button
						variant="outlined"
						onClick={() => handleCreateDeck(false)}
						color="primary"
						>
						Whole Deck
					</Button>
					<Button
						variant="contained"
						onClick={() => handleCreateDeck(true)}
						color="primary"
					>
						Only my selection
					</Button>
				</>
			)
		}

		return (
			<>
				{createdDeck?.length > 0 && tabIndex === 1 &&
					<Typography variant="h5" >
						We found {createdDeck?.length || 0} cards
					</Typography>
				}
				<Button color="secondary" onClick={handleClose} >
					cancel
				</Button>
				<Button
					onClick={tabIndex === 0 ? handleCreateDeck : handleSubmitDeck}
					disabled={tabIndex === 1 ? createdDeck?.length === 0 : false}
					variant="contained"
					color="primary"
				>
					create deck
				</Button>

			</>
		)
	}, [tabIndex, loading, handleClose, handleCreateDeck, createdDeck, handleSubmitDeck])

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
			maxWidth="xl"
		>
			<DialogContent
				style={tabIndex === 1 && !loading ? { maxHeight: '85vh', height: '85vh' } : null}
			>
				{contentFromTab()}
			</DialogContent>
			{actionsFromTab &&
				<DialogActions>
					{actionsFromTab}
				</DialogActions>
			}
		</Dialog>
	);
}

export default ConversionDialog;