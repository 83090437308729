import React, { useCallback, useState } from 'react';
import { useToggle } from 'react-use';
import Bugsnag from '@bugsnag/js';

import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';

import GridOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import LinedOutlinedIcon from '@material-ui/icons/FormatAlignJustifyOutlined';
import BlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import EditIcon from '@material-ui/icons/Edit';

import firebase from '../../../Firebase';

import EnrichmentPrefs from '../../../components/EnrichmentPrefs';
import HelpButton from '../../../components/HelpButton';
import FontPicker from '../../../components/FontPicker';

import logger from '../../../utils/logger';
import { useDispatch, useSelector } from 'react-redux';
import { changeNotebookName } from '../../../data/notebooks/actions';
import { getProfile } from '../../../data/profile/selectors';
import { getFilePreferences } from '../../../data/metaData/selectors';
import { changeEnrichmentPrefs } from '../../../data/profile/actions';
import { showDialog } from '../../../data/dialogs/actions';
import { RENAME_DIALOG_KEY } from '../../../data/dialogs/keys';
import { FormControl, FormControlLabel, Typography } from '@material-ui/core';

/*Class Prefix = prefs*/
// styles.css imported in ControlBox

const NotebookPreference = function(props) {
	const { notebook, onUpdate, courseId } = props;
	const profile = useSelector(getProfile);
	const { enrichmentPrefs } = profile;
	const prefs = useSelector((state) => getFilePreferences(state, { courseId, subsection: 'notebooks', fileId: notebook.id }));
	const { background, showTOC, enableOtto, fontFamily, enableAutoCorrect } = prefs;
	const dispatch = useDispatch();

	const [showRename, toggleShowRename] = useToggle(false);
	const [showEnrichmentPrefs, setShowEnrichmentPrefs] = useState(false);

	const handleEnrichmentPrefsChange = useCallback((updatedPrefs) => {
		firebase.changeEnrichmentPrefs(updatedPrefs).then((resp) => {
			dispatch(changeEnrichmentPrefs(updatedPrefs));
			setShowEnrichmentPrefs(false);
		}).catch((err) => {
			Bugsnag.notify(err);
			logger.log(err);
		});
	}, []);

	const handlePrefsChange = (pref, value) => (_e) => {
		onUpdate({ ...prefs, [pref]: value });
	}

	const handleFontFamilyChange = (nextFont, _backupFont) => {
		handlePrefsChange('fontFamily', nextFont)();
	}

	const handleRename = () => {
		dispatch(showDialog('rename-notebook', RENAME_DIALOG_KEY, {
			currentName: notebook.name,
			onSubmit: (newName) => {
				firebase.updateNotebookInCourse(courseId, notebook.id, { name: newName }).then((resp) => {
					logger.log('updated notebook preferences', resp);
					dispatch(changeNotebookName(notebook.id, newName));
				}).catch((err) => {
					Bugsnag.notify(err);
					logger.error("Error updating notebook name: ", err);
				});
			}
		}))
	}

	return (
		<div className="prefs-root" >
			<Box display='flex' alignItems='center' marginRight={3} >
				<p className="prefs-name-header" >{notebook.name}</p>
				&nbsp;&nbsp;
				<Tooltip title="edit name" >
					<IconButton onClick={handleRename} color='secondary' >
						<EditIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<div className="prefs-content" >

				<FormControl>
					<FormControlLabel
						labelPlacement="top"
						control={(
							<div className="prefs-background-container" >
								<ButtonGroup color='secondary' variant='outlined' >
									<Button
										variant={background === 'blank' ? 'contained' : undefined}
										onClick={handlePrefsChange('background', 'blank')}
									>
										<Tooltip title='blank paper' >
											<BlankOutlinedIcon />
										</Tooltip>
									</Button>
									<Button
										variant={background === 'lined' ? 'contained' : undefined}
										onClick={handlePrefsChange('background', 'lined')}
									>
										<Tooltip title='lined paper' >
											<LinedOutlinedIcon />
										</Tooltip>
									</Button>
									<Button
										variant={background === 'grid' ? 'contained' : undefined}
										onClick={handlePrefsChange('background', 'grid')}
									>
										<Tooltip title='grid paper' >
											<GridOutlinedIcon />
										</Tooltip>
									</Button>
								</ButtonGroup>
							</div>
						)}
						label={
							<Box width="100%" display="flex" alignItems="center" >
								<Typography color="inherit" component="span" variant="body1" >
									Background
								</Typography>
								<HelpButton style={{ marginLeft: 8, color: "#fff" }} message="Select the background for your notebook below" />
							</Box>
						}
					/>
				</FormControl>

				<Box display="flex" alignItems="center" marginTop={2} justifyContent="space-between">
					<FormControl>
						<FormControlLabel
							labelPlacement="start"
							control={(
								<Switch
									checked={showTOC === 'true' || showTOC === true}
									onChange={handlePrefsChange('showTOC', (showTOC === 'true' || showTOC === true) ? 'false' : 'true')}
									name="showTOC"
								/>
							)}
							label={
								<Box width="100%" display="flex" alignItems="center" >
									<Typography color="inherit" component="span" variant="body1" >
										Table of Contents
									</Typography>
								</Box>
							}
						/>
					</FormControl>
					<HelpButton style={{ marginLeft: 8 }} message="When Table of Contents is enabled, it will break your notes into distinct sections and allow you to quickly navigate the notebook" />
				</Box>

				<Box display="flex" alignItems="center" marginTop={2} justifyContent="space-between">
					<FormControl>
						<FormControlLabel
							labelPlacement="start"
							control={(
								<Switch
									onChange={handlePrefsChange('enableOtto', (enableOtto === 'true' || enableOtto === true) ? 'false' : 'true')}
									checked={enableOtto === 'true' || enableOtto === true}
									name="autocomplete"
								/>
							)}
							label={
								<Box width="100%" display="flex" alignItems="center" >
									<Typography component="span" variant="body1" >
										Autocomplete
									</Typography>
								</Box>
							}
						/>
					</FormControl>
					<HelpButton style={{ marginLeft: 8 }} message="When autocomplete is on, your notebook will offer word completion suggestions as you type. Hitting the Tab key will insert the full word." />
				</Box>


				{profile.isTestAccount &&
					<Box display="flex" alignItems="center" marginTop={2} justifyContent="space-between">
						<FormControl>
							<FormControlLabel
								labelPlacement="start"
								control={(
									<Switch
										onChange={handlePrefsChange('enableAutoCorrect', (enableAutoCorrect === 'true' || enableAutoCorrect === true) ? 'false' : 'true')}
										checked={enableAutoCorrect === 'true' || enableAutoCorrect === true}
										name="autocomplete"
									/>
								)}
								label={
									<Box width="100%" display="flex" alignItems="center" >
										<Typography component="span" variant="body1" >
											Autocorrect
										</Typography>
									</Box>
								}
							/>
						</FormControl>
						<HelpButton style={{ marginLeft: 8 }} message="When autocorrect is on, your notebook will automatically correct your typos for you" />
					</Box>
				}

				<Box display="flex" alignItems="center" marginTop={2} justifyContent="space-between">
					<FormControl>
						<FormControlLabel
							labelPlacement="start"
							control={(
								<Box marginLeft={0.5} marginRight={0.5} >
									<Button color='secondary' onClick={() => setShowEnrichmentPrefs(true)} size="small" variant="outlined" >
										edit
									</Button>
								</Box>
							)}
							label={
								<Box width="100%" display="flex" alignItems="center" >
									<Typography component="span" variant="body1" >
										Enrichments
									</Typography>
								</Box>
							}
						/>
					</FormControl>
					<HelpButton style={{ marginLeft: 8 }} message={"Enrichments are automatic formatting improvements applied to your notes as you type"} />
				</Box>
				

				<Box display="flex" alignItems="center" marginTop={2} justifyContent="space-between">
					<FormControl>
						<FormControlLabel
							labelPlacement="start"
							control={(
								<Box marginLeft={0.5} marginRight={0.5} >
									<FontPicker
										value={fontFamily}
										onChange={handleFontFamilyChange}
									/>
								</Box>
							)}
							label={
								<Box width="100%" display="flex" alignItems="center" >
									<Typography component="span" variant="body1" >
										Font
									</Typography>
								</Box>
							}
						/>
					</FormControl>
				</Box>
			</div>

			<EnrichmentPrefs
				open={showEnrichmentPrefs}
				onClose={() => setShowEnrichmentPrefs(false)}
				onSubmit={handleEnrichmentPrefsChange}
				starterPrefs={enrichmentPrefs}
			/>

		</div>);
}

export default NotebookPreference;