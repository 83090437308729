import React from 'react';
import { withRouter } from 'react-router-dom';

import firebase from '../Firebase';

// HOC for protecting routes based on authorization
//	- condition should be like those in constants/routeConditions
const withAuthorization = (condition) => (Component) => {
	class WithAuthorization extends React.Component {

		componentDidMount() {
			this.listener = firebase.auth.onAuthStateChanged((authUser) => {
				if (!condition.validation(authUser)) {	// condition.validatio checks if a user should have access
					const redirect = window.location.pathname !== '/' ? window.location.pathname : null;
					const searchParams = new URLSearchParams(window.location.search);
					if (window.location.pathname !== '/' && !searchParams.has('redirect') && redirect !== '/landing') {
						searchParams.append('redirect', redirect);
					}
					this.props.history.replace({
						pathname: condition.redirect,
						search: searchParams.toString(),
					});	// if not redirect them to the given page
				}
			})
		}

		componentWillUnmount() {	// clean up
			this.listener && this.listener();
		}

		render() {
			return <Component {...this.props} />
		}
	}

	return withRouter(WithAuthorization);
}

export { withAuthorization };