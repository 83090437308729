import { combineReducers, createStore } from 'redux';

import profileReducer from './profile/reducers';
import coursesReducer from './courses/reducers';
import flashcardsReducer from './flashcards/reducers';
import notebooksReducer from './notebooks/reducers';
import metaDataReducer from './metaData/reducers';
import environmentReducer from './environment/reducers';
import tabsReducer from './tabs/reducers';
import dialogsReducer from './dialogs/reducers';

// here we'll combine the different reducers to put them in the store
const bigDaddyReducer = combineReducers({
  profile: profileReducer,  // keep in mind the shape of this object will be the shape of the store
  courses: coursesReducer,
  flashcards: flashcardsReducer,
  notebooks: notebooksReducer,
  metaData: metaDataReducer,
  environment: environmentReducer,
  tabs: tabsReducer,
  dialogs: dialogsReducer,
})

const store = createStore(bigDaddyReducer);

export default store;