
export const INITIALIZE_META_DATA = 'INITIALIZE_META_DATA';

// metaData like { <courseId>_<subsection>_<fileId>: <metaData> }
export const initializeMetaData = (metaData) => {
  return {
    type: INITIALIZE_META_DATA,
    payload: metaData,
  }
}

export const UPDATE_META_DATA = "UPDATE_META_DATA";

export const updateMetaData = (courseId, subsection, fileId, updatedMetaData) => {
  return {
    type: UPDATE_META_DATA,
    payload: {
      courseId,
      subsection,
      fileId,
      updatedMetaData
    }
  }
}