import { serverURL } from '../utils/utils';	
import firebase from '../Firebase';
import Bugsnag from '@bugsnag/js';

import logger from './logger';

export const priceIdToTier = {
	[process.env.REACT_APP_STRIPE_PRO_PRICE_ID_PRODUCTION]: 'PRO',
	[process.env.REACT_APP_STRIPE_PRO_PRICE_ID_DEVELOPMENT]: 'PRO',
}

//----- Every endpoint will need to be chained with firebase.authenticateUser() so that the server knows whos knocking at the door -----------

export async function createCheckoutSession(priceId) {
	const resp = await firebase.authenticateUser().then(idToken => {
		return Promise.resolve(fetch(`${serverURL()}/create-checkout-session`, {
				method: "POST",
				body: JSON.stringify({
					priceId,
					userEmail: firebase.auth.currentUser.email,
				}),
				headers: {
				"Authorization": `Bearer ${idToken}`,
				"Content-type": "application/json; charset=UTF-8",
				}
	  	}
		));
	}).catch(err => {
		return Promise.reject(err);
	});
	try {
		return resp.json().then(({ sessionURL }) => {
			logger.log(sessionURL)
			window.location.replace(sessionURL);
		});
	} catch (e) {
		Bugsnag.notify(e);
		logger.log("ERROR: ", e)
	}

}

export async function createCustomerPortalSession(customerId) {
	const resp = await firebase.authenticateUser().then((idToken) => {
		return Promise.resolve(fetch(`${serverURL()}/create-customer-portal-session`, {
			method: "POST",
			body: JSON.stringify({
				customerId,
			}),
			headers: {
				"Authorization": `Bearer ${idToken}`,
				"Content-type": "application/json; charset=UTF-8",
			}
		}));
	}).catch((err) => {
		return Promise.reject(err);
	});

	try {
		return resp.json().then(({ sessionURL }) => {
			logger.log(sessionURL)
			window.location.replace(sessionURL);
		});
	} catch (e) {
		Bugsnag.notify(e);
		logger.log("ERROR: ", e)
	}
}
