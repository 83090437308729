import React, { useCallback } from 'react';

import TrashIcon from '@material-ui/icons/Delete';
import SwitchIcon from '@material-ui/icons/SyncAlt';

import AtomicBlockTypes from '../../containers/Notebook/components/AtomicBlockTypes';

import { isDev } from '../../utils/utils';

/* class prefix = cci */
import './styles.css';

const CheckCardInput = (props) => {
	const { card, isFocused, onDiscard, index, onFlip, debug } = props;

	const handleDiscard = useCallback(() => {
		onDiscard(index);
	}, [index, onDiscard]);

	const handleFlip = useCallback(() => {
		onFlip(index);
	}, [index, onFlip]);

	const renderCard = (sideData) => {
		switch(sideData.contentType) {
			case 'atomic-block-data': 	// have to render an atomic block in the card
				return (
					<div className={`cci-card ${isFocused ? 'cci-card-focused' : ''}`}  >
						{React.createElement(
							AtomicBlockTypes[sideData.content.atomicSubtype].component,
							{
								...sideData.content.data,
								viewOnly: true,
							},
							null,
						)}
					</div>);
			case 'text':
				return (
					<div className={`cci-card ${isFocused ? 'cci-card-focused' : ''}`} >
						{sideData.content.split('\n').map((line, index) => (
							<React.Fragment key={`check-card-${index}`} >
								<span>{line}</span>
								<br />
							</React.Fragment>
						))}
					</div>);
			default:
				return <div>card type not supported</div>
		}
	}

	return (
		<div
			className={`flex-row cci-root`}
			id={`checking-card-${index}`}
		>
			{isDev() && debug &&
				<div className="flex-column" style={{ justifyContent: 'center', marginRight: 10, }} >
					<h2>Debug Info</h2>
					<h3>ruleKey</h3>
					<p>{card.metaData.ruleKey}</p>
					<h3>cardType</h3>
					<p>{card.metaData.cardType}</p>
					<h3>coverage</h3>
					<p style={{ whiteSpace: 'pre' }} >{card.metaData.coverage}</p>
				</div>
			}
			<span style={ isFocused ? { color: 'var(--mid-blue)' } : null} className="cci-number" >
				{index+1}
			</span>
			<div className="flex-column-center" >
				{renderCard(card.metaData.userFlip ? card.back : card.front)}
			</div>
				<div className="flex-column-center" >
					{onFlip &&
						<SwitchIcon
							onClick={handleFlip}
							className="cci-mid-button"
							style={ isFocused ? { color: 'var(--mid-blue)' } : null}
							/>
					}
					{onDiscard &&
						<TrashIcon
							onClick={handleDiscard}
							className="cci-mid-button"
							style={isFocused ? { color: 'var(--mid-blue)' } : null}
						/>
					}
			</div>
			<div className="flex-column-center" >
				{renderCard(card.metaData.userFlip ? card.front : card.back)}
			</div>
		</div>);
}

export default CheckCardInput;