import React, { useCallback, useEffect, useState } from 'react';

import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';



/*Class Prefix = ep*/
import './styles.css';
import { Typography } from '@material-ui/core';
import HelpButton from '../HelpButton';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';

export const DEFAULT_ENRICHMENT_PREFS = {
	'bold-before-colon-hyphen': true,
	'bold-dates': true,
	'bold-prev-indent': true,
	'bold-simple-dates': true,
	'hyphen-indent': true,
	'auto-cap-sentences': true,
	'auto-cap-first-of-line': true,
	'reset-style-on-enter': true,
	'markdown-headers': true,
}

const EnrichmentPrefs = function(props) {
	const { open, onClose, onSubmit, starterPrefs } = props;
	
	const [newestPrefs, setNewestPrefs] = useState({});
	const [prefsToTrack, setPrefsToTrack] = useState({});
	
	const handlePrefsToggle = (prefsKey) => (e) => {
		setPrefsToTrack({ ...prefsToTrack, [prefsKey]: !prefsToTrack[prefsKey] })
		setNewestPrefs({ ...newestPrefs, [prefsKey]: !newestPrefs[prefsKey] });
	};

	const handleSubmit = useCallback((e) => {
		Object.keys(prefsToTrack).forEach((pref) => {
			if (starterPrefs[pref] !== newestPrefs[pref]) {
				Mixpanel.track(EVENTS.toggleEnrichmentPref, { key: pref, toggledTo: newestPrefs[pref] });
			}
		});
		onSubmit(newestPrefs);
	}, [newestPrefs, onSubmit, prefsToTrack, starterPrefs]);

	useEffect(() => {
		setNewestPrefs(starterPrefs);
	}, [starterPrefs]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{ className: 'ep-root' }}
			maxWidth={'md'}
			fullWidth
		>
			<Box className='ep-header' >
				<Box display='flex' alignItems='center' >
					<Typography variant='h4' >
						Enhancement Preferences
					</Typography>
					<Box marginLeft={1} >
						<HelpButton
							tooltipProps={{ placement: 'right' }}
							message={`Enhancements are automatic formatting improvements applied to your notes as you type.
							Here you can choose to enable/disable certain types of enhancements. Just toggle the switch to turn each
							rule on or off. You must click submit to save your changes.`}
							iconSize={26}
						/>
					</Box>
				</Box>
					<div className="flex-row-center" >
						<Button variant='outlined' color='secondary' onClick={onClose} >
							Cancel
						</Button>
						<div style={{ width: 25 }} />
						<Button variant='contained' color='secondary' onClick={handleSubmit} >
							Submit
						</Button>
					</div>
			</Box>
			<div className="ep-notebook-boi flex-column" >

				<span>
					<span>Bold Indent</span> {/* key = bold-prev-indent */}
					<Switch
						className="ep-toggle"
						checked={newestPrefs['bold-prev-indent'] || false}
						onChange={handlePrefsToggle('bold-prev-indent')}
					/>
				</span>
				<div className="ep-container" >
					<ul>
						<li><b>some text gets bolded</b></li>
						<ul><li>indented text</li></ul>
					</ul>
				</div>

				<br />

				<span>
					<span>Bold Colon/Hyphen</span> {/* key = bold-before-colon-hyphen */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('bold-before-colon-hyphen')}
						checked={newestPrefs['bold-before-colon-hyphen'] || false}
					/>
				</span>
				<div className="ep-container" >
					<div><b>some text that gets bolded</b>: other text</div>
					<div><b>some text that gets bolded</b> - other text</div>
				</div>

				<br />

				<span>
					<span>Bold Dates</span> {/* key = bold-dates */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('bold-dates')}
						checked={newestPrefs['bold-dates'] || false}
					/>
				</span>
				<div className="ep-container" >
					<div><b>786-1250</b> or <b>1850-1856</b></div>
					<div><b>1/1/1999</b> or <b>01/01/1999</b></div>
					<div><b>1-1-1999</b> or <b>01-01-1999</b></div>
				</div>
				
				<br />

				<span>
					<span>Bold Simple Dates</span> {/* key = bold-simple-dates */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('bold-simple-dates')}
						checked={newestPrefs['bold-simple-dates'] || false}
					/>
				</span>
				<div className="ep-container" >
					<div><b>2020</b></div>
					<div><b>50 BCE</b> or <b>65 B.C.E.</b></div>
					<div><b>50 CE</b> or <b>65 C.E.</b></div>
					<div><b>50 AD</b> or <b>65 A.D.</b></div>
				</div>

				<br />

				<span>
					<span>Reset Style</span> {/* key = reset-style-on-enter */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('reset-style-on-enter')}
						checked={newestPrefs['reset-style-on-enter'] || false}
					/>
				</span>
				<div className="ep-container" >
					Going to a new line will automatically turn off active styles like bolding, highlighting, etc.
				</div>

				<br />

				<span>
					<span>Hyphen Indent</span> {/* key = bold-simple-dates */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('hyphen-indent')}
						checked={newestPrefs['hyphen-indent'] || false}
					/>
				</span>
				<div className="ep-container" >
					Typing a "-" on an empty line will turn the line into a hyphenated list
				</div>

				<br />

				<span>
					<span>Capitalize Sentences</span> {/* key = auto-cap-sentences */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('auto-cap-sentences')}
						checked={newestPrefs['auto-cap-sentences'] || false}
					/>
				</span>
				<div className="ep-container" >
					<span>[text]. the</span> &nbsp;&nbsp;&nbsp;&nbsp; &#x2B07; <span>[text]. The</span>
				</div>

				<br />

				<span>
					<span>Capitalize First Word of Line</span> {/* key = auto-cap-sentences */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('auto-cap-first-of-line')}
						checked={newestPrefs['auto-cap-first-of-line'] || false}
					/>
				</span>
				<div className="ep-container" >
					<span>typing on an empty line will capitalize the first word</span> &nbsp;&nbsp;&nbsp;&nbsp; &#x2B07;
					<span>Typing on an empty line will capitalize the first word</span>
				</div>

				<br />

				<span>
					<span>Markdown headers</span> {/* key = auto-cap-sentences */}
					<Switch
						className="ep-toggle"
						onChange={handlePrefsToggle('auto-cap-first-of-line')}
						checked={newestPrefs['auto-cap-first-of-line'] || false}
					/>
				</span>
				<div className="ep-container" >
					<span>
						{Array(1).fill(0).map((_z,index) => <kbd key={`1-mh-${index}`} className='ep-kbd'>#</kbd>)}
						+<kbd className='ep-kbd'>Space</kbd> will change the font size to 72 for this line
					</span>
					<span>
						{Array(2).fill(0).map((_z,index) => <kbd key={`1-mh-${index}`} className='ep-kbd'>#</kbd>)}
						+<kbd className='ep-kbd'>Space</kbd> will change the font size to 48 for this line
					</span>
					<span>
						{Array(3).fill(0).map((_z,index) => <kbd key={`1-mh-${index}`} className='ep-kbd'>#</kbd>)}
						+<kbd className='ep-kbd'>Space</kbd> will change the font size to 24 for this line
					</span>
					<span>
						{Array(4).fill(0).map((_z,index) => <kbd key={`1-mh-${index}`} className='ep-kbd'>#</kbd>)}
						+<kbd className='ep-kbd'>Space</kbd> will change the font size to 18 for this line
					</span>
				</div>

				<br />

			</div>
		</Dialog>);
}

export default EnrichmentPrefs;