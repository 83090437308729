import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_ENRICHMENT_PREFS } from '../components/EnrichmentPrefs';

// functions for creating default data objects

export function makeNotebook(notebookName) {
	return {
		name: notebookName,
		id: uuidv4(),
	}
}

export function makeFlashcardDeck(deckName) {
	return {
		name: deckName,
		id: uuidv4(),
	}
}

export function makeNotebookPreferences() {
	return {
		background: 'blank',
		showTOC: false,
		enableOtto: true,
		enableAutoCorrect: false,
		fontFamily: 'Roboto',
	}
}

export function makeFlashcardsPreferences() {
	return {
		background: 'lined',
	}
}

export function makeUser(name, email) {
	return {
		name,
		first_name: '',
		last_name: '',
		email,
		enrichment_prefs: { ...DEFAULT_ENRICHMENT_PREFS },
		isTestAccount: process.env.NODE_ENV === 'development',
		subscription: {},
		controlBoxOpen: true,
		dismissedNotebookTutorial: true,	// needs to be removed if we ever re-enable the tutorial
	}
}

export function makeCourse(id, name) {
	return {
		id,
		name,
		color: 'var(--primary)',
		notebooks: [],
		flashcards: [],
	}
}