import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import Bugsnag from "@bugsnag/js";

import { gzip, ungzip } from "node-gzip";
import { v4 as uuidv4 } from "uuid";

import logger from "../utils/logger";
import {
  readUserDataFromLocalStorage,
  saveUserDataToLocalStorage,
} from "../utils/utils";

import {
  transformCourse,
  transformUser,
  transformStorageMetaData,
} from "./transformers";

import { makeNotebook, makeFlashcardDeck, makeUser } from "./defaults";

import resourceList from "./resources";

import { DateTime, Interval } from "luxon";
import Mixpanel, { EVENTS } from "../utils/mixpanel";

/* THIS MUST BE UPDATED ON EACH DEPLOYMENT, rn changing it has the following side effects
	- triggers firebase to fetch user data from the database instead of retrieving it locally (not sure if still true)
	- 
*/
export const DEPLOYMENT_VERSION_NUMBER = "1.5.5";

// config for firebase app using environment variables
const config = {
  apiKey: "AIzaSyCsYdYhrX1E1_VvJbb7Vb9p_kSDm9zQCKw",
  authDomain: "study-surplus.firebaseapp.com",
  databaseURL: "https://study-surplus.firebaseio.com",
  projectId: "study-surplus",
  storageBucket: "study-surplus.appspot.com",
  messagingSenderId: "981959050951",
  appId: "1:981959050951:web:ad53a297ce322e0839e0e2",
  measurementId: "G-Y76VFPM3EM",
};

class Firebase {
  constructor() {
    this.app = firebase.initializeApp(config);
    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.limitGoogleProviderScope();
    this.storage = firebase.storage();
    this.storageRef = this.storage.ref();

    this.userData = null;
    this.resourcesInitialized = false;
    this.userLimits = { numWrites: 0, numReads: 0, numDeletes: 0 };
  }

  trackUserActionAndProceed = (action) => {
    if (action === "WRITE") {
      this.userLimits.numWrites += 1;
      if (this.userLimits.numWrites > 100) {
        Mixpanel.track(EVENTS.userLimitExceed, { limit: "WRITES" });
        this.doSignOut();
        throw new Error(`user limit for writes exceeded`);
      }
    } else if (action === "READ") {
      this.userLimits.numReads += 1;
      if (this.userLimits.numReads > 500) {
        Mixpanel.track(EVENTS.userLimitExceed, { limit: "READS" });
        this.doSignOut();
        throw new Error(`user limit for reads exceeded`);
      }
    } else if (action === "DELETE") {
      this.userLimits.numDeletes += 1;
      if (this.userLimits.numDeletes > 10) {
        Mixpanel.track(EVENTS.userLimitExceed, { limit: "DELETES" });
        this.doSignOut();
        throw new Error(`user limit for deletes exceeded`);
      }
    }
    return true;
  };

  getUsername = () => {
    return this.auth.currentUser?.email?.slice().replace(/@.+/, "");
  };

  // should only be called in response to onAuthStateChanged
  initializeResources = () => {
    if (this.resourcesInitialized)
      // if they're already loaded we don't need to load them again
      return Promise.resolve("true");

    const resourcePromises = [];

    for (const [rKey, rSource] of Object.entries(resourceList)) {
      resourcePromises.push(
        this.loadResource(rKey, rSource.filePath, rSource.onLoad)
      );
    }

    return Promise.all(resourcePromises)
      .then((respValues) => {
        this.resourcesInitialized = true;
        respValues.forEach((status) => {
          logger.log(status);
        });
        return Promise.resolve(true);
      })
      .catch((err) => {
        logger.error(`ERROR loading resources (should NOT happen): ${err}`);
        Bugsnag.notify(err);
        return Promise.reject(false);
      });
  };

  fetchProfile = (dispatch, forceLoad = false) => {
    if (!this.auth.currentUser.uid) {
      // just checking to make sure there is a user signed in
      return Promise.reject("Tried loading user without auth");
    }

    return this.fetchUserData();
  };

  // should only be called in response to onAuthStateChanged
  // NOT CURRENTLY USED, needs to be updated with redux-persist;
  initializeUser = (forceLoad = false) => {
    if (!this.auth.currentUser.uid) {
      // just checking to make sure there is a user signed in
      return Promise.reject("Tried loading user without auth");
    }

    // first try to load the userData from localStorage
    const localUserData = readUserDataFromLocalStorage();
    if (
      !forceLoad &&
      localUserData &&
      localUserData.id === this.auth.currentUser.uid && // ensure the local storage corresponds with firebase user
      localUserData.deploymentVersionNumber === DEPLOYMENT_VERSION_NUMBER
    ) {
      // and that the deployment version number matches
      logger.log("user data retrieved locally");
      this.updateUserData(localUserData);
      return Promise.resolve(localUserData);
    } else {
      // if we can't get the data from localStorage or need to update
      // we have 2 different fetches for user data and courses because courses are a subcollection in FIRESTORE
      return this.fetchUserData()
        .then((user) => {
          // first load the user data from FIRESTORE
          return this.fetchCoursesData().then((courses) => {
            // then load the course data from FIRESTORE
            const fetchedUserData = {
              ...user,
              courses,
              deploymentVersionNumber: DEPLOYMENT_VERSION_NUMBER,
            }; // combine user and course data
            this.updateUserData(fetchedUserData);
            logger.log("user data retrieved from database");
            return Promise.resolve(fetchedUserData);
          });
        })
        .catch((e) => {
          Bugsnag.notify(e);
          logger.error("Error initializing user: ", e);
          return Promise.reject(e);
        });
    }
  };

  // asynchronously loads in metaData for each storage item
  loadStorageMetaData = (courses) => {
    if (!this.auth.currentUser.uid) return Promise.reject("no user data");
    const toResolve = [];
    Object.keys(courses).length &&
      Object.entries(courses).forEach(([courseId, course]) => {
        course.notebooks &&
          Object.entries(course.notebooks).forEach(([id, nb]) => {
            const filePath = this.getUserDataFilePath(
              courseId,
              "notebooks",
              id
            );
            if (!filePath) return;
            // the data key is a string representation of the obejct path to the file in userData
            const dataKey = `${courseId}_notebooks_${id}`;
            toResolve.push(
              this.loadFileMetaData(filePath)
                .then((metaData) => ({
                  [dataKey]: transformStorageMetaData(metaData, "notebook"),
                }))
                .catch((err) => {
                  logger.error("Error loading metaData: ", err);
                })
            );
          });
        course.flashcards &&
          Object.entries(course.flashcards).forEach(([id, deck]) => {
            const filePath = this.getUserDataFilePath(
              courseId,
              "flashcards",
              id
            );
            if (!filePath) return;
            // the data key is a string representation of the obejct path to the file in userData
            const dataKey = `${courseId}_flashcards_${id}`;
            toResolve.push(
              this.loadFileMetaData(filePath)
                .then((metaData) => ({
                  [dataKey]: transformStorageMetaData(metaData, "flashcards"),
                }))
                .catch((err) => {
                  logger.error("Error loading metaData: ", err);
                })
            );
          });
      });

    return this.listAllMedia()
      .then((resp) => {
        // first list all media refs
        resp.items.forEach((imgRef) => {
          // add the media metadata loads into the toResolve
          toResolve.push(
            imgRef.getMetadata().then((metaData) => ({
              [`media_${imgRef.name}`]: transformStorageMetaData(
                metaData,
                "media"
              ),
            }))
          );
        });
        return Promise.all(toResolve);
      })
      .then((values) => {
        const combinedData = values.reduce(
          (accum, curr) => ({ ...accum, ...curr }),
          {}
        );
        return Promise.resolve(combinedData);
      });
  };

  // for the UserDataContext
  setUserDataChangeCallback = (callback) => {
    this.onUserDataChange = callback;
  };

  // function to call whenever you change user data
  //	- DO NOT just do 'this.userData = ' as it wont update local storage or the UserDataContext
  // DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED DEPRECATED
  updateUserData = (updatedUserData, updateLocalStorage = true) => {
    this.userData = updatedUserData;
    if (updateLocalStorage) saveUserDataToLocalStorage(updatedUserData);
    this.onUserDataChange && this.onUserDataChange(updatedUserData);
  };

  //===================================================================================================================//
  //																							    Auth API 																												 //
  //===================================================================================================================//

  //Limit the info we want to request from the auth provider
  limitGoogleProviderScope = () => {
    this.googleProvider.addScope("profile");
    this.googleProvider.addScope("email");
    this.googleProvider.addScope("openid");
    this.googleProvider.setCustomParameters({
      redirect_uri: config.authDomain,
    });
  };

  getProviderId = () => {
    if (this.auth.currentUser?.providerData?.length > 0) {
      return this.auth.currentUser?.providerData[0]?.providerId;
    }
  };

  constructUserFromAuth = () => {
    return [
      this.auth.currentUser.uid,
      this.auth.currentUser.email,
      this.auth.currentUser.displayName,
      "",
    ];
  };

  setAuthStateChangeCallback = (callback) => {
    this.auth.onAuthStateChanged(callback);
  };

  // returns promise
  doCreateUserWithEmailAndPassword = (email, password, name) => {
    this.tempNameOnCreate = name;
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        Mixpanel.identify(user.uid);
        Mixpanel.people.set({
          $email: user.email,
          email_verified: user.emailVerified,
        });
        return Promise.resolve(userCredential);
      });
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        Mixpanel.identify(user.uid);
        Mixpanel.people.set({
          $email: user.email,
          email_verified: user.emailVerified,
        });
        return Promise.resolve(userCredential);
      });

  doGoogleSignIn = () => {
    return this.auth.signInWithPopup(this.googleProvider).then((result) => {
      Mixpanel.identify(result.user.uid);
      Mixpanel.people.set({
        $email: result.user.email,
        email_verified: result.user.emailVerified,
      });
      return Promise.resolve(result);
    });
  };

  doSignOut = () => {
    return this.auth.signOut().then((resp) => {
      Mixpanel.reset();
      return Promise.resolve(resp);
    });
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  //Must reauthenticate yourself before changing your password
  //or email. Ie, log bag in again to make sure its you, and
  //not just someone in your room messing with you.
  //Helper function for changing email and password
  reauthenticate = (currentPassword) => {
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  };

  //Change the users email after reauthenticating themselves
  doEmailUpdate = (currentPassword, newEmail) => {
    return this.reauthenticate(currentPassword)
      .then((resp) => {
        return firebase.auth().currentUser.updateEmail(newEmail);
      })
      .then((resp) => {
        logger.log("Email updated!");
        return Promise.resolve(newEmail);
      });
  };

  //Change the users password after reauthenticating themselves
  doPasswordUpdate = (currentPassword, newPassword, callback) => {
    return this.reauthenticate(currentPassword)
      .then((resp) => {
        return firebase.auth.currentUser.updatePassword(newPassword);
      })
      .then((resp) => {
        logger.log("Password updated!");
        return Promise.resolve();
      });
  };

  //EVERY api call to our AWS server will need to chain promise this and attach the idToken
  authenticateUser = () => {
    return firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(
        (idToken) => {
          return Promise.resolve(idToken);
        },
        (err) => {
          return Promise.reject("error authenticating user with jotly server");
        }
      );
  };

  //===================================================================================================================//
  //																							   FIRESTORE API 																										 //
  //===================================================================================================================//

  // adds a new user to the FIRESTORE database
  saveNewUserToFirestore = (userId, email, firstName, lastName) => {
    const name = this.tempNameOnCreate || firstName;
    this.trackUserActionAndProceed("WRITE");
    const profileData = makeUser(name, email);
    return this.db.collection("users").doc(userId).set(profileData);
  };

  updateProfile = (key, value) => {
    this.trackUserActionAndProceed("WRITE");
    const userDoc = this.db.collection("users").doc(this.auth.currentUser.uid);

    return userDoc
      .set({ [key]: value }, { merge: true })
      .then((resp) => {
        logger.log("Updated profile");
        return Promise.resolve();
      })
      .catch((error) => {
        Bugsnag.notify(error);
        logger.log("ERROR updating profile: ", error);
      });
  };

  changeEnrichmentPrefs = (updatedPrefs) => {
    this.trackUserActionAndProceed("WRITE");
    const userDoc = this.db.collection("users").doc(this.auth.currentUser.uid);

    return userDoc
      .set({ enrichment_prefs: updatedPrefs }, { merge: true })
      .then((resp) => {
        logger.log("Updated enrichment prefs");
        return Promise.resolve();
      })
      .catch((error) => {
        logger.log("ERROR changing enrichment prefs: ", error);
        return Promise.reject(error);
      });
  };

  changeEmail = (newEmail) => {
    this.trackUserActionAndProceed("WRITE");
    const userDoc = this.db.collection("users").doc(this.auth.currentUser.uid);

    return userDoc
      .set({ email: newEmail }, { merge: true })
      .then((resp) => {
        logger.log("Updated email in Firestore ");
        return Promise.resolve(newEmail);
      })
      .catch((error) => {
        logger.log("ERROR changing email in firestore: ", error);
        return Promise.reject(error);
      });
  };

  changeTheme = (newTheme) => {
    this.trackUserActionAndProceed("WRITE");
    const userDoc = this.db.collection("users").doc(this.auth.currentUser.uid);

    return userDoc
      .set({ theme: newTheme }, { merge: true })
      .then((resp) => {
        logger.log("Updated theme ");
        return Promise.resolve();
      })
      .catch((error) => {
        logger.log("ERROR changing theme: ", error);
        return Promise.reject(error);
      });
  };

  // adds a new course to the FIRESTORE database
  saveNewCourse = (courseName, courseColor) => {
    this.trackUserActionAndProceed("WRITE");
    return this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .add({
        name: courseName,
        notebooks: [],
        flashcards: [],
        color: courseColor,
      })
      .then((resp) => {
        const course = {
          id: resp.id,
          name: courseName,
          notebooks: [],
          flashcards: [],
          color: courseColor,
        };
        return Promise.resolve(course);
      });
  };

  // changes the name of a course given the id
  // no error handling here
  changeCourseName = (courseId, newName) => {
    this.trackUserActionAndProceed("WRITE");
    // get the ref to the course in FIRESTORE
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.set({ name: newName }, { merge: true });
  };

  updateCourse = (courseId, toUpdate) => {
    if (!courseId) return Promise.reject("no course id");
    this.trackUserActionAndProceed("WRITE");
    // get the ref to the course in FIRESTORE
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.set(toUpdate, { merge: true });
  };

  //	This deletes the course data in FIRESTORE
  //		- storage deletion is handled in functions
  deleteCourse = (courseId) => {
    if (!this.auth.currentUser.uid) {
      // just checking to make sure there is a user signed in
      return Promise.reject("Tried deleting course w/o user");
    }
    this.trackUserActionAndProceed("DELETE");

    const courseDoc = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId); // get the ref to teh course doc in FIRESTORE;

    return courseDoc.delete();
  };

  getProfileAgeInDays = () => {
    if (!this.auth.currentUser) {
      // just checking to make sure there is a user signed in
      return 0;
    }

    try {
      return Interval.fromDateTimes(
        DateTime.fromHTTP(this.auth.currentUser.metadata.creationTime),
        DateTime.local()
      )
        .length("days")
        .toFixed(0);
    } catch (e) {
      Bugsnag.notify(e);
      return 0;
    }
  };

  // fetches the user data from the user's doc in FIRESTORE
  fetchUserData = () => {
    this.trackUserActionAndProceed("READ");
    const userRef = this.db.collection("users").doc(this.auth.currentUser.uid);
    return userRef.get().then((userDoc) => {
      // returning a promise starting with getting the user doc
      if (userDoc.exists) {
        // not sure why it wouldn't exist but just checking
        const newUserData = transformUser(userDoc);
        // NOTE we don't update this.userData because this doens't include the courses
        return Promise.resolve(newUserData);
      } else {
        return Promise.reject("user doc does not exist in database");
      }
    });
  };

  checkUser = () => {
    this.trackUserActionAndProceed("READ");
    const userRef = this.db.collection("users").doc(this.auth.currentUser.uid);
    return userRef.get();
  };

  // fetches all of a users courses data from FIRESTORE
  // NOTE: errors not handled in here
  fetchCoursesData = () => {
    this.trackUserActionAndProceed("WRITE");
    return this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .get // get all the courses docs
      ()
      .then((courses) => {
        // loop through and convert each course to standard form
        return courses.docs.map((courseDoc) => transformCourse(courseDoc));
      })
      .then((cleanCourses) => {
        // return an object of ids to courses
        const coursesRet = {};
        cleanCourses.forEach((course) => {
          coursesRet[course.id] = course;
        });
        return Promise.resolve(coursesRet);
      });
  };

  // adds a new notebook to FIRESTORE database
  //	- newNotebooks list MUST contain the old notebooks and the new one together
  //	- this will generate a unique id for the notebook and return it as a promise resolve
  addNotebookToCourse = (courseId, newNotebookName) => {
    this.trackUserActionAndProceed("WRITE");

    // get the ref to the course in FIRESTORE
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);
    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the notebooks array in the course doc
        const oldNotebooks = courseDoc.data().notebooks || [];
        const newNotebook = makeNotebook(newNotebookName);
        const newNotebooks = oldNotebooks.concat([newNotebook]);
        return courseRef
          .set({ notebooks: newNotebooks }, { merge: true })
          .then((resp) => {
            return Promise.resolve({
              id: newNotebook.id,
              name: newNotebookName,
            });
          });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  // deletes a notebook from FIRESTORE
  deleteNotebookFromCourse = (courseId, notebookId) => {
    this.trackUserActionAndProceed("WRITE");
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the notebooks array in the course doc
        const oldNotebooks = courseDoc.data().notebooks || [];
        const newNotebooks = oldNotebooks.filter((nb) => nb.id !== notebookId);
        return courseRef
          .set({ notebooks: newNotebooks }, { merge: true })
          .then((resp) => {
            return Promise.resolve({ id: notebookId });
          });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  // updates the data for a notebook in FIRESTORE
  //	- toUpdate is an object containing values to update, this will be merged with current notebook data
  //			such as the id
  updateNotebookInCourse = (courseId, notebookId, toUpdate) => {
    this.trackUserActionAndProceed("WRITE");
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the notebooks array in the course doc
        const oldNotebooks = courseDoc.data().notebooks || [];
        const newNotebooks = oldNotebooks.map((nb) =>
          nb.id === notebookId ? { ...nb, ...toUpdate } : { ...nb }
        );
        return courseRef.set({ notebooks: newNotebooks }, { merge: true });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  // adds a flashcardDeck to FIRESTORE
  //	- returns the generated id for the deck in a promise resolve
  addFlashcardDeckToCourse = (courseId, newDeckName) => {
    this.trackUserActionAndProceed("WRITE");
    if (!this.auth.currentUser.uid) {
      // just checking to make sure there is a user signed in
      return Promise.reject("Tried fetching user data w/o user");
    }

    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);
    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the flashcardDecks array in the course doc
        const oldFlashcards = courseDoc.data().flashcards || [];
        const newDeck = makeFlashcardDeck(newDeckName);
        const newFlashcardDecks = oldFlashcards.concat([newDeck]);
        return courseRef
          .set({ flashcards: newFlashcardDecks }, { merge: true })
          .then((resp) => {
            return Promise.resolve({ id: newDeck.id, name: newDeckName });
          });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  // delete a flashcardDeck from FIRESTORE
  deleteFlashcardDeckFromCourse = (courseId, deckId) => {
    this.trackUserActionAndProceed("WRITE");
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the flashcardDecks array in the course docdeleteFlashcardDeckFromCourse
        const oldFlashcardDecks = courseDoc.data().flashcards || [];
        const newFlashcardDecks = oldFlashcardDecks.filter(
          (d) => d.id !== deckId
        );
        return courseRef
          .set({ flashcards: newFlashcardDecks }, { merge: true })
          .then((resp) => {
            return Promise.resolve({ id: deckId });
          });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  // updates the data for a flashcard deck in FIRESTORE
  //	- toUpdate is an object containing values to update, this will be merged with current flashcards data
  //			such as the id
  updateFlashcardsInCourse = (courseId, deckId, toUpdate) => {
    this.trackUserActionAndProceed("WRITE");
    const courseRef = this.db
      .collection("users")
      .doc(this.auth.currentUser.uid)
      .collection("courses")
      .doc(courseId);

    return courseRef.get().then((courseDoc) => {
      if (courseDoc.exists) {
        // update the flashcards array in the course doc
        const oldFlashcards = courseDoc.data().flashcards || [];
        const newFlashcards = oldFlashcards.map((deck) =>
          deck.id === deckId ? { ...deck, ...toUpdate } : { ...deck }
        );
        return courseRef.set({ flashcards: newFlashcards }, { merge: true });
      } else {
        return Promise.reject(`Error: no course with id: ${courseId}`);
      }
    });
  };

  //===================================================================================================================//
  //																							   STORAGE API 																											 //
  //===================================================================================================================//

  // takes a string
  // returns a promise that resolves to a compressed array buffer, using gzip
  compressStringified = (stringified) => {
    return gzip(stringified);
  };

  // takes a Uint8Array
  // returns a promise that resolves to a string, using gzip
  decompressUint8Array = (arrayBoi) => {
    return ungzip(arrayBoi);
  };

  // load the metaData for a file, returns promise
  loadFileMetaData = (filePath) => {
    return this.storage.ref(filePath).getMetadata();
  };

  updateFileMetaData = (courseId, subsection, resourceId, updatedMetaData) => {
    const filePath = this.getUserDataFilePath(courseId, subsection, resourceId);
    if (!filePath) return Promise.reject("no file path");

    return this.storage
      .ref(filePath)
      .updateMetadata(updatedMetaData)
      .then((metaData) => {
        return Promise.resolve(transformStorageMetaData(metaData, subsection));
      })
      .catch((err) => Promise.reject(err));
  };

  sharedFileExists = (resourceId) => {
    const filePath = `shared_files/${resourceId}.gzip`;
    if (!filePath) return Promise.reject("no file path");

    return this.storage.ref(filePath).getDownloadURL();
  };

  /* constructs a filepath to a user data resource
   *	@param courseId, id of course
   *	@subsection, is the section of course data, 'notebooks' or 'flashcards' rn
   *	@resourceId, is the id of the resource, like the id of the notebook
   *	@returns string representation of the file path
   *	NOTE: This is for after version 0.1.2
   */
  getUserDataFilePath = (courseId, subsection, resourceId) => {
    return this.auth.currentUser
      ? `user_files/${this.auth.currentUser.uid}/${courseId}/${subsection}/${resourceId}.gzip`
      : null;
  };

  loadResource = (resourceKey, filePath, onLoadCallback) => {
    const pathRef = this.storage.ref(filePath);

    return pathRef
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (_event) => {
          onLoadCallback(resourceKey, xhr.response);
        };
        xhr.open("GET", url);
        xhr.send();
        return Promise.resolve(
          `Loading resource: ${resourceKey}, awaiting in callback`
        );
      })
      .catch((err) => {
        Bugsnag.notify(err);
        logger.log("ERROR: ", err);
        return Promise.resolve(`ERROR loading resource: ${resourceKey}`);
      });
  };

  /*	saves string data to Firebase STORAGE
   *	@param filePath is where you want to store the file, use this.getUserDataFilePath
   *	@param stringifiedContent should be a string representation of data
   *	@param injectedMetaData, this is extra metaData props to inject, overrides contentType and contentEncoding
   *	@returns promise of success or failure
   *	@NOTE errors must be handled in the calling function
   */
  saveStringifiedToStorage = (
    filePath,
    stringifiedContent,
    injectedMetaData = {}
  ) => {
    // There's some very weird stuff around setting metaData so check with Mike before changing anything
    // https://cloud.google.com/storage/docs/transcoding
    // https://cloud.google.com/storage/docs/gsutil/addlhelp/WorkingWithObjectMetadata
    const metaData = {
      contentType: "application/json", // default contentType assumes application/json
      // contentEncoding: 'gzip',	// we don't set this so google doesn't decompress for us
      ...injectedMetaData,
    };

    return this.compressStringified(stringifiedContent).then(
      (compressedContent) => {
        // compress that shit
        return this.storage.ref(filePath).put(compressedContent, metaData); // upload that shit
      }
    );
  };

  /*	Fetches stringified content from Firebase STORAGE
   *	@param filePath, is where you want to get the file from, use this.getUserDataFilePath
   *	@param onLoadCallback, is the callback function the loaded string will be passed to, this function doesn't parse the string
   *	@returns promise of success or failure
   *	@NOTE errors must be handled in the calling function
   */
  fetchStringifiedFromStorage = (filePath, onLoadCallback) => {
    return this.storage
      .ref(filePath)
      .getDownloadURL()
      .then((url) => {
        // create ref and get download url
        const xhr = new XMLHttpRequest();
        xhr.responseType = "arraybuffer"; // this is because we gzip so it's in array buffer form
        xhr.onload = (_event) => {
          const toDecompress = new Uint8Array(xhr.response); // convert responst from arraybuffer into Uint8Array for decompression
          this.decompressUint8Array(toDecompress).then((rawJSONString) => {
            // decompress that boi
            onLoadCallback(rawJSONString); // send data ish to the callback GanGanG
          });
        };
        xhr.open("GET", url); // set up the request to send a GET to download
        xhr.send(); // send that request to the server baby
        return Promise.resolve(
          `downloading from ${filePath}, awaiting in callback`
        );
      });
  };

  deleteStringifiedFromStorage = (filePath) => {
    const pathRef = this.storage.ref(filePath);

    return pathRef.delete();
  };

  // list the notebooks under a given course in STORAGE
  listNotebooks = (courseName) => {
    return this.storageRef
      .child("user_files")
      .child(this.auth.currentUser.uid)
      .child(courseName)
      .child("notebooks")
      .listAll();
  };

  // lists the flashcardDecks in a course in STORAGEref
  listFlashcards = (courseName) => {
    return this.storageRef
      .child("user_files")
      .child(this.auth.currentUser.uid)
      .child(courseName)
      .child("flashcards")
      .listAll();
  };

  uploadImageFromBlob = (file, meta = {}) => {
    const imageId = uuidv4();
    const metaData = {
      ...meta,
      contentType: file.type,
    };
    return this.storageRef
      .child("user_files")
      .child(this.auth.currentUser.uid)
      .child("media")
      .child(imageId)
      .put(file, metaData)
      .then((snapshot) => {
        return Promise.resolve({ imageId, snapshot });
      });
  };

  listAllMedia = () => {
    if (!this.auth.currentUser?.uid) {
      return Promise.resolve({ items: [] });
    }

    return this.storageRef
      .child("user_files")
      .child(this.auth.currentUser.uid)
      .child("media")
      .listAll();
  };

  getPaginatedListOfPublic = async (fileType) => {
    if (fileType !== "notebooks" && fileType !== "flashcards") {
      return Promise.reject(`Invalid file type: ${fileType}`);
    }
    const listRef = this.storageRef.child("public_files").child(fileType);
    const firstPage = await listRef.list({ maxResults: 20 });
    return Promise.resolve({ listRef, firstPage });
  };
  // getNextPageOfPublic = (listRef, currentPage) => {
  // 	if (currentPage.nextPageToken) {
  // 		return listRef.list({ maxResults: 20, pageToken: currentPage.nextPageToken });
  // 	}
  // }

  publishToPublic = async (
    fileId,
    subsection,
    stringifiedContent,
    injectedCustomMetaData = {}
  ) => {
    if (!this.auth.currentUser) return Promise.reject("Not logged in");

    const filePath = `public_files/${subsection}/${fileId}.gzip`;
    const metaData = {
      customMetadata: {
        // TODO: REMOVE THIS HARCODING
        authorId: 1,
        authorUsername: "The Jotly Team",
        // authorId: this.auth.currentUser.uid,
        // authorUsername: this.getUsername(),
        ...injectedCustomMetaData,
      },
    };
    return this.saveStringifiedToStorage(
      filePath,
      stringifiedContent,
      metaData
    );
  };

  getImageAsDownloadURL = (imageId) => {
    if (!this.auth.currentUser.uid) {
      return Promise.reject("No user auth");
    }

    // download url can be directly inserted into an <image> tag
    const filePath = `user_files/${this.auth.currentUser.uid}/media/${imageId}`;
    return this.storage.ref(filePath).getDownloadURL();
  };
}

export default Firebase;
