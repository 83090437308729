import firebase from '../Firebase'
import logger from './logger';

const mixpanel = require('mixpanel-browser');

mixpanel.init(process.env.NODE_ENV === 'production' ?
	process.env.REACT_APP_MIXPANEL_PRODUCTION_TOKEN
	:
	process.env.REACT_APP_MIXPANEL_DEVELOPMENT_TOKEN);


const Mixpanel = {
  identify: (id) => {
		logger.log('mixpanel.identify');
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, properties) => {
		logger.log('mixpanel.track ', name, properties);
    mixpanel.track(name, { ...(properties || {}), profileAgeInDays: firebase.getProfileAgeInDays() || 0 });
  },
  reset: () => {
		logger.log('mixpanel.reset')
    mixpanel.reset();
  },
  people: {
    set: (...props) => {
			if (mixpanel.people) {
				logger.log('mixpanel.people.set', ...props);
				mixpanel.people.set(...props);
			}
    },
		setOnce: (...props) => {
			if (mixpanel.people) {
				logger.log('mixpanel.people.set_once', ...props);
				mixpanel.people.set_once(...props);
			}
		}
  },
  'opt_out_tracking': () => {
		// disabled for now
    // mixpanel.opt_out_tracking();
  },
}

export default Mixpanel;

export const EVENTS = {
	signUp: 'sign_up',
	signIn: 'sign_in',
	emailChange: 'email_change',
	passwordChange: 'password_change',
	passwordForget: 'password_forget',
	visitedPrivacyPolicyPage: 'visit_privacy_policy_page',
	userFeedback: 'user_feedback',
	crashingError: 'crashing_error',
	enrichmentsError: 'enrichments_error',
	createCourse: 'create_course',
	dismissCookiesBanner: 'dismiss_cookies_banner',

	openAddFile: 'open_add_file',

	createNotebook: 'create_notebook',
	createFlashcards: 'create_flashcards',

	openNotebook: 'open_notebook',
	openFlashcards: 'open_flashcards',

	saveNotebook: 'save_notebook',
	saveFlashcards: 'save_flashcards',
	
	userLimitExceed: 'user_limit_exceed',
	
	flashcardDelete: 'fc_delete',
	flashcardEdit: 'fc_edit',
	flashcardDeckDelete: 'fc_deckdelete',
	flashcardSwitch: 'fc_switch',
	
	openConversionDialog: 'open_conversion_dialog',
	convertNotes: 'convert_notes',
	gotConvertedNotes: 'got_converted_notes',
	hasSelectionOnConvert: 'has_selection_on_convert',
	submitConvertedDeck: 'submit_converted_deck',
	uploadImage: 'upload_image',
	insertAtomic: 'insert_atomic',
	droppedFiles: 'dropped_files',
	
	pasteIntoNotebook: 'paste_into_notebook',
	enrichmentApplied: 'enrichment_applied',
	toggleEnrichmentPref: 'toggle_enrichment_pref',
	toggleOutline: 'toggle_outline',
	toggleAutocomplete: 'toggle_autocomplete',
	toggleAutocorrect: 'toggle_autocorrect',
	toggleBackground: 'toggle_background',
	
	autocorrect: 'autocorrect',
	autocomplete: 'autocomplete',
	undo: 'undo',
	redo: 'redo',

	addTab: 'add_tab',
	closeTab: 'close_tab',
	
	pageView: 'page_view',
	publicPageView: 'public_page_view',

	// account page
	deletedMedia: 'deleted_media',
	themeChange: 'theme_change',
	refetchingProfile: 'refetching_profile',
	
	// landing page
	focusLandingEditor: 'focus_landing_editor',
	typeInLandingEditor: 'type_in_landing_editor',
	scrollLandingPage: 'scroll_landing_page',
	landingPageConvertToFlashcards: 'landing_page_convert_to_flashcards',
	landingPageModalGetStarted: 'landing_page_modal_get_started',
	landingBottomGetStarted: 'landing_bottom_get_started',

	// notebook tutorial
	notebookTutorialClickNext: 'notebook_tutorial_click_next',
	notebookTutorialOpen: 'notebook_tutorial_open',
	notebookTutorialClose: 'notebook_tutorial_close',
	notebookTutorialSkip: 'notebook_tutorial_skip',
	
	// premium
	clickToUpgrade: 'click_to_upgrade',
	openProDialog: 'open_pro_dialog',
	sawCloseToLimitsButterBar: 'saw_close_to_limits_butter_bar',
	dismissedCloseToLimitsButterBar: 'dismissed_close_to_limits_butter_bar',
	clickManageBilling: 'click_manage_billing',
	
	// add_file flow
	addFileSelectType: 'add_file_select_type',
	addFileSubmit: 'add_file_submit',

	// share file
	openShareFileDialog: 'open_share_file_dialog',
	shareFile: 'share_file',
	getSharedFileSubmit: 'get_shared_file_submit',

	// flashcards
	editFlashcard: 'edit_flashcard',
	deleteFlashcard: 'delete_flashcard',
	flipFlashcardFrontBack: 'flip_flashcard_front_back',
	studyFlashcard: 'study_flashcard',
	restartStudy: 'restart_study',
	changeCardType: 'change_card_type',

}