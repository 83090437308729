
export const INITIALIZE_NOTEBOOKS = 'INITIALIZE_NOTEBOOKS';

export const initializeNotebooks = (notebooks) => {
  return {
    type: INITIALIZE_NOTEBOOKS,
    payload: notebooks,
  }
}


export const CHANGE_NOTEBOOK_NAME = 'CHANGE_NOTEBOOK_NAME';

export const changeNotebookName = (notebookId, name) => {
  return {
    type: CHANGE_NOTEBOOK_NAME,
    payload: {
      notebookId,
      name,
    }
  }
}

export const ADD_NOTEBOOK = 'ADD_NOTEBOOK';

export const addNotebook = (courseId, notebookId, name) => {
  return {
    type: ADD_NOTEBOOK,
    payload: {
      courseId,
      notebookId,
      name,
    }
  }
}

export const DELETE_NOTEBOOK = 'DELETE_NOTEBOOK';

export const deleteNotebook = (courseId, notebookId) => {
  return {
    type: DELETE_NOTEBOOK,
    payload: {
      courseId: courseId,
      notebookId,
    },
  }
}

export const UPDATE_NOTEBOOK_SIZE = 'UPDATE_NOTEBOOK_SIZE';

export const updateNotebookSize = (courseId, notebookId, size) => {
  return {
    type: UPDATE_NOTEBOOK_SIZE,
    payload: {
      courseId,
      notebookId,
      size,
    },
  }
}