import {
	AtomicBlockUtils,
	EditorState,
	convertFromHTML,
	ContentState,
	Modifier,
	SelectionState,
  getSafeBodyFromHTML,
} from "draft-js";
import { OrderedSet as ImmutableOrderedSet } from "immutable";
import Compressor from 'compressorjs';
import Bugsnag from '@bugsnag/js';

import { dateRegexes, simpleDateRegexes, backtrackWordOffset } from '../../containers/Notebook/utils';

import { wordManager } from '../../utils/wordManager';
import logger from '../../utils/logger';
import firebase from '../../Firebase';

import Mixpanel, { EVENTS } from '../../utils/mixpanel';
import { FONT_SIZES } from "../../containers/Notebook/styleMap";

export function createAtomicEntity(editorState, type, mutability="IMMUTABLE", data={}) {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(type, mutability, { ...data });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  let newState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
  newState = AtomicBlockUtils.insertAtomicBlock(newState, entityKey, ' ');
  return newState;
}

export function determineCurrentFontSize(editorState) {
	const currentStyles = editorState.getCurrentInlineStyle();
	let currentFontSize;
	FONT_SIZES.forEach((fs) => {
		if (currentStyles.has(`FONT_SIZE_${fs}`)) {
			currentFontSize = fs;
		}
	});
	return currentFontSize;
}

export function injectPastedContent(pastedText, pastedHTML, editorState) {
	if (!pastedHTML) {
		return null;
	}

	try {
		let adjustedHTML = pastedHTML.replaceAll(/(<\\?meta.*?>)/g, '')
		adjustedHTML = adjustedHTML.split(/\r\n|\r|\n/g);
		adjustedHTML = adjustedHTML.reduce((prev, curr) => `${prev}<div>${curr}</div>`, '');
		const blocksFromHTML = convertFromHTML(adjustedHTML, getSafeBodyFromHTML).contentBlocks
		const derivedContentState = ContentState.createFromBlockArray(blocksFromHTML)
		const newState = Modifier.replaceWithFragment(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			derivedContentState.getBlockMap(),
		);
		const newEditorState = EditorState.push(editorState, newState, 'insert-fragment');
		return newEditorState
	} catch (err) {
		logger.error("Error while pasting content: ", err);
		Bugsnag.notify(err);
		return null;
	}
}

export function extractItemsFromEditorState(editorState) {
	const contentState = editorState.getCurrentContent();
	const selectionState = editorState.getSelection();
	const currentBlock = contentState.getBlockForKey(selectionState.getEndKey());
	return {
		contentState,
		selectionState,
		currentBlock,
		currentText: currentBlock.getText(),
	}
}

export function insertSpace(editorState) {
	const contentWithSpace = Modifier.insertText(
		editorState.getCurrentContent(),
		editorState.getSelection(),
		' ',
		editorState.getCurrentInlineStyle(),
	);
	const newEditorState = EditorState.push(editorState, contentWithSpace, 'insert-characters');
	return newEditorState;
}

export function moveSelectionToEndOfCurrentBlock(editorState) {
	const { currentBlock } = extractItemsFromEditorState(editorState);
	return EditorState.forceSelection(
		editorState,
		SelectionState.createEmpty(currentBlock.getKey()).set('anchorOffset', currentBlock.getLength())
	);
}

export function clearLine(editorState, returnContentOnly=true) {
	const { contentState, currentBlock } = extractItemsFromEditorState(editorState);
	const selectionToRemove = SelectionState.createEmpty(currentBlock.getKey())
		.set('anchorOffset', 0)
		.set('focusOffset', currentBlock.getLength());
	const contentStateWithClearedLine = Modifier.removeRange(contentState, selectionToRemove, 'backward');
	if (returnContentOnly) {
		return contentStateWithClearedLine;
	}
	const newEditorState = EditorState.push(editorState, contentStateWithClearedLine, 'remove-range');
	return moveSelectionToEndOfCurrentBlock(newEditorState);
}

export function uploadImage({ file, fileId, onSuccess, onFail }) {	// notebookId changed to fileID 3/19/22
	new Compressor(file, {
		quality: 0.8,
		maxWidth: 4096,
		maxHeight: 4096,
		success: (result) => {
			logger.log("Size of Upload", result.size);
			if (result.size > 20000000) {
				logger.error("Error uploading image, too large: ", result.size);
				onFail(`Error uploading image, too large: ${result.size}`);
				return;
			}
			firebase.uploadImageFromBlob(result, { customMetadata: { fileId }}).then(({ imageId, snapshot }) => {
				Mixpanel.track(EVENTS.uploadImage, { size: result.size });
				logger.log("Upload sucessful: ", imageId, file.name);
				snapshot.ref.getDownloadURL().then((url) => {
					onSuccess({ id: imageId, name: file.name, url });
				})
			}).catch((e) => {
				Bugsnag.notify(e);
				logger.error(e)
				onFail("Error uploading image");
			});
		}
	})
}

export function convertCurrentBlockToListType(editorState, searchRegex, blockType) {
	const { currentBlock, contentState, selectionState, currentText } = extractItemsFromEditorState(editorState);
	const regexCopy = new RegExp(searchRegex);
	const numberText = regexCopy.exec(currentText);
	const selectionToRemove = SelectionState.createEmpty(currentBlock.getKey())
		.set('anchorOffset', 0)
		.set('focusOffset', numberText[0].length);
	let newContentState = Modifier.removeRange(contentState, selectionToRemove, 'ltr');
	newContentState = Modifier.setBlockType(newContentState, selectionState, blockType)
	let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
	newEditorState = EditorState.forceSelection(newEditorState, SelectionState.createEmpty(currentBlock.getKey()))
	return newEditorState;
}


///////////////////////////////////////////////////////////////////////////////
//													Enrichments																			 //
///////////////////////////////////////////////////////////////////////////////


/**
 * Returns null if no enrichments
 * Otherwise returns newEditorState
 */

export function takeSpace(editorState, enabledKeys, enableAutoCorrect) {
	const { selectionState, currentBlock, currentText, contentState } = extractItemsFromEditorState(editorState);

	// const lastWord = currentText.substring(backtrackWordOffset(currentText, selectionState.getStartOffset()-2),
	// 	selectionState.getStartOffset()-1);

	// not exactly sure how all this works but it does
	const possibleDateRegex = /[\d/\-bBcCeEaAdD]/;
	const possibleDate = currentText.substring(
		backtrackWordOffset(currentText, selectionState.getStartOffset()-2, possibleDateRegex),
		selectionState.getStartOffset()
	);
	if (
		(enabledKeys['bold-dates'] || enabledKeys['bold-simple-dates']) &&
		possibleDate.length > 0
		) {
			let match;
			if (enabledKeys['bold-dates']) {
				dateRegexes.some((dreg) => {	// using some causes short circuit when first is found
					match = dreg.exec(possibleDate);
					dreg.lastIndex = 0;
					if (match) {
						Mixpanel.track(EVENTS.enrichmentApplied, { key: 'bold-dates' });
						match = match[0];
						return true;
					}
					return false;
				});
			}

			if (!match && enabledKeys['bold-simple-dates']) {
				simpleDateRegexes.some((dreg) => {	// using some causes short circuit when first is found
					match = dreg.exec(possibleDate);
					dreg.lastIndex = 0;
					if (match) {
						Mixpanel.track(EVENTS.enrichmentApplied, { key: 'bold-simple-dates' });
						match = match[0];
						return true;
					}
					return false;
				});
			}

			if (match) {
				const editorStateWithSpace = insertSpace(editorState);
				const { currentBlock: nCurrentBlock, contentState: nContentState, selectionState: nSelectionState } = extractItemsFromEditorState(editorStateWithSpace);
				const selectionToBold = SelectionState.createEmpty(nCurrentBlock.getKey())
					.set('anchorOffset', nSelectionState.getStartOffset()-1-match.length)
					.set('focusOffset',  nSelectionState.getStartOffset()-1);
				const contentBolded = Modifier.applyInlineStyle(nContentState, selectionToBold, 'BOLD');
				let newEditorState = EditorState.push(editorStateWithSpace, contentBolded, 'change-inline-style');
				newEditorState = EditorState.acceptSelection(newEditorState, nSelectionState);
				return newEditorState
			}

	}

	if (
		enabledKeys['bold-before-colon-hyphen'] &&
		currentBlock.getLength() >= 2 &&	// current block is more than 2 characters
		currentText.split(' ').length <= 6 &&	// block has less than 6 words
		currentText[currentText.length-1] === '-'	// last char before space was a hyphen
	) {
		// first we have to insert the colon
		const editorStateWithSpace = insertSpace(editorState);
		const { currentBlock: nCurrentBlock, contentState: nContentState } = extractItemsFromEditorState(editorStateWithSpace);
		const selectionToBold = SelectionState.createEmpty(nCurrentBlock.getKey())
			.set('anchorOffset', 0)
			.set('focusOffset', selectionState.getEndOffset()-1);
		const contentWithBold = Modifier.applyInlineStyle(nContentState, selectionToBold, 'BOLD');
		let newEditorState = EditorState.push(editorStateWithSpace, contentWithBold, 'change-inline-style');
		newEditorState = EditorState.forceSelection(
			newEditorState,
			SelectionState.createEmpty(nCurrentBlock.getKey()).set('anchorOffset', selectionState.getEndOffset()+1)
		);
		Mixpanel.track(EVENTS.enrichmentApplied, { key: 'bold-before-colon-hyphen' });
		return newEditorState
	}

	if (
		enabledKeys['markdown-headers'] &&	// disabled for now
		currentBlock.getLength() > 0 &&
		/^#+$/.test(currentText)
	) {
		let newEditorState = clearLine(editorState, false);
		const styling = [`FONT_SIZE_${FONT_SIZES[Math.max(0, FONT_SIZES.length-currentBlock.getLength())]}`]
		newEditorState = EditorState.setInlineStyleOverride(newEditorState, ImmutableOrderedSet(styling));
		return newEditorState
	}


	const wordRegex = /\b[a-zA-Z]+/;
	let lastWord = currentText.substring(
		backtrackWordOffset(currentText, selectionState.getStartOffset(), wordRegex),
		selectionState.getStartOffset()
	);
	lastWord = lastWord?.toLowerCase();
	if (enableAutoCorrect && lastWord && lastWord.length > 2 && !wordManager.hasWord(lastWord)) {
		const suggestions = wordManager.spellCheck(lastWord);
		if (suggestions.length > 0) {
			const editorStateWithSpace = insertSpace(editorState);
			const { currentBlock: nCurrentBlock, contentState: nContentState, selectionState: nSelectionState } = extractItemsFromEditorState(editorStateWithSpace);
			const selectionOfWordToReplace = SelectionState.createEmpty(nCurrentBlock.getKey())
				.set('anchorOffset', selectionState.getStartOffset() - lastWord.length)
				.set('focusOffset', selectionState.getEndOffset());
			const contentWithCorrectedWord = Modifier.replaceText(
				nContentState,
				selectionOfWordToReplace,
				suggestions[0],
				editorStateWithSpace.getCurrentInlineStyle(),
			);
			let newEditorState = EditorState.push(editorStateWithSpace, contentWithCorrectedWord, 'spellcheck-characters');
			newEditorState = EditorState.forceSelection(
				newEditorState,
				SelectionState.createEmpty(nCurrentBlock.getKey())
				.set('anchorOffset', selectionState.getEndOffset()+(suggestions[0].length - lastWord.length + 1))
				.set('focusOffset', selectionState.getEndOffset()+(suggestions[0].length - lastWord.length + 1))
			);
			Mixpanel.track(EVENTS.autocorrect);
			return newEditorState;
		}
	}

	if (
		/^ ?\d\.? /g.test(currentText)
	) {
		const editorStateWithSpace = insertSpace(editorState);
		return convertCurrentBlockToListType(editorStateWithSpace, /^ ?\d\.? /, 'ordered-list-item');
	}

	return null;
}


export function takeHyphen(editorState, enabledKeys) {
	const { selectionState, currentText, contentState } = extractItemsFromEditorState(editorState);

	if (
		enabledKeys['hyphen-indent'] &&
		currentText.length <= 1 &&
		selectionState.getEndOffset() === currentText.length &&
		(currentText === '' || currentText === ' ')
	) {
		// first we have to manually insert the hyphen so users can undo to just the hyphen
		const contentWithHyphen = Modifier.insertText(contentState, selectionState, '-');
		const editorStateWithHyphen = EditorState.push(editorState, contentWithHyphen, 'insert-characters');
		// then extract the new vars
		const { selectionState: nSelectionState, currentBlock: nCurrentBlock, contentState: nContentState } = extractItemsFromEditorState(editorStateWithHyphen)
		// then remove the hyphen and toggle the block type
		const contentWithoutHyphen = Modifier.removeRange(
			nContentState,
			SelectionState.createEmpty(nCurrentBlock.getKey())
				.set('anchorOffset', 0)
				.set('focusOffset', nCurrentBlock.getLength()),
				'backward'
		);
		const contentIndented = Modifier.setBlockType(contentWithoutHyphen, selectionState,'unordered-list-item-hyphen');
		const editorStateIndented = EditorState.push(editorStateWithHyphen, contentIndented, 'change-block-type');
		Mixpanel.track(EVENTS.enrichmentApplied, { key: 'hyphen-indent' });
		// finally we maintain selection where it's expected
		return moveSelectionToEndOfCurrentBlock(editorStateIndented);
	}

	return null;
}

export function takeColon(editorState, enabledKeys) {
	const { selectionState, currentText, contentState, currentBlock } = extractItemsFromEditorState(editorState);

	if (
		enabledKeys['bold-before-colon-hyphen'] &&
		currentBlock.getLength() >= 2 &&	// current block is more than 2 characters
		currentText.split(' ').length <= 6	// block has less than 6 words
	) {
		// first we have to insert the colon
		const contentWithColon = Modifier.insertText(contentState, selectionState, ':');
		const editorStateWithColon = EditorState.push(editorState, contentWithColon, 'insert-characters');
		const { currentBlock: nCurrentBlock, contentState: nContentState } = extractItemsFromEditorState(editorStateWithColon);
			const selectionToChange = SelectionState.createEmpty(nCurrentBlock.getKey())
				.set('anchorOffset', 0)
				.set('focusOffset', selectionState.getEndOffset());
			const contentWithBold = Modifier.applyInlineStyle(nContentState, selectionToChange, 'BOLD');
			let newEditorState = EditorState.push(editorStateWithColon, contentWithBold, 'change-inline-style');
			newEditorState = EditorState.forceSelection(
				newEditorState,
				SelectionState.createEmpty(nCurrentBlock.getKey()).set('anchorOffset', selectionState.getEndOffset()+1)
			);
			Mixpanel.track(EVENTS.enrichmentApplied, { key: 'bold-before-colon-hyphen' });
			return newEditorState
	}
	return null;
}

export function takeLetter(editorState, enabledKeys, letter) {
	const { currentText, selectionState, contentState } = extractItemsFromEditorState(editorState);

	if (
		enabledKeys['auto-cap-sentences'] &&
		selectionState.getEndOffset() > 2 &&
		currentText.slice(selectionState.getEndOffset() - 2, selectionState.getEndOffset()) === '. ' &&
		/[a-z]/.test(letter)	// make sure it's not already uppercase
	) {
		// first we insert the lower case letter for undo
		const contentWithLower = Modifier.insertText(contentState, selectionState, letter, editorState.getCurrentInlineStyle());
		const editorStateWithLower = EditorState.push(editorState, contentWithLower, 'insert-characters');
		// then extract new variables
		const { contentState: nContentState, currentBlock: nCurrentBlock, selectionState: nSelectionState } = extractItemsFromEditorState(editorStateWithLower);
		const contentWithCaps = Modifier.replaceText(
			nContentState,
			nSelectionState.set('anchorOffset', nSelectionState.anchorOffset-1),
			letter.toUpperCase(),
			editorState.getCurrentInlineStyle()
		);
		Mixpanel.track(EVENTS.enrichmentApplied, { key: 'auto-cap-sentences' });
		return EditorState.push(editorStateWithLower, contentWithCaps, 'capitalize');
	}

	if (
		enabledKeys['auto-cap-first-of-line'] &&
		selectionState.getEndOffset() === 0 &&
		/[a-z]/.test(letter)	// make sure it's not already uppercase
	) {
		// first we insert the lower case letter for undo
		const contentWithLower = Modifier.insertText(contentState, selectionState, letter);
		const editorStateWithLower = EditorState.push(editorState, contentWithLower, 'insert-characters', editorState.getCurrentInlineStyle());
		// then extract new variables
		const { contentState: nContentState, currentBlock: nCurrentBlock, selectionState: nSelectionState } = extractItemsFromEditorState(editorStateWithLower);
		const contentWithCaps = Modifier.replaceText(
			nContentState,
			nSelectionState.set('anchorOffset', nSelectionState.anchorOffset-1),
			letter.toUpperCase(),
			editorState.getCurrentInlineStyle()
		);
		Mixpanel.track(EVENTS.enrichmentApplied, { key: 'auto-cap-first-of-line' });
		return EditorState.push(editorStateWithLower, contentWithCaps, 'capitalize');
	}

}
