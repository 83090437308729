//=========================================================================================//
//                                         Trie                                            //
//=========================================================================================//

class TrieNode {
	constructor(letter){
		this.letter = letter;
		this.children = {};
		this.last = false; //if path from root to this node makes a word
		this.score = 0;
	}
}

class Suggestion {
	constructor(word, score) {
		this.word = word;
		this.score = score;
	}
}

class Trie {
	constructor(){
		this.root = new TrieNode('');
	}

	//Add a single word to trie
	add(word){
		let node = this.root;

		for(let i = 0; i < word.length; i++){
			//If the node doesn't have the letter
			let letter = word[i];
			if(!Object.keys(node.children).includes(letter))
				node.children[letter] = new TrieNode(letter);
			node = node.children[letter];
		}

		node.last = true;
		node.score += 1;
	}

	//Get autoComplete suggestions from word onwards in trie
	getSuggestions(node, word, suggestions){
		if(node.last && word !== "")
			suggestions.push(new Suggestion(word, node.score))
		
		for(let childNode of Object.keys(node.children))
			this.getSuggestions(node.children[childNode], word.concat(node.children[childNode]['letter']), suggestions);
		
		return suggestions;
	}

	//Returns a list of suggestions sorted by each suggestions score
	autoComplete(word){
		//We only want to start	suggesting when they've typed >= 3 letters
		if(word.length < 3)
			return;
			
		let node = this.root;
		let found = true;
		let tempWord = '';

		//Advance to prefix/word in tree
		for(let i = 0; i < word.length; i++){
			let letter = word[i];
			if(!Object.keys(node.children).includes(letter)){
				found = false;
				break;
			}
			tempWord.concat(letter);
			node = node.children[letter];
		}

		let suggestions = [];
		//if the prefix wasn't found, nothing to autocomplete to
		if(!found)
			return null; 

		//Get all the suggestions for this word, sort by score
		suggestions = this.getSuggestions(node, tempWord, suggestions);
		suggestions.sort((s, t) => {
			return t.score - s.score;
		}); 

		//pick the highest scored suggestion
		return suggestions.length > 0 ? suggestions[0].word : null;
	}

}

export { Trie as otto };