import * as actions from './actions';
import { SIGN_OUT } from '../profile/actions';

// going to be an object like
/*
  { <courseId>_<subsection>_<fileId>: metaData }

  subsection is like 'flashcards' or 'notebooks'
  fileId is the uuid

  OR

  { media_<fileId>: metaData }
*/

// first let's define the initialState for instantiation of the store
const initialState = {}

const metaDataReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
	switch (action.type) {
    case actions.INITIALIZE_META_DATA:
      return { ...action.payload };
    case actions.UPDATE_META_DATA:
      const { courseId, subsection, fileId, updatedMetaData } = action.payload;
      const dataKey = `${courseId}_${subsection}_${fileId}`;
      const temp = { ...state, [dataKey]: { ...state[dataKey], ...updatedMetaData  } }
      return temp;
    case SIGN_OUT:
      return {};
    default:
      return state; // the action doesn't affect the profile store so nothing chaning
  }
}

export default metaDataReducer;