import React, { useState, useRef, useEffect, useCallback, useContext} from 'react';
import { useClickAway } from 'react-use';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

import CodeBlock from '../../containers/Notebook/components/CodeBlock';

import EquationEntry from '../../containers/Notebook/components/EquationEntry';
import TableEntry from '../../containers/Notebook/components/TableEntry';
import TextBox from '../../containers/Notebook/components/TextBox';
import Image from './components/Image';
import { useMeasureFixed } from '../../Firebase/hooks';
import { CourseColorContext } from '../../containers/Notebook';
import { LINED_PAPER_HEIGHT } from '../../constants/muiThemes';
import { useSelector } from 'react-redux';
import { getActiveTabIndex } from '../../data/tabs/selectors';

const PluginsWrapper = (props) => {

	const courseColor = useContext(CourseColorContext);

	const { onFinishEdit, onDeleteBlock, blockKey, editing, disallowEditing,
		height: initialHeight, width: initialWidth, onChange } = props;

	const [sizeRef, { height, width }] = useMeasureFixed();
	const [userIsResizing, setUserIsResizing] = useState(false);
	const [adjustedHeight, setAdjustedHeight] = useState(initialHeight || LINED_PAPER_HEIGHT);
	const [adjustedWidth, setAdjustedWidth] = useState(initialWidth || LINED_PAPER_HEIGHT);
	const activeTabIndex = useSelector(getActiveTabIndex);
	const clickAwayRef = useRef(null);
	useClickAway(clickAwayRef, () => {
		if (editing) {
			onFinishEdit();
		}
	})

	const toggleScroll = useCallback((to) => {
		const els = document.getElementsByClassName('editor-scroll-container');
		if (els.length > activeTabIndex) {
			els[activeTabIndex].style.overflow = to;
		}
	}, [activeTabIndex]);

	const adjustHeight = useCallback((rawHeight) => {
		
		if (rawHeight % LINED_PAPER_HEIGHT === 0) return;
		const oneBelow = Math.floor(rawHeight / LINED_PAPER_HEIGHT);	// get floored divisor for one below
		const nextHeight = LINED_PAPER_HEIGHT * (oneBelow+1);
		if (nextHeight !== rawHeight && nextHeight >= LINED_PAPER_HEIGHT) {
			onChange && onChange({ height: nextHeight })
			setAdjustedHeight(nextHeight);
		}
	}, [onChange]);

	const handleStartResize = useCallback((_event, _data) => {
		setUserIsResizing(true);
		toggleScroll('hidden')
	}, [toggleScroll]);

	const handleStopResize = useCallback((_event, data) => {
		setUserIsResizing(false);
		adjustHeight(data.size.height);
		setAdjustedWidth(data.size.width);
		onChange && onChange({ width: data.size.width });
		toggleScroll('auto')
	}, [onChange, adjustHeight, toggleScroll])

	useEffect(() => {
		adjustHeight(initialHeight);
	}, [initialHeight]);
	
	const handleDelete = () => {
		onDeleteBlock(blockKey);
	}


	const eatClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	// WARNING: disallowEditing is what keeps images from reloading a bunch
	if (editing && !disallowEditing) {
		return (
			<div
				className="plugins-atomic-wrapper"
				onClick={eatClick}
				id={`resize-${blockKey}`}
				style={{ ...props.style }}
				ref={clickAwayRef}
			>
				<div
					className="plugins-atomic-size-wrapper"
					style={{ minWidth: adjustedWidth, minHeight: adjustedHeight, borderColor: courseColor }}
					key={'plugins-atomic-size-wrapper'}
					id={'plugins-atomic-size-wrapper'}
					ref={sizeRef}
				>
					{props.children}
				</div>
		</div>
		);
	}

	return (
		<div
			className="plugins-atomic-wrapper"
			onClick={eatClick}
			id={`resize-${blockKey}`}
			style={{ ...props.style, height: Math.max(adjustedHeight, height) }}
			ref={clickAwayRef}
		>
			<ResizableBox
				width={adjustedWidth || width}
				height={adjustedHeight || height}
				minConstraints={[180, 30]}
				maxConstraints={[window.innerWidth, window.innerHeight]}
				draggableOpts={{ grid: [1, 30] }}
				onResizeStart={handleStartResize}
				onResizeStop={handleStopResize}
				onClick={eatClick}
			>
				<div onClick={eatClick} className="plugins-atomic-size-wrapper" ref={sizeRef} style={{ borderColor: courseColor }} >
					<span className="plugins-atomic-size-constraint" >
						{props.children}
					</span>
					<div className="plugins-atomic-blue-square" onClick={eatClick} onMouseUp={eatClick} onMouseDown={eatClick} />
				</div>
			</ResizableBox>
		</div>
	);
	
}

function usePluginUtils(block, blockProps, selection, contentState) {
	const [editing, setEditing] = useState(false);

	const handleFinishEdit = useCallback(() => {
		setEditing(false);
		blockProps.onFinishEdit(block.getKey());
	}, [block, blockProps]);

	useEffect(() => {
		if (selection.getFocusKey() === block.getKey()) {
			setEditing(true);
			blockProps.onStartEdit(block.getKey());
		}
	}, [selection, blockProps, block]);

	const handleChange = useCallback((data) => {
		const contentWithUpdatedEntity = contentState.mergeEntityData(blockProps.entityKey, data);
		blockProps.onContentChange(contentWithUpdatedEntity);
	}, [blockProps.entityKey, contentState, blockProps.onContentChange])

	return { editing, handleFinishEdit, handleChange };
}

const CodeBlockComponent = React.forwardRef(
	(
		{
			block, // eslint-disable-line no-unused-vars
			blockProps, // eslint-disable-line no-unused-vars
			customStyleMap, // eslint-disable-line no-unused-vars
			customStyleFn, // eslint-disable-line no-unused-vars
			decorator, // eslint-disable-line no-unused-vars
			forceSelection, // eslint-disable-line no-unused-vars
			offsetKey, // eslint-disable-line no-unused-vars
			selection, // eslint-disable-line no-unused-vars
			tree, // eslint-disable-line no-unused-vars
			contentState, // eslint-disable-line no-unused-vars
			blockStyleFn, // eslint-disable-line no-unused-vars
			preventScroll, // eslint-disable-line no-unused-vars
			style,
			...elementProps
		},
		ref
	) => {
		const { editing, handleFinishEdit, handleChange } = usePluginUtils(block, blockProps, selection, contentState);
		const data = contentState.getEntity(blockProps.entityKey).getData();

		return (
			<PluginsWrapper
				onDeleteBlock={blockProps.onDeleteBlock}
				onFinishEdit={handleFinishEdit}
				style={style}
				blockKey={block.getKey()}
				height={data.height}
				width={data.width}
				onChange={handleChange}
				editing={editing}
			>
				<div ref={ref} {...elementProps} >
					<CodeBlock
						initialContent={""}
						editing={editing}
						onChange={handleChange}
						{...data}
					/>
				</div>
			</PluginsWrapper>);
	}
);


export const createCodeBlockPlugin = (config = {}) => {
	const component = config.decorator ? config.decorator(CodeBlockComponent) :CodeBlockComponent;
	return {
		blockRendererFn: (block, { getEditorState }) => {
			if (block.getType() === 'atomic') {
				const contentState = getEditorState().getCurrentContent();
				const entityKey = block.getEntityAt(0);
				if (!entityKey)	return;
				const entity = contentState.getEntity(entityKey);
				const type = entity.getType();
				if (type === 'code-block') {
					return {
						component,
						editable: false,
						props: {
							...config,
							entityKey: entityKey,
						},
					};
				}
			}
			return null;
		},
	};
};

const TableEntryComponent = React.forwardRef(
	(
		{
			block, // eslint-disable-line no-unused-vars
			blockProps, // eslint-disable-line no-unused-vars
			customStyleMap, // eslint-disable-line no-unused-vars
			customStyleFn, // eslint-disable-line no-unused-vars
			decorator, // eslint-disable-line no-unused-vars
			forceSelection, // eslint-disable-line no-unused-vars
			offsetKey, // eslint-disable-line no-unused-vars
			selection, // eslint-disable-line no-unused-vars
			tree, // eslint-disable-line no-unused-vars
			contentState, // eslint-disable-line no-unused-vars
			blockStyleFn, // eslint-disable-line no-unused-vars
			preventScroll, // eslint-disable-line no-unused-vars
			style,
			...elementProps
		},
		ref
	) => {
		const { editing, handleFinishEdit, handleChange } = usePluginUtils(block, blockProps, selection, contentState);
		const data = contentState.getEntity(blockProps.entityKey).getData();

		return (
			<PluginsWrapper
				onDeleteBlock={blockProps.onDeleteBlock}
				onFinishEdit={handleFinishEdit}
				style={style}
				blockKey={block.getKey()}
				height={data.height}
				width={data.width}
				onChange={handleChange}
				editing={editing}
			>
				<div ref={ref} {...elementProps} >
					<TableEntry
						editing={editing}
						onChange={handleChange}
						numRows={2}
						numColumns={2}
						cellMap={{}}
						{...data}
					/>
				</div>
			</PluginsWrapper>);
	}
);

export const createTableEntryPlugin = (config = {}) => {
	const component = config.decorator ? config.decorator(TableEntryComponent) :TableEntryComponent;
	return {
		blockRendererFn: (block, { getEditorState }) => {
			if (block.getType() === 'atomic') {
				const contentState = getEditorState().getCurrentContent();
				const entityKey = block.getEntityAt(0);
				if (!entityKey)	return;
				const entity = contentState.getEntity(entityKey);
				const type = entity.getType();
				if (type === 'table-entry') {
					return {
						component,
						editable: false,
						props: {
							...config,
							entityKey: entityKey,
						},
					};
				}
			}
			return null;
		},
	};
};


const EquationEntryComponent = React.forwardRef(
	(
		{
			block, // eslint-disable-line no-unused-vars
			blockProps, // eslint-disable-line no-unused-vars
			customStyleMap, // eslint-disable-line no-unused-vars
			customStyleFn, // eslint-disable-line no-unused-vars
			decorator, // eslint-disable-line no-unused-vars
			forceSelection, // eslint-disable-line no-unused-vars
			offsetKey, // eslint-disable-line no-unused-vars
			selection, // eslint-disable-line no-unused-vars
			tree, // eslint-disable-line no-unused-vars
			contentState, // eslint-disable-line no-unused-vars
			blockStyleFn, // eslint-disable-line no-unused-vars
			preventScroll, // eslint-disable-line no-unused-vars
			style,
			...elementProps
		},
		ref
	) => {
		const { editing, handleFinishEdit, handleChange } = usePluginUtils(block, blockProps, selection, contentState);
		const data = contentState.getEntity(blockProps.entityKey).getData();

		return (
			<PluginsWrapper
				onFinishEdit={handleFinishEdit}
				style={style}
				onDeleteBlock={blockProps.onDeleteBlock}
				blockKey={block.getKey()}
				height={data.height}
				width={data.width}
				onChange={handleChange}
				editing={editing}
			>
				<div ref={ref} {...elementProps} >
					<EquationEntry
						onFinishEdit={handleFinishEdit}
						latexContent={""}
						editing={editing}
						onChange={handleChange}
						{...data}
					/>
				</div>
			</PluginsWrapper>);
	}
);


export const createEquationEntryPlugin = (config = {}) => {
	const component = config.decorator ? config.decorator(EquationEntryComponent) :EquationEntryComponent;
	return {
		blockRendererFn: (block, { getEditorState }) => {
			if (block.getType() === 'atomic') {
				const contentState = getEditorState().getCurrentContent();
				const entityKey = block.getEntityAt(0);
				if (!entityKey)	return;
				const entity = contentState.getEntity(entityKey);
				const type = entity.getType();
				if (type === 'equation-entry') {
					return {
						component,
						editable: false,
						props: {
							...config,
							entityKey: entityKey,
						},
					};
				}
			}
			return null;
		},
	};
};

const TextBoxComponent = React.forwardRef(
	(
		{
			block, // eslint-disable-line no-unused-vars
			blockProps, // eslint-disable-line no-unused-vars
			customStyleMap, // eslint-disable-line no-unused-vars
			customStyleFn, // eslint-disable-line no-unused-vars
			decorator, // eslint-disable-line no-unused-vars
			forceSelection, // eslint-disable-line no-unused-vars
			offsetKey, // eslint-disable-line no-unused-vars
			selection, // eslint-disable-line no-unused-vars
			tree, // eslint-disable-line no-unused-vars
			contentState, // eslint-disable-line no-unused-vars
			blockStyleFn, // eslint-disable-line no-unused-vars
			preventScroll, // eslint-disable-line no-unused-vars
			style,
			...elementProps
		},
		ref
	) => {
		const { editing, handleFinishEdit, handleChange } = usePluginUtils(block, blockProps, selection, contentState);
		const data = contentState.getEntity(blockProps.entityKey).getData();

		return (
			<PluginsWrapper
				onFinishEdit={handleFinishEdit}
				style={style}
				onDeleteBlock={blockProps.onDeleteBlock}
				blockKey={block.getKey()}
				height={data.height}
				width={data.width}
				onChange={handleChange}
				editing={editing}
			>
				<div ref={ref} {...elementProps} >
					<TextBox
						editing={editing}
						onChange={handleChange}
						content={""}
						{...data}
					/>
				</div>
			</PluginsWrapper>);
	}
);


export const createTextBoxPlugin = (config = {}) => {
	const component = config.decorator ? config.decorator(TextBoxComponent) :TextBoxComponent;
	return {
		blockRendererFn: (block, { getEditorState }) => {
			if (block.getType() === 'atomic') {
				const contentState = getEditorState().getCurrentContent();
				const entityKey = block.getEntityAt(0);
				if (!entityKey)	return;
				const entity = contentState.getEntity(entityKey);
				const type = entity.getType();
				if (type === 'text-box') {
					return {
						component,
						editable: false,
						props: {
							...config,
							entityKey: entityKey,
						},
					};
				}
			}
			return null;
		},
	};
};

const ImageComponent = React.forwardRef(
	(
		{
			block, // eslint-disable-line no-unused-vars
			blockProps, // eslint-disable-line no-unused-vars
			customStyleMap, // eslint-disable-line no-unused-vars
			customStyleFn, // eslint-disable-line no-unused-vars
			decorator, // eslint-disable-line no-unused-vars
			forceSelection, // eslint-disable-line no-unused-vars
			offsetKey, // eslint-disable-line no-unused-vars
			selection, // eslint-disable-line no-unused-vars
			tree, // eslint-disable-line no-unused-vars
			contentState, // eslint-disable-line no-unused-vars
			blockStyleFn, // eslint-disable-line no-unused-vars
			preventScroll, // eslint-disable-line no-unused-vars
			style,
			...elementProps
		},
		ref
	) => {
		const { editing, handleFinishEdit, handleChange } = usePluginUtils(block, blockProps, selection, contentState);
		const data = contentState.getEntity(blockProps.entityKey).getData();

		return (
			<PluginsWrapper
				onFinishEdit={handleFinishEdit}
				style={style}
				onDeleteBlock={blockProps.onDeleteBlock}
				blockKey={block.getKey()}
				height={data.height}
				width={data.width}
				onChange={handleChange}
				editing={editing}
				disallowEditing
			>
				<div ref={ref} {...elementProps} >
					<Image
						editing={editing}
						onChange={handleChange}
						{...data}
					/>
				</div>
			</PluginsWrapper>);
	}
);

export const createImagePlugin = (config = {}) => {
	const component = config.decorator ? config.decorator(ImageComponent) : ImageComponent;
	return {
		blockRendererFn: (block, { getEditorState }) => {
			if (block.getType() === 'atomic') {
				const contentState = getEditorState().getCurrentContent();
				const entityKey = block.getEntityAt(0);
				if (!entityKey)	return;
				const entity = contentState.getEntity(entityKey);
				const type = entity.getType();
				if (type === 'image') {
					return {
						component,
						editable: false,
						props: {
							...config,
							entityKey: entityKey,
						},
					};
				}
			}
			return null;
		},
	};
}

// let ottoSuggestion = '';
// export const createOttoPlugin = (config = {}) => {
// 	return {
// 		keyBindingFn: () => {
// 			console.log("HEREO")
// 		},
// 		keyBindingFn: (e, { getEditorState }) => {
// 			console.log("HERE")
// 			const ottoHookNode = document.getElementById(`otto-span-${getEditorState().getSelection().getEndKey()}`);
// 			if (ottoHookNode) {
// 				const prefix = ottoHookNode.innerText;	// pull out the text the user has entered so far
// 				const suggestion = wordManager.autoComplete(prefix);	// get the suggestion
// 				if (suggestion && suggestion.length > 0) {
// 					ottoSuggestion = prefix + suggestion;
// 					wordManager.ottoNode.innerText = suggestion;	// update the text of teh ottoNode to display suggestion
// 					// ottoNode may cause complications with the fact that it's inside the Editor but managed externally by us
// 					ottoHookNode.parentElement.insertBefore(wordManager.ottoNode, ottoHookNode.nextSibling);	// place ottoNode next to where user is typing
// 				}
// 			} else {
// 				ottoSuggestion = '';
// 				if (wordManager.ottoNode) {
// 					wordManager.ottoNode.innerText = '';
// 					wordManager.ottoNode.remove();
// 				}
// 			}
// 		},
// 		handleKeyCommand: () => { console.log("BOOGIE") }


// 		// blockRendererFn: (block, { getEditorState }) => {
// 		// 	if (block.getType() !== 'atomic') {
// 		// 		const selection = getEditorState().getSelection();
// 		// 		if (selection.isCollapsed() && selection.getFocusKey() === block.getKey()) {
// 		// 			return {
// 		// 				component,
// 		// 				editable: true,
// 		// 				props: {
// 		// 				},
// 		// 			};
// 		// 		}
// 		// 	}
// 		// 	return null;
// 		// },
// 	};
// };

