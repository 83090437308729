import React from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { redirectWithAuth, redirectWithoutAuth } from '../constants/routeConditions';

import Spinner from '../components/Spinner'

function RedirectPage(props) {

	return (<Spinner />);
}

export default compose(
	withAuthorization(redirectWithoutAuth),
	withAuthorization(redirectWithAuth),
)(RedirectPage)

