
export const getMetaData = (state) => state.metaData;

export const getFileMetaData = (state, { courseId, subsection, fileId }) =>
  state.metaData[`${courseId}_${subsection}_${fileId}`];

export const getFilePreferences = (state, { courseId, subsection, fileId }) =>
  state.metaData[`${courseId}_${subsection}_${fileId}`] ? state.metaData[`${courseId}_${subsection}_${fileId}`].preferences : {};

export const getTotalMediaSize = (state) => {
  let total = 0;
  Object.entries(state.metaData).forEach((dataKey, metaData) => {
    if (dataKey.includes('media')) {
      total += metaData.size;
    }
  });
  return total;
}

export const getTotalSavedBytes = (state) => {
  let total = 0;
  Object.entries(state.metaData).forEach(([_dataKey, metaData]) => {
    total += metaData.size;
  });
  return total;
}