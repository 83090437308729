import { useState, useMemo } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

const defaultStateUseMeasure = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};
export function useMeasureFixed() {

	const [element, ref] = useState(null);
	const [rect, setRect] = useState(defaultStateUseMeasure);

	const observer = useMemo(() => {
		return new ResizeObserver((entries) => {
			window.requestAnimationFrame(() => {
				if (entries?.length > 0 && entries[0]) {
					const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
					setRect({ x, y, width, height, top, left, bottom, right });
				}
			});
		})
	}, []);
	
	useIsomorphicLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

	return [ref, rect];
}
