import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

/*Class Prefix = spinner*/
import './styles.css'


const Spinner = (props) => {
	const { size } = props;

	return (
		<div
			className="spinner-root"
		>
			<CircularProgress size={size || 40} />
		</div>);
}
export default Spinner;