import React, { useState, useCallback } from 'react';
import { useToggle } from 'react-use';

import IconButton from '@material-ui/core/IconButton'

import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import TableEntryCell from './TableEntryCell';

/*Class Prefix = te*/
import './styles.css'

const TableEntry = (props) => {
	// const { onStartEdit, onFinishEdit, numRows: initialNumRows, onRemove, onResize,
	// 	numColumns: initialNumColumns, cellMap: initialCellMap, viewOnly } = props;
	const { editing, cellMap: initialCellMap, onChange, numRows: initialNumRows, numColumns: initialNumColumns } = props;

	// instantiate state variables
	const [numRows, setNumRows] = useState(initialNumRows);
	const [numColumns, setNumColumns] = useState(initialNumColumns);
	const [cellMap, setCellMap] = useState(initialCellMap);
	// const [editMode, setEditMode] = useState(false);
	const [t, toggle] = useToggle(false);
	// const [, cancel] = useDebounce(onResize, 500, [t]);

	const handleChange = useCallback((updatedData) => {
		if (updatedData.numRows) {
			setNumRows(updatedData.numRows);
		}
		if (updatedData.numColumns) {
			setNumColumns(updatedData.numColumns);
		}
		onChange({
			numRows,
			numColumns,
			cellMap,
			...updatedData,
		})
	}, [numRows, numColumns, cellMap]);

	// callback for handling input on a cell, called in TableEntryCell
	const handleCellChange = (cellKey, value) => {
		cellMap[cellKey] = value;
		const newCellMap = { ...cellMap, [cellKey]: value }
		handleChange({ cellMap: newCellMap });
	}


	const handleDeleteRow = useCallback((deletedRowIndex) => (e) => {
		const newCellMap = {};
		Object.entries(cellMap).forEach(([cellKey, value]) => {
			const rowIndex = parseInt(cellKey[0]);
			const columnIndex = parseInt(cellKey[1]);
			if (rowIndex < deletedRowIndex) {
				newCellMap[cellKey] = value;
			} else if (rowIndex > deletedRowIndex) {
				newCellMap[`${rowIndex-1}${columnIndex}`] = value;
			}
		});
		handleChange({ cellMap: newCellMap, numRows: numRows-1 })
		setCellMap(newCellMap);
		setNumRows(numRows-1);
	}, [numRows, cellMap, handleChange]);

	const handleDeleteColumn = useCallback((deletedColumnIndex) => (e) => {
		const newCellMap = {};
		Object.entries(cellMap).forEach(([cellKey, value]) => {
			const rowIndex = parseInt(cellKey[0]);
			const columnIndex = parseInt(cellKey[1]);
			if (columnIndex < deletedColumnIndex) {
				newCellMap[cellKey] = value;
			} else if (columnIndex > deletedColumnIndex) {
				newCellMap[`${rowIndex}${columnIndex-1}`] = value;
			}
		});
		handleChange({ cellMap: newCellMap, numColumns: numColumns-1 })
		setCellMap(newCellMap);
		setNumColumns(numColumns-1);
	}, [numColumns, cellMap, handleChange]);

	// increase number of rows or columns
	const handleAddRow = (e) => {
		e.stopPropagation();
		e.preventDefault();
		handleChange({ numRows: numRows+1 });
	}
	const handleAddColumn = (e) => {
		e.stopPropagation();
		handleChange({ numColumns:numColumns+1 });
	}

	// useEffect(() => {
	// 	onResize();
	// }, [numRows, numColumns, editMode]);

	return (
		<React.Fragment>
			<div className="te-root" >
				<div className="flex-row" style={{ minHeight: numRows*30+10, height: '100%' }} >
					<table>
						<tbody>
						{Array.from(Array(numRows).keys()).map((rowIndex) => (
							<tr key={`row-${rowIndex}`} >
								{editing &&
									<IconButton onClick={handleDeleteRow(rowIndex)} key={`remove-row-${rowIndex}`} color="primary" >
										<ClearIcon />
									</IconButton>
								}
								{Array.from(Array(numColumns).keys()).map((columnIndex) => {
									const cellKey = `${rowIndex}${columnIndex}`;
									return (
										<TableEntryCell
											key={`cell-${cellKey}`}
											cellKey={cellKey}
											onChange={handleCellChange}
											innerText={cellMap[cellKey]}
											editing={editing}
										/>);
								})}
							</tr>))}
							{editing &&
								<tr>
									{Array.from(Array(numColumns+1).keys()).map((columnIndex) => {
										if (columnIndex === 0) {
											return <td key={`remove-column-spacer`} className="te-hidden-cell" />
										}
										const cellKey = `remove-column-${columnIndex}`;
										return (
											<td key={cellKey} className="te-hidden-cell" >
												<IconButton onClick={handleDeleteColumn(columnIndex-1)} color="primary" >
													<ClearIcon />
												</IconButton>
											</td>);
									})}
								</tr>
							}
						</tbody>
					</table>
					{editing &&
						<IconButton onClick={handleAddColumn} color="primary" >
							<AddIcon />
						</IconButton>
					}
				</div>
				{editing &&
					<IconButton onClick={handleAddRow} color="primary" >
						<AddIcon />
					</IconButton>
				}
			</div>
		</React.Fragment>);
}

export default TableEntry;