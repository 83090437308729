import { LINED_PAPER_HEIGHT } from "../../constants/muiThemes";

// export const FONT_SIZES = [8, 10, 11, 12, 14, 18, 24, 32, 36, 48, 60, 72, 96];
export const FONT_SIZES = [14, 16, 24, 48, 72];

// This let's us style specific characters in the editor
const customStyleMap = {
	STRIKETHROUGH: {
		textDecoration: "line-through",
	},
	FONT_LARGE: {
		fontSize: 40,
		lineHeight: '40px',	// should be 2x var(--lined-paper-height)
	},
	HIGHLIGHT: {
		backgroundColor: '#EFFD5F',
	},
	TEXT_ALIGN_LEFT: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	TEXT_ALIGN_CENTER: {
		display: 'flex',
		justifyContent: 'center',
	},
	TEXT_ALIGN_RIGHT: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}

FONT_SIZES.forEach((size) => {
	customStyleMap[`FONT_SIZE_${size}`] = {
		fontSize: size,
		lineHeight: `${Math.ceil(size / LINED_PAPER_HEIGHT) * LINED_PAPER_HEIGHT}px`,	// lined-paper-height
	}
})

export default customStyleMap;