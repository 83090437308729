import React, { useMemo } from 'react';

import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const HelpButton = function(props) {
	const { message, title, style={}, tooltipProps={}, iconSize=20 } = props;

	const iconStyle = useMemo(() => ({ ...style, fontSize: iconSize }), [style, iconSize]);

	return (
		<Tooltip
			title={title || message}
			arrow
			delay={500}
			style={{ fontSize: '1rem', color: '#fff' }}
			{...tooltipProps}
		>
			<InfoIcon style={iconStyle} />
		</Tooltip>
	);
}

export default HelpButton;