
const katexPresets = { isLoading: false, data: null };

export default katexPresets;

const KATEX_PRESETS_RESOURCE = {
	key: "katex-presets",
	filePath: 'misc_files/katexPresetsv6.json',
};

const onLoadResourceCallback = (resourceKey, rawBlob) => {
	const reader = new FileReader();

	reader.onload = (e) => {
		katexPresets.isLoading = false;
		katexPresets.data = JSON.parse(e.srcElement.result).data;
	};

	reader.readAsText(rawBlob);
}

export { KATEX_PRESETS_RESOURCE, onLoadResourceCallback }