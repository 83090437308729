
export const INITIALIZE_FLASHCARDS = 'INITIALIZE_FLASHCARDS';

export const initializeFlashcards = (flashcards) => {
  return {
    type: INITIALIZE_FLASHCARDS,
    payload: flashcards,
  }
}


export const CHANGE_FLASHCARDS_NAME = 'CHANGE_FLASHCARDS_NAME';

export const changeFlashcardsName = (deckId, name) => {
  return {
    type: CHANGE_FLASHCARDS_NAME,
    payload: {
      deckId,
      name,
    }
  }
}

export const ADD_FLASHCARDS = 'ADD_FLASHCARDS';

export const addFlashcards = (courseId, deckId, name) => {
  return {
    type: ADD_FLASHCARDS,
    payload: {
      courseId,
      deckId,
      name,
    }
  }
}

export const DELETE_FLASHCARDS = 'DELETE_FLASHCARDS';

export const deleteFlashcards = (courseId, deckId) => {
  return {
    type: DELETE_FLASHCARDS,
    payload: {
      courseId,
      deckId,
    }
  }
}

export const UPDATE_FLASHCARDS_SIZE = 'UPDATE_FLASHCARDS_SIZE';

export const updateFlashcardsSize = (courseId, deckId, size) => {
  return {
    type: UPDATE_FLASHCARDS_SIZE,
    payload: {
      courseId,
      deckId,
      size,
    },
  }
}