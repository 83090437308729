
// actions are usually written in CAPITAL_SNAKE_CASE

export const FETCHING_PROFILE = 'FETCHING_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAIL = 'FETCH_PROFILE_FAIL';

export const fetchingProfile = () => {
  return {
    type: FETCHING_PROFILE,
  }
}

export const fetchProfileSuccess = (userData) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: userData,
  }
}

export const fetchProfileFail = (err) => {
  return {
    type: FETCH_PROFILE_FAIL,
    payload: err,
  };
}

export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const createProfileSuccess = (email, firstName, lastName) => {
  return {
    type: CREATE_PROFILE_SUCCESS,
    payload: {
      email,
      firstName,
      lastName,
    }
  }
}

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const changeEmail = (newEmail) => {
  return {
    type: CHANGE_EMAIL,
    payload: newEmail,
  }
}

export const CHANGE_ENRICHMENT_PREFS = 'CHANGE_ENRICHMENT_PREFS';
export const changeEnrichmentPrefs = (updatedPrefs) => {
  return {
    type: CHANGE_ENRICHMENT_PREFS,
    payload: updatedPrefs,
  }
}

export const SIGN_OUT = 'SIGN_OUT';
export const signOut = () => {
  return {
    type: SIGN_OUT,
  }
}

export const CHANGE_THEME = 'CHANGE_THEME';
export const changeTheme = (newTheme) => {
  return {
    type: CHANGE_THEME,
    payload: newTheme,
  }
}

export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const updateProfile = (updatedValues) => {
  return {
    type: UPDATE_PROFILE,
    payload: updatedValues,
  }
}