import React, { useCallback, useState } from 'react';

/*Class Prefix = tb*/
import './styles.css';

const TextBox = function(props) {
  const { content, onChange } = props;

  const [value, setValue] = useState(content);

  const handleChange = useCallback((ev) => {
    setValue(ev.target.value)
    onChange({ content: ev.target.value });
  }, [onChange]);

	return (
    <textarea
      value={value}
      onChange={handleChange}
      className="tb-root"
    />
  );
}

export default TextBox;