import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { changeCourseColor, changeCourseName } from '../../data/courses/actions';
import { removeDialog } from '../../data/dialogs/actions';
import firebase from '../../Firebase';

import Transition from '../SlideTransition';

const EditCourseDialog = function(props) {
  const { onClose, course } = props;

  const [newName, setNewName] = useState(course?.name);
  const [newColor, setNewColor] = useState(course?.color);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (newName === course.name && newColor === course.color)	return;
    setLoading(true)
    const toUpdate = {};
    if (newName !== course.name) {
      toUpdate['name'] = newName;
    }
    if (newColor !== course.color) {
      toUpdate['color'] = newColor;
    }
    firebase.updateCourse(course.id, toUpdate).then((_resp) => {
      dispatch(changeCourseColor(course.id, newColor));
      dispatch(changeCourseName(course.id, newName));
      dispatch(removeDialog('edit-course'))
    }).catch((err) => {
      dispatch(removeDialog('edit-course'))
    })
  }

  return (
    <Dialog
			open
			onClose={onClose}
			TransitionComponent={Transition}
		>
      <DialogTitle>
        Edit Folder
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" >
          <TextField
            value={newName}
            placeholder="Folder name"
            onChange={(e) => setNewName(e.target.value)}
            variant="outlined"
            label="Folder name"
          />
          <Box display="flex" alignItems="center" component="span" justifyContent="space-between" mt={2} >
            Color: 
            <div
              style={{
                backgroundColor: newColor || '#000',
                cursor: 'pointer',
                width: 40,
                minWidth: 40,
                height: 40,
                borderRadius: '50%',
                border: '1px solid #666',
                overflow: 'hidden',
                margin: '0 0 0 16px',
              }}
            >
              <input
                type="color"
                name="new-folder-color"
                id="new-folder-color"
                style={{ opacity: 0, width: '100%', height: '100%', borderRadius: '50%', cursor: 'pointer' }}
                value={newColor}
                onChange={(e) => { setNewColor(e.target.value) }}
              />
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.onClose}
          >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCourseDialog;