import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { removeDialog } from '../../data/dialogs/actions';

// import logger from '../../utils/logger';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';

import Transition from '../SlideTransition';

const useStyles = makeStyles((_theme) => ({
	input: {
		margin: `8px 0`,
		minWidth: 250,
	},
}));

const FeedbackDialog = function(props) {

	const classes = useStyles();
  const dispatch = useDispatch();

  const [feedbackText, setFeedbackText] = useState('');

  const handleClose = () => {
    props.dialogId && dispatch(removeDialog(props.dialogId));
  }

  const handleSubmit = () => {
    Mixpanel.track(EVENTS.userFeedback, { feedbackText })
    handleClose();
  }

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<DialogTitle>
				Send Feedback
			</DialogTitle>
			<DialogContent>
        <Typography>
          Let us know if you see something broken or have a suggestion for Jotly
        </Typography>
        <TextField
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          variant="outlined"
          rows={4}
          maxRows={4}
          className={classes.input}
          multiline
          fullWidth
        />
			</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!feedbackText}
        >
          submit
        </Button>
      </DialogActions>
		</Dialog>
	);
}

export default FeedbackDialog;