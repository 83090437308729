
// logger for only logging while in development mode
//	- needs work
class DevLogger {

	constructor() {
		this.dontLog = process.env.NODE_ENV === "production";
	}

	log(...args) {
		if (this.dontLog)	return;
		console.log(...args);
	}

	error(...args) {
		if (this.dontLog)	return;

		console.error(...args);
	}

}

const devLogger = new DevLogger();
export default devLogger;