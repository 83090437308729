
// actions are usually written in CAPITAL_SNAKE_CASE

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const REMOVE_DIALOG = 'REMOVE_DIALOG';

export const showDialog = (id, key, props) => {
  return {
    type: SHOW_DIALOG,
		payload: {
			id,
			key,
      props,
		}
  }
}

export const removeDialog = (id) => {
  return {
    type: REMOVE_DIALOG,
		payload: id,
  }
}