import Bugsnag from '@bugsnag/js';

import { DELETE_FLASHCARDS } from '../flashcards/actions';
import { DELETE_NOTEBOOK } from '../notebooks/actions';
import { SIGN_OUT } from '../profile/actions';
import * as actions from './actions';

import logger from '../../utils/logger'

// going to be a list of objects like { key: <one of below>, fileId: <file id if notebook or flashcards>, name: <string> }

// first let's define the initialState for instantiation of the store
const initialTabsList = [];
const initialState = {
  isLoading: true,
  activeTabIndex: 0,
  showNewTabView: false,
  tabsList: initialTabsList.slice(),
}

const saveTabsToLocalStorage = (tabsList, activeTabIndex) => {
  try {
    tabsList && localStorage.setItem('tabsList', JSON.stringify(tabsList));
    if (activeTabIndex || activeTabIndex === 0) {
      localStorage.setItem('activeTabIndex', JSON.stringify(activeTabIndex));
    }
  } catch (error) {
		Bugsnag.notify(error);
    logger.log('Error saving tabs to local storage', error);
  }
}

const tabsReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
  switch (action.type) {
    case actions.INITIALIZE_TABS: {
      let { tabsList, activeTabIndex } = action.payload;
      tabsList = tabsList?.slice() || initialTabsList;
      activeTabIndex = activeTabIndex || 0;
      return { ...state, isLoading: false, activeTabIndex, tabsList, showNewTabView: tabsList.length === 0 };
    }
    case actions.SHOW_NEW_TAB_VIEW: {
      return { ...state, showNewTabView: true };
    }
    case actions.ADD_TAB: {
			const newTabsList = state.tabsList.slice();
			newTabsList.push(action.payload);
      saveTabsToLocalStorage(newTabsList, newTabsList.length - 1)
      return { ...state, activeTabIndex: newTabsList.length - 1, tabsList: newTabsList, showNewTabView: false };
    }
    case actions.REMOVE_TAB: {
      let newTabsList = state.tabsList.filter((_tab, index) => index !== action.payload);
      newTabsList = newTabsList.length <= 0 ? initialTabsList.slice() : newTabsList;
      const newActiveIndex = state.activeTabIndex >= newTabsList.length ? newTabsList.length -1 : state.activeTabIndex;
      saveTabsToLocalStorage(newTabsList, newActiveIndex);
      return { ...state, activeTabIndex: newActiveIndex, tabsList: newTabsList, showNewTabView: newTabsList.length === 0 };
    }
    case actions.REPLACE_TAB: {
      const newTabsList = state.tabsList.map((tab, index) => action.payload.indexToReplace === index ? { ...action.payload } : tab);
      saveTabsToLocalStorage(newTabsList, action.payload.indexToReplace)
      return { ...state, activeTabIndex: action.payload.indexToReplace, tabsList: newTabsList, showNewTabView: false };
    }
    case actions.OPEN_FILE_IN_TAB: {
      // combo of add and replace but doesn't open duplicate files
      const { indexToReplace, key, name, fileId, courseId } = action.payload;
      // first check if the file is already open in a tab
      const currentTabWithFileIndex = state.tabsList.findIndex((tab) => tab.fileId === fileId);
      if (currentTabWithFileIndex >= 0) { // if it is just switch to that tab
        return { ...state, activeTabIndex: currentTabWithFileIndex, showNewTabView: false };
      }
      let newTabsList;
      let newActiveIndex;
      if (indexToReplace || indexToReplace === 0) {
        newTabsList = state.tabsList.map((tab, index) => action.payload.indexToReplace === index ? { ...action.payload } : tab);
        newActiveIndex = indexToReplace;
      } else {
        newTabsList = state.tabsList.slice();
        newTabsList.push({ key, name, fileId, courseId });
        newActiveIndex = newTabsList.length - 1;
      }
      saveTabsToLocalStorage(newTabsList, newActiveIndex)
      return { ...state, tabsList: newTabsList || [], activeTabIndex: newActiveIndex, showNewTabView: false };
    }
    case actions.CHANGE_ACTIVE_TAB: {
      saveTabsToLocalStorage(null, action.payload);
      return { ...state, activeTabIndex: action.payload, showNewTabView: false };
    }
    case DELETE_NOTEBOOK:
    case DELETE_FLASHCARDS: {
      const { courseId, notebookId, deckId } = action.payload
      const newTabsList = state.tabsList.filter((tab) => tab.courseId !== courseId && tab.fileId !== notebookId && tab.fileId !== deckId);
      if (newTabsList.length !== state.tabsList.length) {
        const newActiveIndex = Math.min(state.activeTabIndex, newTabsList.length - 1);
        saveTabsToLocalStorage(newTabsList, newActiveIndex)
        return { ...state, activeTabIndex: newActiveIndex, tabsList: newTabsList, showNewTabView: newTabsList.length === 0 }
      }
      return state;
    }
    case SIGN_OUT: {
      localStorage.setItem('tabsList', null);
      localStorage.setItem('activeTabIndex', null);
      return { ...initialState, isLoading: false };
    }
    default:
      return state; // the action doesn't affect the profile store so nothing chaning
  }
}

export default tabsReducer;