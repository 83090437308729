import React from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { removeDialog } from '../../data/dialogs/actions';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// import logger from '../../utils/logger';

import Transition from '../SlideTransition';
import PremiumPlans from '../../components/PremiumPlans';

const PremiumPlansDialog = function(props) {

	const dispatch = useDispatch();

	const handleClose = () => {
		props.dialogId && dispatch(removeDialog(props.dialogId));
	}

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			{/* <DialogTitle>
				Go Pro
			</DialogTitle> */}
			<DialogContent>
				<Box display="flex" justifyContent="flex-end" width="100%">
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<PremiumPlans />
			</DialogContent>
		</Dialog>
	);
}

export default PremiumPlansDialog;