import { getNotebooksById, getNumNotebooks } from "../notebooks/selectors";
import { getFlashcardsById, getNumFlashcards } from "../flashcards/selectors";
import { getTotalMediaSize } from '../metaData/selectors';

import { priceIdToTier } from "../../utils/stripe";
import { Plans } from "../plans";

export const getProfile = (state) => state.profile;
export const getEnrichmentPrefs = (state) => state.profile.enrichmentPrefs;
export const getPremiumTier = (state) => {
  const priceId = state.profile?.subscription?.priceId;
  const paymentStatus = state.profile?.subscription?.paymentStatus;
  return priceId && paymentStatus === 'PAID' ? priceIdToTier[priceId] : 'BASIC';
}

export const getSubscription = (state) => state.profile?.subscription;

export const getUserLimits = (state) => {
  const tier = getPremiumTier(state);
  if (tier === 'PRO') {
    return Plans.PRO;
  }
  // BASIC PRICING
  return Plans.BASIC;
}

export const getNumUserSavedBytes = (state) => {
  const sizeOfNotebooks = Object.values(getNotebooksById(state)).reduce((accum, nb) => accum + parseInt(nb.metaData ? nb.metaData.size : 0), 0);
  const sizeOfFlashcards = Object.values(getFlashcardsById(state)).reduce((accum, deck) => accum + parseInt(deck.metaData ? deck.metaData.size : 0), 0);
  const sizeOfMedia = getTotalMediaSize(state);
  return sizeOfNotebooks + sizeOfFlashcards + sizeOfMedia;
}

export const getUserLimitReached = (state) => {
	const limits = getUserLimits(state);
	const storageSize = getNumUserSavedBytes(state);
	const numNotebooks = getNumNotebooks(state);
	const numFlashcards = getNumFlashcards(state);
	return {
		notebooks: numNotebooks >= limits.notebooks,
		flashcards: numFlashcards >= limits.flashcards,
		storage: storageSize >= limits.storage,
	}
}

export const getButterBarText = (state) => {
	const tier = getPremiumTier(state);
	if (tier === 'BASIC') {
		const isCloseToHittingLimit = getNumNotebooks(state) >= 5
			|| getNumFlashcards(state) >= 5
			|| getNumUserSavedBytes(state) >= 400000000;
			if (isCloseToHittingLimit) {
				const numMB = (getNumUserSavedBytes(state) / 1000000).toFixed(2);
				const usageString = `${getNumNotebooks(state)}/${Plans.BASIC.notebooks} Notebooks,
				${getNumFlashcards(state)}/${Plans.BASIC.flashcards} Flashcards,
				and ${numMB}/${Plans.BASIC.storage / 1000000}mb Storage`
				return `You're close to hitting your plan's limits, you have ${usageString}.`;
			}
	}
}