import React from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import { removeDialog } from '../../data/dialogs/actions';


import Transition from '../SlideTransition';


const CustomDialog = function(props) {
  const { Component } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    props.dialogId && dispatch(removeDialog(props.dialogId));
  }

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<Component onClose={handleClose} />
		</Dialog>
	);
}

export default CustomDialog;