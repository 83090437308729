import { DEFAULT_ENRICHMENT_PREFS } from '../components/EnrichmentPrefs';
import { makeNotebookPreferences, makeFlashcardsPreferences } from './defaults';

// these are helper functions to transform data from firebase to what we expect


export function transformUser(userDoc) {
	const user = userDoc.data();
	return {
		id: userDoc.id,
		firstName: user.first_name,
		lastName: user.last_name,
		email: user.email,
		enrichmentPrefs: user.enrichment_prefs ? { ...DEFAULT_ENRICHMENT_PREFS, ...user.enrichmentPrefs } : { ...DEFAULT_ENRICHMENT_PREFS },
		isTestAccount: Boolean(user.isTestAccount),
		theme: user.theme || 'jotly',
		subscription: user.subscription || {},
		dismissedNotebookTutorial: user.dismissedNotebookTutorial || false,
		controlBoxOpen: user.controlBoxOpen === true ,
	};
}

export function transformCourse(courseDoc) {
	const course = courseDoc.data();

	// convert notebooks and flashcards into objects
	const flashcards = {};
	course.flashcards && course.flashcards.forEach((deck) => flashcards[deck.id] = { ...deck });
	const notebooks = {};
	course.notebooks && course.notebooks.forEach((nb) => notebooks[nb.id] = { ...nb });

	return {
		id: courseDoc.id,
		name: course.name,
		color: course.color,
		flashcards,
		notebooks,
	};
}

export function transformStorageMetaData(metaData, resourceType, fromPublic=false) {
	let userPreferences = {};
	if (metaData.customMetadata && resourceType !== 'media' && !fromPublic) {	// the customMetaData is the preferences
		userPreferences = { ...metaData.customMetadata };
		if (metaData.customMetadata.showTOC) {
			userPreferences.showTOC = userPreferences.showTOC === 'true' ? true : false;
		}
		if (metaData.customMetadata.enableOtto) {
			userPreferences.enableOtto = userPreferences.enableOtto === 'true' ? true : false;
		}
		if (metaData.customMetadata.enableAutoCorrect) {
			userPreferences.enableAutoCorrect = userPreferences.enableAutoCorrect === 'true' ? true : false;
		}
	}
	let defaultPreferences;
	switch (resourceType) {
		case 'notebook':
			defaultPreferences = makeNotebookPreferences();
			break;
		case 'flashcards':
			defaultPreferences = makeFlashcardsPreferences();
			break;
		default:
			defaultPreferences = {};
	}

	return {
		size: metaData.size,
		filePath: metaData.fullPath,
		fileName: metaData.name,
		updated: metaData.updated,
		timeCreated: metaData.timeCreated,
		preferences: { ...defaultPreferences, ...userPreferences },
		publicMeta: fromPublic ? metaData.customMetadata : null,
	}
}