
import AddItemDialog from '../../dialogs/AddFile';
import ConfirmDeleteDialog from '../../dialogs/ConfirmDelete';
import FeedbackDialog from '../../dialogs/Feedback';
import RenameDialog from '../../dialogs/RenameDialog';
import ConversionDialog from '../../dialogs/ConversionDialog';
import CustomDialog from '../../dialogs/CustomDialog';
import EditCourseDialog from '../../dialogs/EditCourseDialog';
import ShareFileDialog from '../../dialogs/ShareFileDialog';
import PremiumPlansDialog from '../../dialogs/PremiumPlans';
import StudyDeckDialog from '../../dialogs/StudyDeck';

export const ADD_FILE_DIALOG_KEY = 'ADD_FILE';
export const RENAME_DIALOG_KEY = 'RENAME';
export const CONFIRM_DELETE_DIALOG_KEY = 'CONFIRM_DELETE';
export const FEEDBACK_DIALOG_KEY = 'FEEDBACK_DIALOG';
export const CONVERSION_DIALOG_KEY = 'CONVERSION';
export const CUSTOM_DIALOG_KEY = 'CUSTOM';
export const EDIT_COURSE_DIALOG_KEY = 'EDIT_COURSE_DIALOG_KEY';
export const SHARE_FILE_DIALOG_KEY = 'SHARE_FILE';
export const PREMIUM_PLANS_DIALOG_KEY = 'PREMIUM_PLANS';
export const STUDY_DECK_DIALOG_KEY = 'STUDY_DECK';

const KEYS_TO_COMPONENTS = {
	[ADD_FILE_DIALOG_KEY]: AddItemDialog,
	[RENAME_DIALOG_KEY]: RenameDialog,
	[CONFIRM_DELETE_DIALOG_KEY]: ConfirmDeleteDialog,
	[FEEDBACK_DIALOG_KEY]: FeedbackDialog,
	[CONVERSION_DIALOG_KEY]: ConversionDialog,
	[CUSTOM_DIALOG_KEY]: CustomDialog,
	[EDIT_COURSE_DIALOG_KEY]: EditCourseDialog,
	[SHARE_FILE_DIALOG_KEY]: ShareFileDialog,
	[PREMIUM_PLANS_DIALOG_KEY]: PremiumPlansDialog,
	[STUDY_DECK_DIALOG_KEY]: StudyDeckDialog,
}
export default KEYS_TO_COMPONENTS;