import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import store from './data/store';
import { DEPLOYMENT_VERSION_NUMBER } from './Firebase/firebase';

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
	appVersion: DEPLOYMENT_VERSION_NUMBER,
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

// const landingPageRoot = document.getElementById("landing-root");
// landingPageRoot && landingPageRoot.remove();

const fallbackCopy = "Uh oh, sorry about that. Please refresh the page. If the problem persists please contact jotlynotes@gmail.com."
class Fallback extends React.Component {
	render = () => {
		return <div>{fallbackCopy}</div>
	}
}

// render the App component, this is like the root of the React app
ReactDOM.render(
	(<Provider store={store} >
		<RecoilRoot>
			<ErrorBoundary FallbackComponent={Fallback}>
				<App />
			</ErrorBoundary>
		</RecoilRoot>
	</Provider>),
	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();