
// actions are usually written in CAPITAL_SNAKE_CASE

export const ADD_TAB = 'ADD_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const REPLACE_TAB = 'REPLACE_TAB';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
export const OPEN_FILE_IN_TAB = 'OPEN_FILE_IN_TAB';
export const INITIALIZE_TABS = 'INITIALIZE_TABS';
export const SHOW_NEW_TAB_VIEW = 'SHOW_NEW_TAB_VIEW';

export const addTab = (key, name, courseId, fileId=null) => {
  return {
    type: ADD_TAB,
		payload: {
			key,
      name,
      courseId,
			fileId,
		}
  }
}

export const removeTab = (index) => {
  return {
    type: REMOVE_TAB,
		payload: index,
  }
}

export const replaceTab = (indexToReplace, key, name, courseId, fileId) => {
  return {
    type: REPLACE_TAB,
		payload: {
      indexToReplace,
			key,
      name,
      courseId,
			fileId,
		}
  }
}

export const changeActiveTab = (index) => {
  return {
    type: CHANGE_ACTIVE_TAB,
    payload: index,
  }
}

export const openFileInTab = (key, name, courseId, fileId, indexToReplace) => {
  return {
    type: OPEN_FILE_IN_TAB,
    payload: {
      key,
      name,
      courseId,
      fileId,
      indexToReplace,
    },
  }
}

export const initializeTabs = (tabsList, activeTabIndex) => {
  return {
    type: INITIALIZE_TABS,
    payload: {
      tabsList,
      activeTabIndex,
    }
  }
}

export const openNewTabView = () => {
  return {
    type: SHOW_NEW_TAB_VIEW,
    payload: true,
  }
}