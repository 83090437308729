import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

import {
  createCheckoutSession,
  createCustomerPortalSession,
} from "../utils/stripe";
import { getPremiumTier } from "../data/profile/selectors";
import Mixpanel, { EVENTS } from "../utils/mixpanel";
import { Plans } from "../data/plans";

import logger from "../utils/logger";

const useStyles = makeStyles((theme) => ({
  accountTypeCard: {
    margin: "42px 16px 24px",
    padding: 16,
    borderRadius: 16,
    boxShadow: "0px 2px 12px -2px #CCC",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 250,
    textAlign: "left",
    position: "relative",
    justifyContent: "space-between",
    width: 300,
    height: 400,
    fontSize: 18,
    "& ul": {
      alignSelf: "flex-start",
      paddingLeft: 16,
      width: "100%",
    },
  },
  accountTypeCardActive: {
    "&::before": {
      position: "absolute",
      top: -36,
      left: -10,
      width: "calc(100% + 16px)",
      height: "calc(100% + 36px)",
      paddingTop: 4,
      border: `2px solid ${theme.palette.secondary.main}`,
      content: '"You have"',
      color: theme.palette.secondary.main,
      textAlign: "center",
      borderRadius: 16,
    },
  },
}));

const PremiumPlans = function (props) {
  const classes = useStyles();
  const tier = useSelector(getPremiumTier);

  const [loading, setLoading] = useState(false);

  const handleUpgradeToPro = () => {
    setLoading(true);
    Mixpanel.track(EVENTS.clickToUpgrade, { tier: "PRO" });
    createCheckoutSession(
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_STRIPE_PRO_PRICE_ID_PRODUCTION
        : process.env.REACT_APP_STRIPE_PRO_PRICE_ID_DEVELOPMENT
    );
  };

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        <div
          className={`${classes.accountTypeCard} ${
            !["PRO", "PRO_PLUS"].includes(tier)
              ? classes.accountTypeCardActive
              : ""
          }`}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className="account-type-card-title">{Plans.BASIC.name}</div>
            <ul>
              <li>{Plans.BASIC.storage / 1000000}MB of storage</li>
              <li>Notebook with AI enrichment and formatting</li>
              <li>{Plans.BASIC.notebooks} notebooks</li>
              <li>{Plans.BASIC.notebooks} flashcard decks</li>
              <li>Convert your notes into flashcards</li>
              <li>Flashcard editing and studying interface</li>
              <li>Dark Mode</li>
            </ul>
          </Box>
          <div className="account-type-card-title">{Plans.BASIC.cost}</div>
        </div>
      </Box>
      {/* <div className="flex-column-center" >
				<div className={`${classes.accountTypeCard} ${tier === 'PRO' ? classes.accountTypeCardActive : ''}`} >
					<Box display="flex" flexDirection="column" alignItems="center" >
						<div className="account-type-card-title" >{Plans.PRO.name}</div>
						<ul>
							<li className="account-type-everything" >Everything in Basic</li>
							<li>Unlimited notebooks</li>
							<li>Unlimited flashcard decks</li>
							<li>1GB of storage</li>
							<li>Collection of 5 account themes</li>
							<li>24/7 Customer Support</li>
						</ul>
					</Box>
					<div className="account-type-card-title" >
						{Plans.PRO.cost}
					</div>
				</div>
				<Button
					color="secondary"
					variant="contained"
					type="submit"
					disabled={tier === 'PRO' || loading}
					onClick={handleUpgradeToPro}
				>
					get now
				</Button>
			</div> */}
      {false && (
        <div className="flex-column-center">
          <div
            className={`${classes.accountTypeCard} ${
              tier === "PRO_PLUS" ? classes.accountTypeCardActive : ""
            }`}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <div className="account-type-card-title">Pro +</div>
              <ul>
                <li className="account-type-everything">Everything in Pro</li>
                <li>10GB storage</li>
                <li>Entire collection of account themes</li>
              </ul>
            </Box>
            <div className="account-type-card-title">$9.99/mo</div>
          </div>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={tier === "PRO_PLUS" || loading}
            onClick={() => {
              createCheckoutSession(
                process.env.NODE_ENV === "production"
                  ? process.env.REACT_APP_STRIPE_PRO_PRICE_ID_PRODUCTION
                  : process.env.REACT_APP_STRIPE_PRO_PRICE_ID_DEVELOPMENT
              );
            }}
          >
            get now
          </Button>
        </div>
      )}
    </Box>
  );
};

export default PremiumPlans;
