import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';



import Transition from '../SlideTransition';

const ConfirmDeleteDialog = function(props) {	// doesn't remove itself
	const { onSubmit, name, onClose, loadingOnSubmit } = props;

	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		onSubmit();
		if (loadingOnSubmit) {
			setLoading(true);
		}
	}

	return (
		<Dialog
			open
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<DialogTitle>
				Are you sure you want to delete <b>{name}</b>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" >
					Deleting is permanent
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={onClose}
					disabled={loading}
				>
					cancel
				</Button>
				<Button
					variant="contained"
					color="secondary"
					onClick={handleSubmit}
					disabled={loading}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDeleteDialog;