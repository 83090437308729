import EquationEntry from './EquationEntry';
import TableEntry from './TableEntry';
import CodeBlock from './CodeBlock';
import TextBox from './TextBox';
import Image from '../../../components/Editor/components/Image';

const ATOMIC_BLOCK_TYPES = {
	'equation-entry': {
		label: 'Equation',
		emptyData: { latexContent: '', width: 300, height: 30 * 2 },
		component: EquationEntry,
	},
	'table-entry': {
		label: 'Table',
		emptyData: { numRows: 2, numColumns: 2, cellMap: {}, width: 300, height: 300 },
		component: TableEntry,
	},
	'code-block': {
		label: 'Code Block',
		emptyData: { content: '', width: 300, height: 240 },
		component: CodeBlock,
	},
	'text-box': {
		label: 'Text Box',
		emptyData: { content: '', width: 300, height: 240 },
		component: TextBox,
	},
	'image': {
		label: 'Image',
		emptyData: { id: null, width: 300, height: 300 },
		component: Image,
	},
	// 'split-page': {
	// 	label: 'Split Page',
	// 	emptyData: { columns: [{ contentType: 'text', content: '' }, { contentType: 'text', content: '' }], },
	// 	component: SplitPage,
	// },
}

export default ATOMIC_BLOCK_TYPES