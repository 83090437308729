import {
	Modifier,
	EditorState,
	SelectionState,
} from 'draft-js';
import { moveSelectionToEndOfCurrentBlock } from '../../components/Editor/utils';

/*	Key Handlers - functions to handle specific key events
*			- called from handleKeyCommand in Notebook/index
*			- each one should take the editorState and the event
*			- all must return the updated editorState whether or not it changed
*/



// handle the tab button being pressed
const MAX_DEPTH = 4;
export function handleTab(editorState, withShift) {

	var newEditorState = editorState;
	var contentState = newEditorState.getCurrentContent();

	// declaration of regular expressions to use
	const orderedListRegex = /^ ?\d\.?/g;	// regex for start of string, 0 or one space, a digit, then a dot or a space
	const unorderedListRegex = /^ ?[-+>*~] ?/g;	// regex for start of string, 0 or 1 space, listing punctuation, then 0 or 1 space
	const firstLetterRegex = /[a-zA-z]/g;	//regex for single character

	// first we want to get the selection range of blocks - this way we can iterate over and handle each differently
	const originalSelection = newEditorState.getSelection();
	const selectionStartKey = originalSelection.getStartKey();
	const selectionEndKey = originalSelection.getEndKey();

	var stateChange = false;	// keep track if we changed anything or not

	var currentBlock = contentState.getBlockForKey(selectionStartKey);
	while (currentBlock) {	// basically keep going - we will break the loop after handling the last block
		const originalCurrentBlockText = currentBlock.getText();
		const emptyBlockSelection = SelectionState.createEmpty(currentBlock.getKey());

		// if it's already in a we need to adjust the depth
		if (currentBlock.getType() === 'unordered-list-item' || currentBlock.getType() === 'ordered-list-item' || currentBlock.getType() === 'unordered-list-item-hyphen') {

			if (withShift && currentBlock.getDepth() === 0 && currentBlock.getType() !== 'unstyled') {	// removing the indentation
				contentState = Modifier.setBlockType(contentState, emptyBlockSelection, 'unstyled');	// remove list styling
				stateChange = 'change-block-type';
			} else if (currentBlock.getDepth() < MAX_DEPTH || withShift) {	// ensure we're not at max depth or we're unindenting
				const depthAdjustment = withShift ? -1 : 1;
				const adjustedBlock = currentBlock.set('depth', currentBlock.getDepth() + depthAdjustment);	// adjust the depth
				contentState = contentState.merge({
					blockMap: contentState.getBlockMap().set(currentBlock.getKey(), adjustedBlock),	// update the blockMap
				});
				stateChange = 'adjust-depth';
			}

		} else if (!withShift) {	// the block is not a list item and they are trying to indent not un-indent
			// This if statement handles the case where there is already punctuation for the list so we need to remove it
			if (originalCurrentBlockText.search(orderedListRegex) !== -1 || originalCurrentBlockText.search(unorderedListRegex) !== -1) {
				let numberRange = SelectionState.createEmpty(currentBlock.getKey());
				const endOffset = originalCurrentBlockText.search(firstLetterRegex) !== -1	// set endOffset to first character or end of string
					? originalCurrentBlockText.search(firstLetterRegex) : currentBlock.getLength();
				numberRange = numberRange.merge({
					anchorKey: currentBlock.getKey(),
					anchorOffset: 0,
					focusKey: currentBlock.getKey(),
					focusOffset: endOffset,	// up to first lett or end of block,
				});
				contentState = Modifier.removeRange(contentState, numberRange);
			}

			// Now that we're here the block should no longer have punctuation so we jsut need to convert it to the list
			const listType = originalCurrentBlockText.search(orderedListRegex) !== -1 ? 'ordered-list-item' : 'unordered-list-item';	// type of list
			contentState = Modifier.setBlockType(contentState, emptyBlockSelection, listType);	// update the block type
			stateChange = 'change-block-type';

		}


		if (currentBlock.getKey() === selectionEndKey) {	// we've just handled the final block
			break;
		}
		currentBlock = contentState.getBlockAfter(currentBlock.getKey());	// increment to next key
	}

	if (stateChange) {	// if we changed the state
		// By doing EditorState.push() below we basically set a new checkpoint for 'undo'
		newEditorState = EditorState.push(newEditorState, contentState, stateChange);	// update the editor state
		// I'm not certain 'stateChange' necessarily has to be correct but definitely need it for undo purposes
		if (originalSelection.getEndKey() !== originalSelection.getStartKey()) {
			// if it's a multiline selection then reset to original
			newEditorState = EditorState.forceSelection(newEditorState, originalSelection);	// reset the selection
		} else {
			// if it's a single block selection move to the end of the current line
			newEditorState = moveSelectionToEndOfCurrentBlock(newEditorState)
		}
	}


	return newEditorState;
}

export function handleSpace(editorState) {
const newContent = Modifier.insertText(
		editorState.getCurrentContent(),
		editorState.getSelection(),
		' ',
		editorState.getCurrentInlineStyle(),
	);
	const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
	return newEditorState;
}