import * as actions from './actions';
import { SIGN_OUT } from '../profile/actions';
import { INITIALIZE_META_DATA, UPDATE_META_DATA } from '../metaData/actions';

// going to be flashcards in an object organized by notebookId as the key

// first let's define the initialState for instantiation of the store
const initialState = {}

const notebooksReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
switch (action.type) {
    case actions.INITIALIZE_NOTEBOOKS:
      return { ...action.payload };
    case actions.CHANGE_NOTEBOOK_NAME:
      const { notebookId, name } = action.payload;
      return {
        ...state,
        [notebookId]: { ...state[notebookId], name }, // update name
      };
      case actions.ADD_NOTEBOOK:
        return {
          ...state,
          [action.payload.notebookId]: {
            courseId: action.payload.courseId,
            id: action.payload.notebookId,
            name: action.payload.name,
          }
        }
      case actions.DELETE_NOTEBOOK:
        const newState = { ...state };
        delete newState[action.payload.notebookId];
        return newState;
      case INITIALIZE_META_DATA:
        const stateWithMetaData = { ...state };
        Object.keys(action.payload).forEach((dataKey) => {
          const [_courseId, subsection, fileId] = dataKey.split('_');
          if (subsection !== 'notebooks' || !state[fileId]) return state;
          stateWithMetaData[fileId].metaData = { ...action.payload[dataKey] };
        });
        return stateWithMetaData;
      case UPDATE_META_DATA:
        if (action.payload.subsection !== 'notebooks' || !state[action.payload.fileId]) return state;
        return {
          ...state,
          [action.payload.fileId]: {
            ...state[action.payload.fileId],
            metaData: {
              ...action.payload.updatedMetaData
            }
          }
        }
      case actions.UPDATE_NOTEBOOK_SIZE:
        return {
          ...state,
          [action.payload.notebookId]: {
            ...state[action.payload.notebookId],
            metaData: {
              ...state[action.payload.notebookId].metaData,
              size: action.payload.size,
            }
          }
        }
      case SIGN_OUT:
        return {};
      default:
      return state; // the action doesn't affect the profile store so nothing chaning
  }
}

export default notebooksReducer;