import React from 'react';
import { CompositeDecorator } from 'draft-js';
import { backtrackWordOffset } from '../utils';

/*Class Prefix = dec*/
import './styles.css'

// searches for a match to the regex and calls the callback with the start and end of the match
function findInContentWithRegex(regex, contentBlock, callback) {
	const text = contentBlock.getText();
	let matchArr, start;
	while ((matchArr = regex.exec(text)) !== null) {
		start = matchArr.index;
		callback(start, start + matchArr[0].length);
	}
}

//=======================================Otto=======================================//
// strategy and component for rendering the otto span, basically the word currently being edited
// THIS WILL PROBABLY BE DEPRECATED ONCE WE ADD IN PLUGINS
export function ottoStrategy(contentBlock, callback, contentState) {
	const currentSelection = contentState.getSelectionAfter();
	if (!currentSelection.isCollapsed() || currentSelection.getStartKey() !== contentBlock.getKey()) {
		return;	// if the selection isn't a single location or not in this block then no otto
	}
	
	const charAfterIsSpace = currentSelection.getEndOffset() >= contentBlock.getText().length -1	// at the end of the block
	|| contentBlock.getText()[currentSelection.getEndOffset()+1] === ' ';	// next character is a space
	
	if (charAfterIsSpace) {
		// gets the index for the start of the word
		const beginOffset = backtrackWordOffset(contentBlock.getText(), currentSelection.getEndOffset());
		callback(beginOffset, currentSelection.getEndOffset()+1);	// calls callback with begin and ending of word being typed
	}
}
export const OttoSpan = (props) => (
	<span id={`otto-span-${props.blockKey}`} className={`dec-otto-${props.blockKey}`} >
		{props.children}
	</span>)

//=============================================================================//
//																Decorators																	 //
//=============================================================================//
// Decorators let us search for specific patterns and apply styles to them
//	- see https://draftjs.org/docs/advanced-topics-decorators

/*	NOTES
	- decorators are only re-run and checked for the block that changes in contentState
	- ^^ basically means decorators can only depend on inline triggers, not other blocks
*/


// const LINE_WITH_COLON_HYPHEN_REGEX = /^[^\.]*[:-].*/g;	// '^' we say anything but a period before colon cus we don't want mid-paragraph
// const COLON_HYPHEN_REGEX = /[:-]/g // simply to find the colon


// strategy and component for an equation to insert the result of the equation
const EQUATION_REGEX = /(\d+ *[+*-/] *\d+)( *[+*-/] *\d+)* *=/g;
function equationStrategy(contentBlock, callback, contentState) {
	const lineMatches = contentBlock.getText().search(EQUATION_REGEX);	// search for the equation
	if (lineMatches === -1) {	// if there is no colon in the text
		return;
	}

	const text = contentBlock.getText();
	const equationMatches = text.match(EQUATION_REGEX);
	equationMatches.forEach((stringMatch) => {
		const matchIndex = text.indexOf(stringMatch);
		callback(matchIndex, matchIndex+stringMatch.length);
	});
}
const EquationSpan = (props) => {

	const add = (a, b) => a+b;
	const sub = (a, b) => a-b;
	const mult = (a, b) => a*b;
	const divi = (a, b) => a/b;

	let text = props.decoratedText;

	text = text.replace(' ', '');	// remove spaces
	text = text.replace('*','_*_');
	text = text.replace('-','_-_');
	text = text.replace('/','_/_');
	text = text.replace('+','_+_');

	const splitBois = text.split('_');
	let sum = 0;
	let action = add;
	splitBois.forEach((num) => {
		if (action) {
			sum = action(sum, parseInt(num));
			action = null;
		} else {
			switch(num) {
				case '*':
					action = mult;
					break;
				case '-':
					action = sub;
					break;
				case '/':
					action = divi;
					break;
				case '+':
				default:
					action = add;
					break;
			}
		}
	});

	return (
		<span >
			{props.children}
			{!isNaN(sum) &&
				<b>
					{sum}
				</b>
			}
		</span>);
}

const wordRegex = /[^ ]\w+[ \.\n]/g
export function spellcheckStrategy(contentBlock, callback, contentState) {
	findInContentWithRegex(wordRegex, contentBlock, callback);
}
export const spellcheckSpan = (props) => {

	return (
		<span spellCheck >
			{props.children}
		</span>
	);
}


export function linkStrategy(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges((char) => {
		const entityKey = char.getEntity();
		return (entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'LINK');
	},
	callback
	);
}
export const linkSpan = (props) => {
	const { contentState, entityKey } = props;
	const { url } = contentState.getEntity(entityKey).getData();

	const clickLink = () => { window.open(url, '_blank') };

	return (
		<span onClick={clickLink} className="dec-link" >
			{props.children}
		</span>
	)
}


//=============================================================================//

// building the decorators
/* NOTES
		- order matters here, the closer to the top it is the higher priority, i.e. headerStrategy runs over subheaderStrategy
*/
				
const decorators = new CompositeDecorator([
	// {
	// 	strategy: linkStrategy,
	// 	component: linkSpan,
	// },
	// {
	// 	strategy: equationStrategy,
	// 	component: EquationSpan,
	// },
	{	// temporary, will probably be replaced by a plugin
		strategy: ottoStrategy,
		component: OttoSpan,
	},
	// {
	// 	strategy: spellcheckStrategy,
	// 	component: spellcheckSpan,
	// },
]);

export default decorators;