import {
	WORD_MANAGER_ENGLISH_DICTIONARY_RESOURCE,
	onLoadResourceCallback as wordManagerOnLoad,
} from '../utils/wordManager';



import {
	KATEX_PRESETS_RESOURCE,
	onLoadResourceCallback as katexOnLoad,
} from '../constants/katexPresets'

/* These are the resources to be loaded into firebase on app start, with the following format

	[RESOURCE_KEY]: { filePath: <string>, onLoad: <function> }

		- filePath is the storage filePath, like firebase.storage.ref(filePath)
		- onLoad is the onLoad callback, it will be passed (resoureceKey, rawBlob) on load

	*/
const RESOURCES = {
	[WORD_MANAGER_ENGLISH_DICTIONARY_RESOURCE.key]: {
		filePath: WORD_MANAGER_ENGLISH_DICTIONARY_RESOURCE.filePath,
		onLoad: wordManagerOnLoad,
	},
	// [OTTO_SPANISH_DICTIONARY_RESOURCE.key]: {
	// 	filePath: OTTO_SPANISH_DICTIONARY_RESOURCE.filePath,
	// 	onLoad: ottoOnLoad,
	// },
	[KATEX_PRESETS_RESOURCE.key]: {
		filePath: KATEX_PRESETS_RESOURCE.filePath,
		onLoad: katexOnLoad,
	},
};

export default RESOURCES;