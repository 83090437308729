import React, { useCallback, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';

import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';
import firebase from '../../../Firebase';

import logger from '../../../utils/logger'
import { isFileImage } from '../../../utils/utils'

/*Class Prefix = ec*/
import './styles.css';
import { useSelector } from 'react-redux';
import { getActiveTab } from '../../../data/tabs/selectors';

import { uploadImage } from '../utils';

const Image = function(props) {
	const { id, onChange, inFlashcards } = props;
	
	const [loadedImage, setLoadedImage] = useState();
	const [loadingImage, setLoadingImage] = useState(false);
	const [errorLoading, setErrorLoading] = useState(false);

	const activeTab = useSelector(getActiveTab);
	const notebookId = activeTab?.fileId

	const onDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];
		if (!isFileImage(file)) {
			return;	// don't accept non image files
		}
		setLoadingImage(true);
		uploadImage({
			file,
			fileId: notebookId,
			onSuccess: ({ id, name, url }) => {
				onChange({ id, name });
				setLoadedImage(url);
				setLoadingImage(false);
			},
			onFail: (e) => {
				setErrorLoading(e);
				setLoadingImage(false);
			}
		});
	}, [onChange, notebookId]);

	const loadImage = (idToLoad) => {
		if (!idToLoad || loadingImage || errorLoading) return;

		setLoadingImage(true);
		firebase.getImageAsDownloadURL(idToLoad).then((URL) => {
			logger.log("Loaded Image: ", URL)
			setLoadedImage(URL);
			setLoadingImage(false);
		}).catch((err) => {
			logger.log("Error loading image: ", err);
			Bugsnag.notify(err);
			setErrorLoading('error loading image');
			setLoadingImage(false);
		})
	}

	useEffect(() => {
		loadImage(id)
	}, [id]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	if (errorLoading) {
		return (<div>error loading...</div>)
	}
	if (loadingImage) {
		return <CircularProgress />
	}

	return (
		<>
			{id || loadedImage ?
				<img alt={`user-uploaded-image}`} className={inFlashcards ? 'ec-image-flashcards' : 'ec-image'} src={loadedImage} />
				:
				<div {...getRootProps()}>
					<input {...getInputProps()} accept="image/png, image/jpg" />
					<div className="ec-image-upload-container" >
						{isDragActive ?
							<p>Drop the files here ...</p>
							:
							<div className="flex-column-center" style={{ textAlign: 'center' }}>
								<h1>+</h1>
								<p>drag and drop or click to upload images</p>
							</div>
						}
					</div>
				</div>
			}
		</>
	);
}

export default Image;