import { SIGN_OUT } from '../profile/actions';
import * as actions from './actions';

// going to be a list of objects like { key: <one of ./keys.js>, id: <unique id for dialog removal>, props: <supplied to the dialog component> }

// first let's define the initialState for instantiation of the store
const initialState = { dialogList: [], };

const dialogReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
  switch (action.type) {
    case actions.SHOW_DIALOG: {
			const newDialogs = state.dialogList.slice();
			newDialogs.push(action.payload);
      return { ...state, dialogList: newDialogs };
    }
    case actions.REMOVE_DIALOG: {
      const newDialogs = state.dialogList.filter((dialog) => dialog.id !== action.payload);
      return { ...state, dialogList: newDialogs };
    }
    case SIGN_OUT: {
      return { ...initialState };
    }
    default:
      return state; // the action doesn't affect the dialogs store so nothing chaning
  }
}

export default dialogReducer;