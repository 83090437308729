

export const getTabsList = (store) => store.tabs.tabsList;
export const getActiveTabIndex = (store) => store.tabs.activeTabIndex;
export const getTabsIsLoading = (store) => store.tabs.isLoading;

export const getActiveTab = (store) => {
  const tabsList = getTabsList(store);
  const activeTabIndex = getActiveTabIndex(store);
  return activeTabIndex < tabsList.length ? tabsList[activeTabIndex] : {};
}

export const getShowNewTabView = (store) => store.tabs.showNewTabView;