export const LANDING = '/landing';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_UPDATE = '/pw-update';
export const EMAIL_UPDATE = '/email-update';
export const HOME = '/home';
export const PUBLIC = '/public';
export const PUBLISHED = '/published';
// export const NOTEBOOKS = '/notebooks';
// export const COURSE = '/course';
export const PRIVACY_POLICY = '/privacy-policy';
// export const ACCOUNT = '/account';
export const PURCHASE_SUCCESSFUL = '/purchase-successful';
export const GET_SHARED = '/get-shared';
export const OPEN_CONVERSIONS = '/note-conversion';