import React, { useRef, useEffect } from 'react';
import katex from 'katex';

// just a commponent to make a button that has latex rendering on it
const KatexButton = (props) => {
	const { texLabel, onSelect, value } = props;

	const katexRef = useRef(null);

	const update = () => {
		katex.render(texLabel, katexRef.current, { throwOnError: false });
	}

	useEffect(() => {
		update();
	}, [texLabel])

	const handleClick = (e) => {
		e.preventDefault();
		onSelect(value);
	}

	return (
		<button onMouseDown={handleClick} className="ee-katex-button" >
			<div ref={katexRef} />
		</button>);
}

export default KatexButton;