import React, { useState, useEffect, useMemo } from 'react';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import Image from '../../../../components/Editor/components/Image';

/*Class Prefix = study-card*/
import './styles.css';

const useStyles = makeStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const StudyCard = (props) => {
	// sideData is like { contentType, content }
	const { sideData, onChange} = props;

	const classes = useStyles();

	// callback for text change on the front input
	const handleChange = (event) => {
		// const newFront = Object.assign({}, localFront, { content: event.target.value })
		// setLocalFront(newFront);
		// onChange(cardIndex, Object.assign({}, { front: newFront, localBack }));
	}

	const innerRender = useMemo(() => {
		if (!sideData?.content) {
			return null;
		}
		switch(sideData.contentType) {
			case 'image':
				return (
					<Image
						id={sideData.content}
						onChange={() => true}
						inFlashcards
					/>
				);
			case 'text':
				return (
					<Typography
						component="div"
						variant="h5"
					>
						{sideData.content.split('\n').map((line, index) => (
							<React.Fragment key={`study-card-line-${index}`} >
								<span>{line}</span>
								<br />
							</React.Fragment>
						))}
					</Typography>
					);
			default:
				return <CircularProgress />
		}
	}, [sideData])


	return (
		<Box maxWidth="100%" maxHeight="100%" height="auto" width="auto" display="flex">
			<Typography
				component="div"
				variant="h5"
				className={classes.card}
			>
				{innerRender}
			</Typography>
		</Box>
	);
}

export default StudyCard;