import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: '50%',
		width: 40,
		height: 40,
		border: '1px solid #555',
		cursor: 'pointer',
	},
	popperContent: {
		background: theme.palette.background.default,
		padding: 4,
		borderRadius: 4,
		width: 180,
		border: `1px solid #333`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	color: {
		width: 30,
		height: 30,
		borderRadius: 4,
		border: `1px solid ${theme.palette.background.default}`,
		margin: 4,
		cursor: 'pointer',
		transition: '200ms',
		'&:hover': {
			borderColor: theme.palette.secondary.dark,
		},
	},
}));
/**
 * ORIGINAL
 * Warm Yellow / #f6cd8b
 * Soft pink / #e5ae86
 * Mint green / #c7c397
 * Forest Green / #9d9754
 * Clay Red / #ff7251
 * Lavender Purple / #976393
 * Aqua Blue / #b6f5f6
 * Pool Blue / #45a4b8
 * 
 * NEW
 * #A2BE53 Faux Green
 * #9fc5e8 Bbblue
 * #FDE598 Aging Lemon
 * #FD7D7D Raspbery
 * #fce5cd
 * #8BCA6F
 * #BE6F99
 * #53BEA5
*/
const COLORS = ['#e5ae86', '#9fc5e8', '#FDE598', '#FD7D7D', '#fce5cd', '#8BCA6F', '#BE6F99', '#53BEA5'];

const CourseColorPicker = function(props) {
	const { onChange, selected, onOpen, onClose } = props;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleSelect = (c) => (_e) => {
		setAnchorEl(null);
		onChange(c)
		onClose();
	};

	const classes = useStyles();

	return (
		<div style={{ marginLeft: 16 }}>
			<button
				aria-describedby={'course-color-picker'}
				type="button"
				onClick={(e) => {
					setAnchorEl(anchorEl ? null : e.currentTarget)
					if (anchorEl) {
						onClose();
					} else {
						onOpen()
					}
				}}
				className={classes.button}
				style={{ backgroundColor: selected || '#fff' }}
			>
			</button>
			<Popper style={{ marginLeft: 4, zIndex: 1500 }} placement='right' id={'course-color-picker'} open={Boolean(anchorEl)} anchorEl={anchorEl}>
				<Box className={classes.popperContent}>
					{COLORS.map((c) => (
						<button
							key={`course-color-${c}`}
							onClick={handleSelect(c)}
							style={{ backgroundColor: c }}
							className={classes.color}
						/>))}
				</Box>
			</Popper>
		</div>
	);
}

export default CourseColorPicker;