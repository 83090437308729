import React, { useEffect, useRef } from 'react';
import { DefaultDraftBlockRenderMap } from 'draft-js';
import './blockRenderStyles.css';
import { useMeasureFixed } from '../../Firebase/hooks';
import { LINED_PAPER_HEIGHT } from '../../constants/muiThemes';
const { Map } = require('immutable');

const DashedList = (props) => {
	return <ul className="brs-dashed-list" >{props.children}</ul>;
}

const TextAlignLeft = (props) => {
	return <div className="brs-text-align-left" >{props.children}</div>;
}

const TextAlignCenter = (props) => {
	return <div className="brs-text-align-center" >{props.children}</div>;
}

const TextAlignRight = (props) => {
	return <div className="brs-text-align-right" >{props.children}</div>;
}

const AtomicContainer = (props) => {
	const containerRef = useRef(null);
	const [sizeRef, { height }] = useMeasureFixed();

	useEffect(() => {
		const containerHeight = containerRef.current?.clientHeight;
		const newHeight = height - (height % LINED_PAPER_HEIGHT) + LINED_PAPER_HEIGHT;
		if (
			height % LINED_PAPER_HEIGHT !== 0
			&& containerHeight
			&& containerHeight !== newHeight
			) {
				containerRef.current.style.height = `${newHeight}px`;
			}
	}, [height]);

	return (
		<div className='brs-atomic-container' ref={containerRef}>
			<div ref={sizeRef}>
				{props.children}
			</div>
		</div>
	);
}

// This let's us render specific blocks as specific elements
//	- see https://draftjs.org/docs/advanced-topics-custom-block-render-map
const blockRenderMap = Map({
	atomic: {
		element: 'div',
		wrapper: <AtomicContainer />,
	},
	'unordered-list-item-hyphen': {
		element: 'li',
		wrapper: <DashedList />
	},
	'text-align-left': {
		element: 'div',
		wrapper: <TextAlignLeft />,
	},
	'text-align-center': {
		element: 'div',
		wrapper: <TextAlignCenter />,
	},
	'text-align-right': {
		element: 'div',
		wrapper: <TextAlignRight />,
	},
	unstyled: {
		element: 'span',
		aliasedElements: [ 'a', 'div', 'span'],
	},
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

export default extendedBlockRenderMap;