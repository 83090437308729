import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Bugsnag from "@bugsnag/js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import ArrowRightIcon from "@material-ui/icons/ChevronRight";
import NotebookIcon from "@material-ui/icons/DescriptionOutlined";
import FlashcardIcon from "@material-ui/icons/FilterNone";

import { removeDialog, showDialog } from "../../data/dialogs/actions";
import { getCoursesById } from "../../data/courses/selectors";
import Spinner from "../../components/Spinner";
import CourseColorPicker from "../../components/CourseColorPicker";

import firebase from "../../Firebase";
import { addFlashcards } from "../../data/flashcards/actions";
import { addCourse } from "../../data/courses/actions";
import { addNotebook } from "../../data/notebooks/actions";
import { addTab } from "../../data/tabs/actions";
import * as TAB_KEYS from "../../data/tabs/keys";
import { PREMIUM_PLANS_DIALOG_KEY } from "../../data/dialogs/keys";
import { getUserLimitReached } from "../../data/profile/selectors";

import logger from "../../utils/logger";
import Mixpanel, { EVENTS } from "../../utils/mixpanel";

import Transition from "../SlideTransition";
import { ChevronLeft } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((_theme) => ({
  listItem: {
    borderRadius: 8,
    minWidth: 300,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    minWidth: 250,
    padding: `0 24px`,
  },
  input: {
    margin: `8px 0`,
    minWidth: 250,
  },
  folderColor: {
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: "50%",
    border: "1px solid #666",
    overflow: "hidden",
    margin: "0 0 0 16px",
  },
}));

const AddFileDialog = function (props) {
  const { initialFolder, initialFileType, onClose } = props;

  const dispatch = useDispatch();

  const coursesById = useSelector(getCoursesById);
  const userLimitsReaced = useSelector(getUserLimitReached);

  const [tabIndex, setTabIndex] = useState(initialFileType ? 1 : 0);
  const [fileType, setFileType] = useState(initialFileType || null);
  const [fileName, setFileName] = useState("");
  const [addToFolder, setAddToFolder] = useState(initialFolder || "");
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderColor, setNewFolderColor] = useState("");
  const [awaitingFileCreation, setAwaitingFileCreation] = useState(false);
  const [lockOpen, setLockOpen] = useState(false);

  const classes = useStyles({ newFolderColor });
  const newFolderNameIsRepeat = Object.values(coursesById).find(
    (item) => item.name === newFolderName
  );
  const addToFolderFolder = Object.values(coursesById).find(
    (c) => c.id === addToFolder
  );

  const handleClose = (e, reason) => {
    if (lockOpen && reason === "backdropClick") return;
    props.dialogId && dispatch(removeDialog(props.dialogId));
    onClose?.();
  };

  const handleOpenColor = () => {
    setLockOpen(true);
  };
  const handleCloseColor = () => {
    setTimeout(() => setLockOpen(false), 250);
  }; // not great but works for now

  const handleSelectFileType = (newType) => () => {
    Mixpanel.track(EVENTS.addFileSelectType, { type: newType });
    setFileType(newType);
    setTabIndex(1);
  };

  const handleAddToFolderChange = (e) => {
    setAddToFolder(e.target.value);
    setNewFolderName("");
    setNewFolderColor("");
  };

  const handleNewFolderNameChange = (e) => {
    setAddToFolder("");
    setNewFolderName(e.target.value);
  };

  const handleBack = () => {
    setTabIndex(0);
    setFileName("");
    setNewFolderName("");
    setNewFolderColor("");
    setFileType(null);
  };

  const handleCreateFile = async (_e) => {
    Mixpanel.track(EVENTS.addFileSubmit, {
      isCreatingCourse: Boolean(newFolderName),
      fileType,
    });
    setAwaitingFileCreation(true);

    let folderId;
    if (newFolderName) {
      // handle folder creation if necessary
      try {
        folderId = await firebase
          .saveNewCourse(newFolderName, newFolderColor)
          .then((course) => {
            Mixpanel.track(EVENTS.createCourse, { color: newFolderColor });
            dispatch(addCourse(course));
            logger.log("Saved new course to firestore");
            return Promise.resolve(course.id);
          });
      } catch (err) {
        Bugsnag.notify(err);
        logger.error("ERROR saving new course: ", err);
      }
    } else {
      folderId = addToFolder;
    }

    if (!folderId) {
      handleClose();
      return; // abort if no folder
    }

    const firebaseFunc =
      fileType === "flashcards"
        ? firebase.addFlashcardDeckToCourse
        : firebase.addNotebookToCourse;
    return await firebaseFunc(folderId, fileName).then((resp) => {
      const correctAction =
        fileType === "flashcards" ? addFlashcards : addNotebook;
      dispatch(correctAction(folderId, resp.id, fileName));
      logger.log(`Added new ${fileType} to firestore`);
      const tabKey =
        fileType === "flashcards"
          ? TAB_KEYS.FLASHCARDS_TAB_KEY
          : TAB_KEYS.NOTEBOOK_TAB_KEY;
      dispatch(addTab(tabKey, fileName, folderId, resp.id));
      handleClose();
    });
  };

  return (
    <Dialog open onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          {fileType && (
            <IconButton onClick={handleBack} size="small">
              <ChevronLeft />
            </IconButton>
          )}
          <Typography>Add {fileType ? fileType : "file"}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {tabIndex === -1 && (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )}

        {tabIndex === 0 && (
          <List>
            <ListItem
              className={classes.listItem}
              button
              onClick={handleSelectFileType("notebook")}
            >
              <ListItemAvatar>
                <Avatar>
                  <NotebookIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Notebook"
                secondary="enhanced notes with automatic formatting"
              />
              <ArrowRightIcon fontSize="large" />
            </ListItem>
            <ListItem
              className={classes.listItem}
              button
              onClick={handleSelectFileType("flashcards")}
            >
              <ListItemAvatar>
                <Avatar>
                  <FlashcardIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Flashcards"
                secondary="use existing notes or create a new deck"
              />
              <ArrowRightIcon fontSize="large" />
            </ListItem>
          </List>
        )}

        {tabIndex === 1 && (
          <form className={classes.form} noValidate autoComplete="off">
            <Typography variant="body1" style={{ marginBottom: 8 }}>
              Name {fileType}
            </Typography>
            <TextField
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              label={`New ${fileType} name`}
              className={classes.input}
              variant="outlined"
            />
            <br />
            <Typography variant="body1" style={{ marginBottom: 8 }}>
              {_.isEmpty(coursesById) ? "Name Folder" : "Choose folder"}
            </Typography>
            <Box display="flex" alignItems="center" flexDirection="column">
              {!_.isEmpty(coursesById) && (
                <>
                  <Box display="flex" alignItems="center" width="100%">
                    <TextField
                      variant="outlined"
                      label="Add to existing folder"
                      value={addToFolder}
                      select
                      className={classes.input}
                      onChange={handleAddToFolderChange}
                      disabled={_.isEmpty(coursesById)}
                      fullWidth
                    >
                      {Object.entries(coursesById).map(([courseId, course]) => (
                        <MenuItem
                          key={`folder-select-${courseId}`}
                          value={courseId}
                        >
                          {course.name}
                        </MenuItem>
                      ))}
                      {false && ( // for now
                        <MenuItem value={"NONE"}>-- NONE --</MenuItem>
                      )}
                    </TextField>
                    <Tooltip
                      disableHoverListener={!addToFolderFolder}
                      title={`${addToFolderFolder?.name} color`}
                      arrow
                    >
                      <div
                        style={{
                          backgroundColor: addToFolderFolder?.color || "#FFF",
                        }}
                        className={classes.folderColor}
                      />
                    </Tooltip>
                  </Box>
                  <Typography variant="caption">or</Typography>
                </>
              )}
              <Box display="flex" alignItems="center">
                <TextField
                  variant="outlined"
                  value={newFolderName}
                  onChange={handleNewFolderNameChange}
                  label="Create new folder"
                  className={classes.input}
                  error={
                    Boolean(newFolderNameIsRepeat) && !awaitingFileCreation
                  }
                  helperText={
                    newFolderNameIsRepeat && !awaitingFileCreation
                      ? "You already have a folder with that name"
                      : null
                  }
                  fullWidth
                />
                <Tooltip
                  open={newFolderName && !newFolderColor ? true : false}
                  arrow
                  title={`select a color for ${newFolderName}`}
                  placement="top"
                >
                  <div>
                    <CourseColorPicker
                      onOpen={handleOpenColor}
                      onClose={handleCloseColor}
                      onChange={setNewFolderColor}
                      selected={newFolderColor}
                    />
                  </div>
                </Tooltip>
              </Box>
            </Box>
          </form>
        )}
      </DialogContent>
      {tabIndex === 1 && (
        <DialogActions>
          {(fileType === "notebook" && userLimitsReaced.notebooks) ||
          (fileType === "flashcards" && userLimitsReaced.flaschards) ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding={2}
            >
              <div>max limit of files reached</div>
              {/* <Typography>
								You must upgrade to pro to add more {fileType}{fileType === 'notebook' ? 's' : ''}
							</Typography>
							<Button
								onClick={() => dispatch(showDialog('premium-from-limit-stopper', PREMIUM_PLANS_DIALOG_KEY))}
								variant="contained"
								color="secondary"
							>
								get pro
							</Button> */}
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={awaitingFileCreation}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateFile}
                endIcon={
                  awaitingFileCreation ? <CircularProgress size={20} /> : null
                }
                disabled={
                  !fileType ||
                  fileName.length === 0 ||
                  (!addToFolder && newFolderName.length === 0) ||
                  awaitingFileCreation ||
                  newFolderNameIsRepeat ||
                  Boolean(newFolderName && !newFolderColor)
                }
              >
                {awaitingFileCreation ? "creating" : "create"}
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AddFileDialog;
