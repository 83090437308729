import * as actions from './actions';

// we are going to store various environment changes here
/*
	environment: {
		hasConnection; <bool>,
	}
*/

// first let's define the initialState for instantiation of the store
const initialState = {
	hasConnection: true,
}


// keep in mind state is the object 'environment' points to in the above comment
const environmentReducer = function(state=initialState, action) { // default to initialState, action is usally { type: <string>, payload: <object> }
	switch (action.type) {
		case actions.CHANGE_CONNECTION_STATUS:
			return { ...state, hasConnection: action.payload };  // destructure the previous/initial state and overwrite changing values
		default:
			return state; // the action doesn't affect the environment store so nothing chaning
	}
}

export default environmentReducer;