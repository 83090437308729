import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Bugsnag from '@bugsnag/js';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Transition from '../SlideTransition';
import { removeDialog } from '../../data/dialogs/actions';
import { shareFile } from '../../utils/shareFiles';

import logger from '../../utils/logger';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';

const ShareFileDialog = function(props) {	// doesn't remove itself
	const { fileName, dialogId, courseId, fileId, fileType } = props;

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
  const [recipientEmail, setRecipientEmail] = useState('');
	const [error, setError] = useState(false);
	
	const handleClose = () => {
		dispatch(removeDialog(dialogId));
	}

	const handleSubmit = () => {
		setLoading(true);
		Mixpanel.track(EVENTS.shareFile)
		shareFile(courseId, fileType, fileId, recipientEmail).then((resp) => {
			setLoading(false);
			handleClose();
		}).catch((error) => {
			Bugsnag.notify(error);
			logger.error('failed to share file', error);
			setError(true);
		});
	}

	const invalidEmail = !recipientEmail.includes('@') ||!recipientEmail.includes('.');

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<DialogTitle>
				Share a copy of <b>{fileName}</b> to:
			</DialogTitle>
			<DialogContent>
				<Box display="flex" flexDirection="column" alignItems={loading ? 'center' : 'flex-start'}>
					{
						loading ?
						<CircularProgress />
						:
						<>
							<TextField
								label="yourfriend@email.com"
								value={recipientEmail}
								onChange={(e) => setRecipientEmail(e.target.value)}
								error={error}
								helperText={error ? 'Sorry, we were unable to share your file, please try again later' : null}
							/>
							<Typography variant="caption">Note: this will not share images in the notebook</Typography>
						</>
					}
				</Box>
			</DialogContent>
			{!loading &&
				<DialogActions>
					<Button
						variant="outlined"
						onClick={handleClose}
						disabled={loading}
					>
						cancel
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={handleSubmit}
						disabled={loading || invalidEmail}
					>
						Share
					</Button>
				</DialogActions>
			}
		</Dialog>
	);
}

export default ShareFileDialog;