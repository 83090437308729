import React, { useState, useRef, useEffect } from 'react';
import { useCounter, useToggle } from 'react-use';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
	Button,
	Typography,
	Box,
	DialogContent,
	Dialog,
	Tooltip,
	IconButton,
	DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Keyboard from '@material-ui/icons/Keyboard';

import StudyCard from '../../containers/Flashcards/components/StudyCard';
import { removeDialog } from '../../data/dialogs/actions';

import Transition from '../SlideTransition';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	card: {
		border: '2px solid',
		padding: 16,
		borderColor: theme.palette.primary.main,
		borderRadius: 4,
		width: 500,
		height: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',

		position: 'relative',
		background: '#fff',
		transformStyle: 'preserve-3d',
		perspective: '600px',
		transition: '150ms',
		backfaceVisibility: 'hidden',
	},
	cardFlipped: {
		transform: 'rotateX(360deg)',
	},
	kbd: {
		backgroundColor: '#EEE',
		borderRadius: 4,
		border: `1px solid #b4b4b4`,
		color: theme.palette.text.primary,
		display: 'inline-block',
		padding: '2px 4px',
		whiteSpace: 'nowrap',
		boxShadow: '0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset',
		fontSize: '0.85em',
	},
}));

const getMessage = (numPassed, numCorrect, numWrong) => {
	const total = numPassed + numCorrect + numWrong;
	if (numWrong / total > 0.4) {
		return "Keep studying!";
	} else if ((numWrong + numPassed) / total > 0.2) {
		return "Wow, getting smarter!";
	}
	return "Nice Job!";
}

const StudyDeckDialog = function(props) {
	const { onClose, deck, context } = props;

	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();

	const [studyDeck, setStudyDeck] = useState(deck);
	const [activeIndex, { inc, dec, reset }] = useCounter(0, studyDeck.length, 0);
	const [numPassedCards, setNumPassedCards] = useState(0);
	const [numCorrectCards, setNumCorrectCards] = useState(0);
	const [numWrongCards, setNumWrongCards] = useState(0);
	const [flipped, toggleFlip] = useToggle(false);
	const cardMap = useRef({});
	const lockActions = studyDeck?.length === numPassedCards + numCorrectCards + numWrongCards;

	const activeCard = studyDeck?.[activeIndex] || {};
	const activeSide = activeCard[flipped ? 'back' : 'front']

	const clearNumCards = () => {
		setNumWrongCards(0);
		setNumCorrectCards(0);
		setNumPassedCards(0);
	}

	const handleClose = (e, reason) => {
		props.dialogId && dispatch(removeDialog(props.dialogId));
		onClose?.();
	}

	const handleRestart = () => {
		cardMap.current = {};
		clearNumCards();
		toggleFlip(false);
		reset();
		Mixpanel.track(EVENTS.restartStudy, { context });
	}

	const handleReset = () => {
		cardMap.current = {};
		clearNumCards();
		reset();
		setStudyDeck(deck)
	}

	const handlePass = () => {
		if (lockActions) return;
		Mixpanel.track(EVENTS.studyFlashcard, { action: 'pass', context });
		cardMap.current[activeIndex] = 'PASSED';
		setNumPassedCards(numPassedCards + 1);
		incWrap();
	}
	
	const handleCorrect = () => {
		if (lockActions) return;
		Mixpanel.track(EVENTS.studyFlashcard, { action: 'correct', context });
		cardMap.current[activeIndex] = 'CORRECT';
		setNumCorrectCards(numCorrectCards + 1);
		incWrap();
	}

	const handleWrong = () => {
		if (lockActions) return;
		Mixpanel.track(EVENTS.studyFlashcard, { action: 'wrong', context });
		cardMap.current[activeIndex] = 'WRONG';
		setNumWrongCards(numWrongCards + 1);
		incWrap();
	}

	const handleStudyMissed = () => {
		const nextStudyDeck = [];
		Object.entries(cardMap.current)
		.filter(([_index, action]) => action === 'WRONG')
		.forEach(([index]) => {
			nextStudyDeck.push(studyDeck[index]);
		});
		clearNumCards();
		cardMap.current = {};
		reset();
		setStudyDeck(nextStudyDeck);
	}

	const handleBack = () => {
		if (activeIndex === 0)	return;
		const originalAction = cardMap?.current[activeIndex-1];
		delete cardMap?.current[activeIndex-1];
		switch (originalAction) {
			case 'PASSED': setNumPassedCards(numPassedCards-1); break;
			case 'CORRECT': setNumCorrectCards(numCorrectCards-1); break;
			case 'WRONG': setNumWrongCards(numWrongCards-1); break;
			default: break;
		}
		dec();
	}

	const incWrap = () => {
		toggleFlip(false);
		inc();
	}

	const handleKeyDown = (e) => {
		if (lockActions) return;
		if (e.key === 'Enter') {
			handleCorrect();
		} else if (e.key === 'Shift') {
			handleWrong();
		} else if (e.keyCode === 32) {
			toggleFlip();
		} else if (e.keyCode === 37) {
			handleBack();
		} else if (e.keyCode === 39) {
			handlePass();
		} else if (e.keyCode === 38 || e.keyCode === 40) {
			toggleFlip()
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [handleKeyDown])

	return (
		<Dialog
			open
			onClose={handleClose}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>
				<Box display="flex" alignItems="center">
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					>
					{
						activeIndex < studyDeck.length ?
							<Box display="flex" alignItems="center">
								<IconButton
									disabled={activeIndex === 0}
									onClick={handleBack}
								>
									<KeyboardArrowLeft />
								</IconButton>
								<Box
									onClick={toggleFlip}
									className={`${classes.card} ${flipped ? classes.cardFlipped : ''}`}
								>
									<StudyCard
										sideData={activeSide}
									/>
								</Box>
								<IconButton
									disabled={activeIndex === studyDeck.length}
									onClick={handlePass}
								>
									<KeyboardArrowRight />
								</IconButton>
							</Box>
							:
							<Box
								className={classes.card}
								display="flex"
								flexDirection="column"
							>
								<Typography variant="h5">
									{getMessage(numPassedCards, numCorrectCards, numWrongCards)}
								</Typography>
								<Box minWidth={120} marginTop={2}>
									<Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
										<Typography># Correct</Typography>
										<Typography>{numCorrectCards}</Typography>
									</Box>
									<Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
										<Typography># Skipped</Typography>
										<Typography>{numPassedCards}</Typography>
									</Box>
									<Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
										<Typography># Wrong</Typography>
										<Typography>{numWrongCards}</Typography>
									</Box>
								</Box>
								<Box mt={4} width="100%" display="flex" alignItems="center" justifyContent="center">
										<Button onClick={handleRestart} color="secondary" variant="contained">
											Restart
										</Button>
								</Box>
								{numWrongCards > 0 &&
									<Box mt={2} width="100%" display="flex" alignItems="center" justifyContent="center">
										<Button onClick={handleStudyMissed} color="secondary" variant="contained">
											study the ones you missed
										</Button>
									</Box>
								}
								<Box mt={2} width="100%" display="flex" alignItems="center" justifyContent="center">
									<Button onClick={handleReset} color="secondary">
										reset
									</Button>
								</Box>
							</Box>
					}
					<Box paddingTop={2} display="flex" alignItems="center" justifyContent="space-between" width="60%">
						<Box display="flex" alignItems="center">
							<Box display="flex" width={300} height={16} marginRight={2} overflow="hidden" borderRadius={4}>
								<div style={{ height: '100%', width: Math.ceil(numPassedCards / studyDeck.length * 300), background: theme.palette.primary.light }} />
								<div style={{ height: '100%', width: Math.ceil(numCorrectCards / studyDeck.length * 300), background: '#50C878' }} />
								<div style={{ height: '100%', width: Math.ceil(numWrongCards / studyDeck.length * 300), background: '#FF5E51' }} />
								<div
									style={{
										height: '100%',
										width: Math.ceil((studyDeck.length-numPassedCards-numCorrectCards-numWrongCards) / studyDeck.length * 300),
										background: '#CCCCCC'
									}}
								/>
							</Box>
							<Typography variant="body1">
								{numPassedCards + numCorrectCards + numWrongCards}/{studyDeck.length}
							</Typography>
						</Box>
						<Tooltip
							enterDelay={0}
							enterNextDelay={0}
							title={
								<Box minWidth={180} padding={1}>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Box display="flex">
											<KeyboardArrowLeft />
											<KeyboardArrowRight />
										</Box>
										<Typography color="inherit">
											last/next
										</Typography>
									</Box>
									<Box marginTop={1} display="flex" alignItems="center" justifyContent="space-between">
										<kbd className={classes.kbd}>Enter</kbd>
										<Typography color="inherit">
											got it
										</Typography>
									</Box>
									<Box marginTop={1} display="flex" alignItems="center" justifyContent="space-between">
										<kbd className={classes.kbd}>Shift</kbd>
										<Typography color="inherit">
											study again
										</Typography>
									</Box>
									<Box marginTop={1} display="flex" alignItems="center" justifyContent="space-between">
										<kbd className={classes.kbd}>Space</kbd>
										<Typography color="inherit">
											flip card
										</Typography>
									</Box>
								</Box>
							}
						>
							<IconButton>
								<Keyboard />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default StudyDeckDialog;