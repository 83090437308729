

const BASIC_PLAN = Object.freeze({
	name: 'Basic',
	notebooks: 8,
	flashcards: 8,
	storage: 100000000,
	cost: 'Free',
});

const PRO_PLAN = Object.freeze({
	name: 'Pro',
	notebooks: Number.POSITIVE_INFINITY,
	flashcards: Number.POSITIVE_INFINITY,
	storage: 1000000000,
	cost: '$2.99/mo'
});

export const Plans = {
	BASIC: BASIC_PLAN,
	PRO: PRO_PLAN,
}