import React, { useState, useEffect, Fragment, useMemo, useCallback, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	BrowserRouter as Router,
	Route,
	Switch,
} from 'react-router-dom';
import { useEffectOnce, useLocation, useSearchParam } from 'react-use';
import Bugsnag from '@bugsnag/js';

import { ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* We need to put the prefix as the start of each class name to avoid overriding classes
*		- see usage in styles.css but it's like .app-root, or if the prefix was temp you'd name the class temp-root
*		- must be unique, usually just use the name of the component or abbreviation of some sort
*		- put this comment here and in the styles.css file for consistency
*/
/*Class Prefix = app*/
import './styles.css';

import Navigation from '../Navigation';
// import EmailUpdatePage from '../EmailUpdate';
import ControlBox from '../ControlBox';
import RedirectPage from '../RedirectPage';
import Spinner from '../../components/Spinner';

import * as ROUTES from '../../constants/routes';

import { getMUIThemeFromName } from '../../constants/muiThemes';
import '../../constants/theme.css';

import logger from '../../utils/logger';
import { getProfile } from '../../data/profile/selectors';

import firebase from '../../Firebase';

import { fetchingProfile, fetchProfileSuccess, fetchProfileFail, createProfileSuccess } from '../../data/profile/actions';
import { fetchCoursesSuccess, fetchCoursesFail, fetchingCourses } from '../../data/courses/actions';
import { initializeFlashcards } from '../../data/flashcards/actions';
import { initializeNotebooks } from '../../data/notebooks/actions';
import { initializeMetaData } from '../../data/metaData/actions';
import { changeConnectionStatus } from '../../data/environment/actions';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';
import { DEPLOYMENT_VERSION_NUMBER } from '../../Firebase/firebase';
import { initializeTabs } from '../../data/tabs/actions';
import { getTabsIsLoading } from '../../data/tabs/selectors';
import CookiesBar from '../../components/CookiesBar';
import { componentLoader } from '../../utils/utils';

// Lazy imports
// const NotebookPage = lazy(() => import('../Notebook/index'));
// const FlashcardPage = lazy(() => import('../Flashcards/index'));
const PrivacyPolicy = lazy(() => componentLoader(() => import('../../components/PrivacyPolicy'), 2));
const SignUpPage = lazy(() => componentLoader(() => import('../SignUp'), 2));
const SignInPage = lazy(() => componentLoader(() => import('../SignIn'), 2));
const PasswordForgetPage = lazy(() => componentLoader(() => import('../PasswordForget'), 2));
// const AccountPage = lazy(() => import('../Account'));
// const CoursePage = lazy(() => import('../Course'));
// const HomePage = lazy(() => import('../Home'));
const LandingPage = lazy(() => componentLoader(() => import('../Landing'), 2));
const TabsPage = lazy(() => componentLoader(() => import('../Tabs'), 2));
const DialogsRoot = lazy(() => componentLoader(() => import('../../dialogs'), 2));
const PublicPage = lazy(() => componentLoader(() => import('../Public'), 2));
const PurchaseSuccessfulPage = lazy(() => componentLoader(() => import('../PurchaseSuccesful'), 2));
const GetSharedPage = lazy(() => componentLoader(() => import('../GetShared'), 2));
const OpenConversionsPage = lazy(() => componentLoader(() => import('../OpenConversions')), 2);

const App = function(props) {

	/* We only use this hook here on the top level, it allows us to update components when
	*		firebase does an action that changes userData, it gets injected via the 
	*		UserDataContext.Provider below. So when userData changes it updates the context
	*		and that update is recognized in components using the UserDataContext
	*/

	const dispatch = useDispatch();
	const profile = useSelector(getProfile);
	const tabsIsLoading = useSelector(getTabsIsLoading)
	const location = useLocation();

	// just let's us manage the initialization of the authUser
	const [authInitialized, setAuthInitialized] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState(null);
	const source = useSearchParam('source');

	const handleTrackAdwordsConversion = useCallback(() => {
		if (window.gtag_report_conversion) {
			window.gtag_report_conversion();
		}
	}, []);

	// called when firebase auth changes, i.e. on itialization or sign in/sign up
	const handleAuthChange = (authUser) => {
		logger.log("authChange being handled in App.js", authUser)
		
		if (authUser !== null && !profile.isLoading) {
			const provider = authUser.providerData && authUser.providerData.length > 0 ? authUser.providerData[0] : null;
			Mixpanel.people.set({
				'$email': authUser.email,
				'email_verified': authUser.emailVerified,
				'app_version': DEPLOYMENT_VERSION_NUMBER,
				'provider_id': provider ? provider.providerId : 'unknown',
			});
			firebase.checkUser().then((userDoc) => {
				if (!userDoc.exists) {	// user has not been created
					handleTrackAdwordsConversion();
					Mixpanel.track(EVENTS.signUp, { providerId: provider ? provider.providerId : 'unknown' });
					return firebase.saveNewUserToFirestore(...firebase.constructUserFromAuth()).then((resp) => {
						dispatch(createProfileSuccess(...firebase.constructUserFromAuth().slice(1)));
						return Promise.resolve();
					});
				} else {	// we need to fetch profile
					dispatch(fetchingProfile());
					return firebase.fetchProfile().then((resp) => {
						dispatch(fetchProfileSuccess(resp));
						return Promise.resolve();
					});
				}
			}).then((_resp) => {
				dispatch(fetchingCourses());
				return firebase.fetchCoursesData();
			}).then((courses) => {
				const filteredCourses = {};	// need to change flashcards/notebooks into lists of ids
				let fetchedFlashcards = {};
				let fetchedNotebooks = {};
				Object.entries(courses).forEach(([courseId, course]) => {
					filteredCourses[courseId] = {
						...course,
						flashcards: Object.keys(course.flashcards),
						notebooks: Object.keys(course.notebooks),
					}
					fetchedFlashcards = { ...fetchedFlashcards, ...course.flashcards };
					fetchedNotebooks = { ...fetchedNotebooks, ...course.notebooks };
				});
				dispatch(fetchCoursesSuccess(filteredCourses));
				dispatch(initializeFlashcards(fetchedFlashcards));
				dispatch(initializeNotebooks(fetchedNotebooks));
				setAuthInitialized(true);
				return firebase.loadStorageMetaData(courses);
			}).then((metaData) => {
				dispatch(initializeMetaData(metaData));
				try {
					const previousTabsList = localStorage.getItem('tabsList');
					const previousActiveIndex = localStorage.getItem('activeTabIndex');
					if (previousTabsList) {
						dispatch(initializeTabs(JSON.parse(previousTabsList), JSON.parse(previousActiveIndex)));
					} else {
						dispatch(initializeTabs())
					}
			
				} catch (err) {
					Bugsnag.notify(err);
					logger.log('Error initialising tabsList', err);
				}
			}).catch((err) => {
				dispatch(fetchProfileFail(err));
				dispatch(fetchCoursesFail(err));
				Bugsnag.notify(err);
				logger.log(err)
				setAuthInitialized(true);
				if (err !== "user doc does not exist in database")
					firebase.doSignOut();
			});
		}else if (!authUser){
			dispatch(fetchProfileFail("User authorization failed"));
			dispatch(fetchCoursesFail("User authorization failed"));
			logger.log("User authorization failed")
			dispatch(initializeTabs())
			setAuthInitialized(true);
		}
		if (authUser) firebase.initializeResources();	// load in resources async
	}

	// Notice how the second argument is an empty list that means this effect will only run once
	//		NOTE: Be very careful playing with this as you can get into infinite loops with rendering
	useEffect(() => {
		const cleanup = firebase.setAuthStateChangeCallback(handleAuthChange);

		return cleanup;
	}, []);

	const clearSnackbarMessage = useCallback(() => {
		setSnackbarMessage(null);
	}, [])

	const handleConnectionChange = (e) => {
		if (profile?.email?.length > 0) {
			dispatch(changeConnectionStatus(navigator.onLine));
			setSnackbarMessage(navigator.onLine ? 'Connection re-established' : 'Connection lost, changes may not be saved')
		}
	}

	useEffectOnce(() => {
		window.addEventListener('online', handleConnectionChange);
		window.addEventListener('offline', handleConnectionChange);
	});

	useEffectOnce(() => {
		if (source) {
			Mixpanel.people.setOnce({
				source,
			});
		}
	});

	const navBar = useMemo(() => {
		if (profile.loadPending || profile.isLoading) {
			return null;
		}
		if (profile.email.length > 0) {
			return null;
			return (
				<Fragment>
					<ControlBox />
				</Fragment>
			);
		}
		if (!location.pathname.includes('landing')) {
			return <Navigation />
		}
		return null;
	}, [profile, location]);

	if (profile.isLoading || !authInitialized || tabsIsLoading) {
		return (
			<div className="flex-column-center" style={{ width: 'calc(100vw)', height: 'calc(100vh)', justifyContent: 'center' }} >
				<Spinner />
			</div>);
	}
	return (
		<ThemeProvider theme={getMUIThemeFromName(profile.theme)} >
			<Router>
				<div className={profile.email.length > 0 ? 'flex-row' : 'flex-column-center'} >
					{navBar}
					<Suspense fallback={<Spinner />} >
						<Switch>
							<Route exact path={ROUTES.LANDING} component={LandingPage} />
							<Route path={ROUTES.SIGN_UP} component={SignUpPage} />
							<Route path={ROUTES.SIGN_IN} component={SignInPage} />
							<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
							<Route path={ROUTES.PURCHASE_SUCCESSFUL} component={PurchaseSuccessfulPage} />
							<Route path={`${ROUTES.GET_SHARED}/:sharedFileId`} component={GetSharedPage} />
							<Route path={`${ROUTES.OPEN_CONVERSIONS}`} component={OpenConversionsPage} />
							{/* <Route path={ROUTES.EMAIL_UPDATE} component={EmailUpdatePage} /> */}
							{/* <Route path={ROUTES.ACCOUNT} component={AccountPage} /> */}
							<Route path={[`${ROUTES.PUBLIC}/:fileType?/:fileId?`, `${ROUTES.PUBLISHED}/:fileType?/:fileId?`]} component={PublicPage} />
							<Route exact path={ROUTES.HOME} component={TabsPage} />
							<Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
							{/* <Route path={ROUTES.HOME+"/:tab"} component={HomePage} exact /> */}
							{/* <Route exact path={ROUTES.COURSE+"/:courseId"} component={CoursePage} /> */}
							{/*NOTE: ":notebookId", the colon means it's passed as a match param to the component, see component for usage*/}
							{/* <Route path={`${ROUTES.COURSE}/:courseId/notebooks/:notebookId`} component={NotebookPage} /> */}
							{/* <Route path={`${ROUTES.COURSE}/:courseId/flashcards/:deckId`} component={FlashcardPage} /> */}
							<Route component={RedirectPage} />
						</Switch>
					</Suspense>
				</div>
			</Router>
			<CookiesBar />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={Boolean(snackbarMessage)}
				autoHideDuration={3000}
				onClose={clearSnackbarMessage}
				message={snackbarMessage}
				action={
					<>
						<IconButton size='small' aria-label='close' color='inherit' onClick={clearSnackbarMessage} >
							<CloseIcon fontSize='small' />
						</IconButton>
					</>
				}
			/>
			<Suspense fallback={<Spinner />} >
				<DialogsRoot />
			</Suspense>
		</ThemeProvider>
		);
}

export default App