import React, { useState, useEffect, useCallback } from 'react';
import {getModifiedDepth} from '../../utils';

/*Class Prefix = toc*/
import './styles.css';

const TOC = function(props) {
	const { contentState, onLinkTo } = props;	// pulling the contentState out of props
	const [chapters, setChapters] = useState([]); //our state variable
	
	const numBlocks = contentState.getBlockMap().size;	// calculate number of blocks

	const findChaptersAlt = useCallback((chapterDepth) => {
		const blockMap = contentState.getBlockMap();
		let newChapters = [];

		blockMap.forEach((currentBlock, currentBlockKey) => {

			if (currentBlock.getLength() === 0
				|| currentBlock.getType() === 'atomic') {
					return;
			}


			if (getModifiedDepth(currentBlock) === chapterDepth) {
				newChapters.push({ chapter: currentBlock.getText(), subChapters: [], blockKey: currentBlockKey });
			} else if (getModifiedDepth(currentBlock) === chapterDepth+1 && newChapters.length > 0) {
				newChapters[newChapters.length - 1].subChapters.push({ subchapter: currentBlock.getText(), blockKey: currentBlockKey });
			}
		});
	
		setChapters(newChapters);
	}, [contentState]);

	const handleLinkClick = useCallback((blockKey) => (e) => {
		e.stopPropagation();
		onLinkTo(blockKey);
	}, [onLinkTo]);

	// const findChapters = () => {
	// 	const blockMap = contentState.getBlockMap();
	// 	let newChapters = [];

	// 	blockMap.forEach((currentBlock, currentBlockKey) => {
	// 		const blockAfter = contentState.getBlockAfter(currentBlockKey);
	// 		const blockTwoAfter = blockAfter ? contentState.getBlockAfter(blockAfter.getKey()) : null;

	// 		//Disqualifiers
	// 		if (!currentBlock || !blockAfter || !blockTwoAfter ||
	// 			currentBlock.getType() === 'atomic' || //Our chapter title shouldnt be some math block,
	// 			blockAfter.getType() === 'atomic' ||   //that's nerdy as shit
	// 			blockTwoAfter.getType() === 'atomic' ||
	// 			getModifiedDepth(currentBlock) !== -1 || //Not in any list of sorts
	// 			(currentBlock.getLength() === 0 || currentBlock.getLength() > 40) || // First line is a short title, next two are empty
	// 			blockAfter.getLength() !== 0 || 	
	// 			blockTwoAfter.getLength() !== 0){	
	// 			return;
	// 		}

	// 		newChapters.push(currentBlock.getText());
	// 	});
	
	// 	setChapters(newChapters);
	// }
	
	useEffect(() => {
		findChaptersAlt(-1);
	}, [numBlocks]);	

	return (
		<div className="toc-root" >
			<p className="toc-header" >
				Outline
			</p>
			<div className="toc-wrapper-list" >
				<ol>
					{chapters.map((chap, i) => (
						<li key={`toc-chapter-${chap.blockKey}`} onClick={handleLinkClick(chap.blockKey)} className="toc-chapter">
							{chap.chapter}
							{chap.subChapters?.length > 0 &&
								<ol className="toc-sub-list" >
									{chap.subChapters.map((sub) => (
										<li
											className="toc-subchapter"
											onClick={handleLinkClick(sub.blockKey)}
											key={`toc-subchapter-${sub.blockKey}`}
										>
											{sub.subchapter}
										</li>
									))}
								</ol>
							}
						</li>
					))}
				</ol>
			</div>
		</div>);
}

export default TOC;