import React, { useState, useRef, useEffect, useCallback } from 'react';

// We use an editable div so it expands with input unlike a regular input component
const TableEntryCell = (props) => {
	const { innerText, onChange, cellKey, editing } = props;

	const [content, setContent] = useState(innerText ? innerText : '');
	const spanRef = useRef(null);

	const handleChange = (event) => {
		const value = event.target.innerText;
		setContent(value);
		onChange(cellKey, value);
	}

	const focusCell = (_event) => {
		spanRef.current.focus();
	}

	useEffect(() => {
		spanRef.current.innerText = content ? content : '';
	}, []);

	useEffect(() => {
		if (innerText !== content)
			spanRef.current.innerText = innerText || '';
	}, [innerText]);

	const eatClick = useCallback((e) => e.stopPropagation(), [])

	return (
		<td
			style={{ padding: 0 }}
			className="te-cell-root"
			key={cellKey}
			onClick={focusCell}
		>
			<div
				contentEditable={editing}
				onInput={handleChange}
				onKeyDown={eatClick}
				ref={spanRef}
				className="te-cell-span"
			/>
		</td>);
}

export default TableEntryCell;