import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import Bugsnag from '@bugsnag/js'

import { Box } from '@material-ui/core';

import { EditorState, convertToRaw, convertFromRaw, RichUtils, SelectionState, Modifier } from 'draft-js';

import Editor from '../../components/Editor';
import TOC from './components/TOC';

import firebase from '../../Firebase/';
import { getFilePreferences } from '../../data/metaData/selectors';
import { updateMetaData } from '../../data/metaData/actions';
import { isElementInViewport, isFileImage } from '../../utils/utils';

import logger from '../../utils/logger';

import DECORATORS from './Decorators';
import ControlPanel from './components/ControlPanel';
import { getCoursesById } from '../../data/courses/selectors';
import { getHasConnection } from '../../data/environment/selectors';
import { makeCourse, makeNotebookPreferences } from '../../Firebase/defaults';
import { createAtomicEntity, extractItemsFromEditorState } from '../../components/Editor/utils'
import { wordManager } from '../../utils/wordManager';
import ATOMIC_BLOCK_TYPES from './components/AtomicBlockTypes';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';
import Spinner from '../../components/Spinner';
import { removeDialog, showDialog } from '../../data/dialogs/actions';
import { CONVERSION_DIALOG_KEY } from '../../data/dialogs/keys';
import { getNotebooksById } from '../../data/notebooks/selectors';
import { getProfile } from '../../data/profile/selectors';
import { FONT_SIZES } from './styleMap';
import { uploadImage } from '../../components/Editor/utils'

/*Class Prefix = editor*/
// import './styles.css';logEvent

export const CourseColorContext = React.createContext('#53A2BE');

class Notebook extends React.PureComponent {

	constructor(props) {
		super(props);
		this.storageFilePath = firebase.getUserDataFilePath(props.courseId, 'notebooks', props.notebookId);
		this.getNotebook();
		this.editorRef = React.createRef();
		this.state = {
			initialized: false,
			editorState: null,
			awaitingSave: false,
			ottoSuggestion: '',
			editingAtomic: false,
		}
		Mixpanel.track(EVENTS.pageView, { page: 'notebook' });
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.courseId !== this.props.courseId || prevProps.notebookId !== this.props.notebookId) {
			this.storageFilePath = firebase.getUserDataFilePath(this.props.courseId, 'notebooks', this.props.notebookId);
			this.getNotebook();
		}
		if (!prevProps.hasConnection && this.props.hasConnection) {
			this.handleSave();
		}
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.handleBeforeUnload)
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload)
	}

	handleBeforeUnload = (e) => {
		if (this.state.awaitingSave) {
				e.preventDefault();
				e.returnValue = '';
				if (this.saveTimeout) {
					clearTimeout(this.saveTimeout);
				}
				this.handleSave();
				return 'You have unsaved changes are you sure you want to leave'
		}
		return null;
	}

	cleanUpOtto = () => {
		if (wordManager.ottoNode) {
			wordManager.ottoNode.innerText = '';
			wordManager.ottoNode.remove();
		}
	}

	handleOtto = () => {
		if (!this.state.initialized) {
			return;
		}
		// get the span containing the word the user is currently editing
		const ottoHookNode = document.getElementById(`otto-span-${this.state.editorState.getSelection().getEndKey()}`);
		if (ottoHookNode) {
			const prefix = ottoHookNode.innerText;	// pull out the text the user has entered so far
			const suggestion = wordManager.autoComplete(prefix);	// get the suggestion
			if (suggestion && suggestion.length > 0) {
				this.setState({ ottoSuggestion: suggestion });
				wordManager.ottoNode.innerText = suggestion;	// update the text of teh ottoNode to display suggestion
				// ottoNode may cause complications with the fact that it's inside the Editor but managed externally by us
				ottoHookNode.parentElement.insertBefore(wordManager.ottoNode, ottoHookNode.nextSibling);	// place ottoNode next to where user is typing
			} else {
				this.cleanUpOtto();
			}
		}
	}

	onLoadContent = (rawJSONString) => {
		const rawContent = JSON.parse(rawJSONString);
		const loadedContent = convertFromRaw(rawContent);
		loadedContent.getBlocksAsArray().forEach((block) => {
			if (block.getType() !== 'atomic' && block.getLength() > 0) {
				wordManager.extractAndAddWordsFromText(block.getText())
			}
		});
		const loadedEditorState = EditorState.createWithContent(loadedContent, DECORATORS);
		this.setState({ initialized: true, editorState: loadedEditorState });
	}

	getNotebook = () => {
		if (!this.storageFilePath) return;

		firebase.fetchStringifiedFromStorage(this.storageFilePath, this.onLoadContent)
			.then((resp) => {
				logger.log(resp);
			})
			.catch((e) => {
				switch (e.code) {
					case 'storage/object-not-found':	// notebook hasn't been saved yet
						this.initializeFile();	// save empty content
						break;
					default:
						logger.error(e);
						Bugsnag.notify(e);
					// setInitialized(true);
				}
			});
	}

	initializeFile = () => {
		if (this.state.initialized) {
			return;
		}
		const { notebookId, courseId } = this.props;
		this.setState({ editorState: EditorState.createEmpty(), initialized: true },
		() => this.handleSave().then(() => {
				const initialMetaData = { customMetadata: { ...makeNotebookPreferences() } }
				return firebase.updateFileMetaData(courseId, 'notebooks', notebookId, initialMetaData)	// initialize the file preferences
			}).then((metaData) => {
				this.props.updateMetaData(courseId, 'notebooks', notebookId, metaData);
			}).catch((error) => {
				Bugsnag.notify(error);
				logger.error("Error creating metadata for file: ", error);
			}));
	}

	handleSave = () => {
		const { initialized, editorState } = this.state;
		const { hasConnection } = this.props;
		if (!initialized || !this.storageFilePath || !hasConnection) {
			return;
		}
		Mixpanel.track(EVENTS.saveNotebook);
		const rawContent = convertToRaw(editorState.getCurrentContent());
		const stringifiedContent = JSON.stringify(rawContent)
		const metaDataWithPreferences = { customMetadata: { ...this.props.preferences } }
		return firebase.saveStringifiedToStorage(this.storageFilePath, stringifiedContent, metaDataWithPreferences)
			.then((response) => {
				this.setState({ awaitingSave: false });
				logger.log("SUCCESS saving notebook");
			}).catch((err) => {
				this.setState({ awaitingSave: false });
				Bugsnag.notify(err);
				logger.error("ERROR saving notebook: ", err);
			})
	}

	handleChange = (newState) => {
		this.setState({ editorState: newState });
		if (!this.state.awaitingSave) {
			this.setState({ awaitingSave: true });
		}
		setTimeout(this.handleScrollIntoView, 0);
		if (this.saveTimeout) {
			clearTimeout(this.saveTimeout);
		}
		this.saveTimeout = setTimeout(this.handleSave, 2000);
	}

	// callback for clicking a control panel button
	handleStyleChange = (stateChanges)  => {
		if (this.state.editingAtomic)
			return
		// then update the editor state accordingly
		let newEditorState = this.state.editorState
		stateChanges.forEach?.((change) => {
			newEditorState = RichUtils.toggleInlineStyle(newEditorState, change);
		})
		this.setState({ editorState: newEditorState });
	}

	handleChangeFontSize = (newFontSize) => {
		try {
			const inlineStyle = this.state.editorState.getCurrentInlineStyle();
			let newContentState = this.state.editorState.getCurrentContent();
			const currentSelection = this.state.editorState.getSelection();
			let newEditorState = this.state.editorState;
			FONT_SIZES.forEach((size) => {
				if (!currentSelection.isCollapsed()) {
					newContentState = Modifier.removeInlineStyle(newContentState, currentSelection, `FONT_SIZE_${size}`);
				}
				if (inlineStyle.has(`FONT_SIZE_${size}`)) {
					newEditorState = RichUtils.toggleInlineStyle(newEditorState, `FONT_SIZE_${size}`);
				}
			});
			newEditorState = EditorState.set(newEditorState, { currentContent: newContentState });
			newEditorState = RichUtils.toggleInlineStyle(newEditorState, `FONT_SIZE_${newFontSize}`);
			this.setState({ editorState: newEditorState });
		} catch (error) {
			Bugsnag.notify(error)
		}
	}

	handleConvertRequest = (_format) => {
		Mixpanel.track(EVENTS.openConversionDialog);
		this.props.showDialog('notebook-conversion-dialog', CONVERSION_DIALOG_KEY, {
			editorState: this.state.editorState,
			notebookId: this.props.notebookId,
			courseId: this.props.courseId,
			onClose: () => this.props.removeDialog('notebook-conversion-dialog'),
		})
	}

	handleListSelect = (listType) => {
		if (listType === 'ol') {
			this.setState({
				editorState: RichUtils.toggleBlockType(
					this.state.editorState,
					'ordered-list-item',
				)
			});
		} else {
			this.setState({
				editorState: RichUtils.toggleBlockType(
					this.state.editorState,
					'unordered-list-item',
				)
			});
		}
	}

	handleScrollIntoView = () => {
		const { currentBlock } = extractItemsFromEditorState(this.state.editorState);
		if (this.state.editingAtomic ||
			currentBlock.getType() === 'atomic' ||
			!window.getSelection() ||
			(!window.getSelection().focusNode && !window.getSelection().anchorNode)) {
			return;
		}
		const nodeBoi = window.getSelection().focusNode.parentElement || window.getSelection().anchorNode.parentElement;
		if (nodeBoi && !isElementInViewport(nodeBoi)) {
			nodeBoi.scrollIntoView(true);
		}
	}

	handleUndo = () => {
		this.setState({ editorState: EditorState.undo(this.state.editorState)});
		Mixpanel.track(EVENTS.undo)
	}
	handleRedo = () => {
		this.setState({ editorState: EditorState.redo(this.state.editorState) });
		Mixpanel.track(EVENTS.redo);
	}

	handleTOCClick = (blockKey) => {
		const blockSelection = SelectionState.createEmpty(blockKey);
		this.setState({ editorState: EditorState.forceSelection(this.state.editorState, blockSelection) }, this.handleScrollIntoView);

	}

	handleChangeAlignment = (alignment) => {
		if (!['left', 'center', 'right'].includes(alignment)) {
			return;
		}
		const newEditorState = RichUtils.toggleBlockType(this.state.editorState, `text-align-${alignment}`);
		this.setState({ editorState: newEditorState })
	}

	handleEditingAtomicChange = (editingAtomic) => {
		this.setState({ editingAtomic });
	}

	handleInsertAtomicEntity = (atomicSubtype) => {
		// e.preventDefault();
		// e.stopPropagation();
		
		const newState = createAtomicEntity(this.state.editorState, atomicSubtype,
			'IMMUTABLE', ATOMIC_BLOCK_TYPES[atomicSubtype]?.emptyData);
		this.setState({ editorState: newState });
	}

	handlePublish = () => {
		const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
		const stringifiedContent = JSON.stringify(rawContent);
		firebase.publishToPublic(this.props.notebookId, 'notebooks', stringifiedContent, {
			fileName: this.props.notebookName,
		}).then(() => {
			logger.log("Success publishing to public");
		}).catch((error) => {
			Bugsnag.notify(error);
			logger.error("Error publishing to public: ", error);
		})
	}

	handleDroppedFiles = (selection, files) => {
		Mixpanel.track(EVENTS.droppedFiles, { numFiles: files?.length })
		const file = files[0];	// just one at a time for now
		if (!isFileImage(file)) {
			return;
		}
		let newEditorState = EditorState.forceSelection(this.state.editorState, selection);
		uploadImage({
			file,
			fileId: this.props.notebookId,
			onSuccess: ({ id, name }) => {
				newEditorState = createAtomicEntity(newEditorState, 'image', 'IMMUTABLE', {
					...ATOMIC_BLOCK_TYPES['image'].emptyData,
					id,
					name,
				});
				this.handleChange(newEditorState);
			},
			onFail: (e) => null
		});
	}

	render() {
		const { editorState, awaitingSave, initialized } = this.state;
		const { preferences, courseId, course, notebookName, notebookId } = this.props;
		if (!initialized) {
			return <Spinner />
		}
		return !editorState ? null : (
			<CourseColorContext.Provider value={course?.color || 'var(--primary)'}>
				<Box display="flex" height="100%" width="100%">
					<Prompt when={awaitingSave} message="You have unsaved changes, are you sure you want to leave" />
					{preferences.showTOC &&
						<TOC
							onLinkTo={this.handleTOCClick}
							contentState={editorState.getCurrentContent()}
						/>
					}
					<ControlPanel
						course={course || {}}
						notebookName={notebookName}
						notebookId={notebookId}
						courseId={courseId}
						courseColor={course?.color}
						onStyleChange={this.handleStyleChange}
						onChangeFontSize={this.handleChangeFontSize}
						onInsertAtomicBlock={this.handleInsertAtomicEntity}
						awaitingSave={false}
						saveMessage={''}
						currentInlineStyle={editorState.getCurrentInlineStyle()}
						currentBlock={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey())}
						onConvertRequest={this.handleConvertRequest}
						onListSelect={this.handleListSelect}
						handleUndo={this.handleUndo}
						handleRedo={this.handleRedo}
						handleAddLink={() => true}
						onPublish={this.handlePublish}
						onChangeAlignment={this.handleChangeAlignment}
					/>
					<Editor
						editorState={editorState}
						onChange={this.handleChange}
						preferences={preferences}
						margin
						noTopSpacer
						height={'100%'}
						onEditingAtomicChange={this.handleEditingAtomicChange}
						editorRef={this.editorRef}
						handleDroppedFiles={this.handleDroppedFiles}
						context="notebook"
					/>
				</Box>
			</CourseColorContext.Provider>
		);
	}
}

function mapStateToProps(state, ownProps) {

	const { courseId, notebookId } = ownProps;
	// const { params: { courseId, notebookId } } = ownProps.match;	// extract courseId and notebookId from url
	const coursesById = getCoursesById(state);
	const course = coursesById ? coursesById[courseId] : makeCourse(courseId, 'loading...')

	return {
		courseId,
		course,
		notebookId,
		profile: getProfile(state),
		notebookName: getNotebooksById(state)?.[notebookId]?.name,
		preferences: getFilePreferences(state, { courseId, subsection: 'notebooks', fileId: notebookId }),
		hasConnection: getHasConnection(state),
	}
}

function mapDispatchToProps(dispatch) {

	return {
		updateMetaData: (...args) => dispatch(updateMetaData(...args)),
		showDialog: (...args) => dispatch(showDialog(...args)),
		removeDialog: (...args) => dispatch(removeDialog(...args)),
	}
}

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(Notebook);