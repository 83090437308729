import { makeStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";

import * as Routes from "../../constants/routes";

/*Class Prefix = nav*/
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
}));

const Navigation = function (props) {
  const classes = useStyles();

  return (
    <div className={`nav-root ${classes.root}`} style={{ marginTop: 24 }}>
      <div
        style={{
          background: "red",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
        }}
      >
        Jotly Notes will be shutting down 5/31/2024, please move any
        notes/flashcards that you want to keep to another platform.{" "}
      </div>
      <Link to={Routes.LANDING} className="nav-logo-container">
        <img
          src={"/assets/logo-final-03.svg"}
          alt="j-logo-nav"
          className="nav-logo"
        />
      </Link>

      <div className="flex-row-center">
        <Fragment>
          {props.location.pathname !== "/signup" &&
            props.location.pathname !== "/" && (
              <Link to={Routes.SIGN_UP} className="nav-text">
                Sign Up
              </Link>
            )}
          {props.location.pathname !== "/signin" &&
            props.location.pathname !== "/" && (
              <Link to={Routes.SIGN_IN} className="nav-text">
                Sign In
              </Link>
            )}
        </Fragment>
      </div>
    </div>
  );
};

export default compose(withRouter)(Navigation);
