import React from 'react';

import { AppBar, Box, Button, Toolbar, Typography } from '@material-ui/core';

import { useLocalStorage } from 'react-use';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';

const toolBarStyles = { bottom: 0, top: 'auto'};

const TSLink = () => <Typography color="inherit" component="a" href="/privacy-policy">Terms of Service and Privacy Policy</Typography>

const CookiesBar = function(_props) {

	const [bannerDismissed, setBannerDismissed] = useLocalStorage('dismissed-cookies-banner', false);

	const handleDismiss = () => {
		setBannerDismissed(true);
		Mixpanel.track(EVENTS.dismissCookiesBanner);
	}

	if (bannerDismissed) {
		return null;
	}

	return (
		<AppBar position="fixed" style={toolBarStyles}>
				<Toolbar>
					<Box padding={2} display="flex" justifyContent="space-between" width="100%">
						<Box flex={4}>
							<Typography color="inherit">
								We use cookies on Jotly as described in our <TSLink />,
								including for security, analytics, advertising and marketing purposes.
								By clicking “Got it” or continuing to use Jotly, you accept these uses.
								For more info, read our <TSLink />.
							</Typography>
						</Box>
						<Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
							<Button
								variant="contained"
								color="secondary"
								onClick={handleDismiss}
							>
								Got it
							</Button>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
	);
}

export default CookiesBar;