import React, { useState, useRef, Fragment } from 'react';
import katex from 'katex';	// we use katex package to render the latex

/*Class Prefix = ee*/
import './styles.css'

import KatexOutput from './KatexOutput';
import KatexButton from './KatexButton';

import PRESETS from '../../../../constants/katexPresets';

import '@draft-js-plugins/alignment/lib/plugin.css';

const EquationEntry = (props) => {
	const { latexContent, viewOnly, editing, onChange, onFinishEdit } = props;	// extract props

	// instantiate state variables
	// NOTE: we manage the edit position so we can move the cursor around ourselves
	const [editPosition, setEditPosition] = useState(null);
	const [texValue, setTexValue] = useState(latexContent || '');
	const [_invalidTex, setInvalidTex] = useState(false);
	const inputRef = useRef(null);

	// handle clicking on the katex render
	const handleKatexClick = (event) => {
		if (editing || viewOnly)	return;	// if currently editing do nothing

		// otherwise set usetContentp starting to edit
		setEditPosition(texValue.length);	// start editing at the end of it
	}

	// handles editing the latex
	const handleChange = (event) => {
		const value = event.target.value;
		let invalid = false;
		try {
			katex.__parse(value);	// try to parse to make sure we'll be able to render it
		} catch (e) {
			invalid = true;
		} finally {
			setEditPosition(event.target.selectionStart);
			setTexValue(value);
			setInvalidTex(invalid);
			onChange({ latexContent: value });
		}
	}

	// 
	const trackEditPosition = (event) => {
		setEditPosition(event.target.selectionStart);
	}

	const insertTexPreset = (preset) => {
		const selectionStart = inputRef.current.selectionStart;
		const selectionEnd = inputRef.current.selectionEnd;
		const newTexValue = texValue.slice(0, selectionStart) + preset + texValue.slice(selectionEnd);
		setTexValue(newTexValue);
		onChange({ latexContent: newTexValue });
		const [start, end] = getNextTexInputRange(newTexValue);
		setTimeout(() => {
			inputRef.current.setSelectionRange(start, end);
			setEditPosition(start);
		}, 100)
	}

	// searches for the next range for inputing latex, basically looking for next {...} or [...]
	const getNextTexInputRange = (overrideTex=texValue) => {
		let startPosition = editPosition;
		if (overrideTex.length === editPosition) {
			startPosition = 0;
		}
		let start = startPosition;
		let end = startPosition;
		for (let i=startPosition; i<overrideTex.length; i++) {
			const currentChar = overrideTex[i];
			if (currentChar === '{' || currentChar === '[') {
				start = i+1;
			}
			else if ((start !== startPosition) &&
				((currentChar === '}' && overrideTex[start-1] === '{') || (currentChar === ']' && overrideTex[start-1] === '['))) {
				end = i;
			}
			if (start !== startPosition && end !== startPosition) {
				break;
			}
		}
		if (start === startPosition || end === startPosition) {
			return [overrideTex.length, overrideTex.length];
		}
		else {
			return [start, end];
		}
	}

	// callback for keyDown
	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {	// check if the key pressed was Tab
			e.preventDefault();	// maintain focus on equation entry
			// move selection to next input
			const [start, end] = getNextTexInputRange();
			inputRef.current.setSelectionRange(start, end);
			setEditPosition(start);
		} else if (e.key === 'Enter') {
			onFinishEdit?.();
		} else {
			// otherwise update the edit position
			trackEditPosition(e);
		}
	}

	// const focus = () => inputRef.current && inputRef.current.focus();

	return (
		<div
			className={`ee-root ${editing ? 'ee-root-edit' : ''}`}
		>
			{editing &&
				<Fragment>
					<div className="ee-presets-container" >
						{PRESETS.data && PRESETS.data.map((group) => {
							return (
								<div key={`ee-group-${group.groupDescription}`} className="ee-presets-container-inner" >
									<div className="ee-presets-container-inner-title" >
										{group.groupDescription}
									</div>
									{group.items.map((item) => {
										return (
											<KatexButton
												key={`ee-item-${item.label}`}
												texLabel={item.label}
												value={item.insertion}
												onSelect={insertTexPreset}
											/>);
									})}
								</div>);
						})}
					</div>
					<input
						onChange={handleChange}
						onClick={trackEditPosition}
						onKeyDown={handleKeyDown}
						onFocus={trackEditPosition}
						value={texValue}
						className="ee-input"
						ref={inputRef}
					/>
				</Fragment>
			}
			<div
				onClick={handleKatexClick}
				className={`ee-katex-container${!editing && texValue.length === 0 ? '-empty' : ''} ${editing ? 'ee-katex-container-edit' : ''}`}
			>
				<KatexOutput content={texValue} onClick={handleKatexClick} />
			</div>
		</div>);
}

export default EquationEntry;