import React from 'react';
import { connect } from 'react-redux';
import { OrderedSet as ImmutableOrderedSet } from 'immutable';
import Bugsnag from '@bugsnag/js';

// imports for Draft JS
import {
	// Editor,
	EditorState,
	RichUtils,
	getDefaultKeyBinding,
	KeyBindingUtil,
	SelectionState,
	Modifier,
} from 'draft-js';
// import "draft-js/dist/Draft.css";	// for styling blocks

import '@draft-js-plugins/alignment/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';

import PluginsEditor, { composeDecorators } from '@draft-js-plugins/editor';
import createAlignmentPlugin from '@draft-js-plugins/alignment';
import createFocusPlugin from '@draft-js-plugins/focus';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';

import { getProfile, getUserLimitReached } from '../../data/profile/selectors';
import { wordManager } from '../../utils/wordManager';
import { getModifiedDepth } from '../../containers/Notebook/utils';
import * as keyHandlers from '../../containers/Notebook/keyHandlers';
import {
	completePrefix,
	enrichState,
} from '../../containers/Notebook/utils';
import { isElementInViewport } from '../../utils/utils';
import STYLE_MAP from '../../containers/Notebook/styleMap';
import BLOCK_RENDER_MAP from '../../containers/Notebook/blockRenderMap';
import Mixpanel, { EVENTS } from '../../utils/mixpanel';

/*Class Prefix = editor*/
import './styles.css';

import { createCodeBlockPlugin, createEquationEntryPlugin, createTableEntryPlugin, createTextBoxPlugin, createImagePlugin } from './plugins';
import { injectPastedContent, takeHyphen, takeLetter, takeSpace, takeColon, determineCurrentFontSize } from './utils';

import { ottoStrategy, OttoSpan } from '../../containers/Notebook/Decorators'
import logger from '../../utils/logger';

const focusPlugin = createFocusPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const decorator = composeDecorators(
	alignmentPlugin.decorator,
	focusPlugin.decorator,
);
const customDecorators = [{ strategy: ottoStrategy, component: OttoSpan }];

class Editor extends React.Component {

	constructor(props) {
		super(props);
		this.ottoSuggestion = '';
		this.state = {};
		this.editorRef = React.createRef();
		const pluginFunctions = {
			decorator,
			onStartEdit: this.handleStartEdit,
			onFinishEdit: this.handleFinishEdit,
			onContentChange: this.onContentChange,
			onDeleteBlock: this.handleDeleteBlock,
		}
		const tableEntryPlugin = createTableEntryPlugin(pluginFunctions)
		const equationEntryPlugin = createEquationEntryPlugin(pluginFunctions);
		const codeBlockPlugin = createCodeBlockPlugin(pluginFunctions);
		const textBoxPlugin = createTextBoxPlugin(pluginFunctions);
		const imagePlugin = createImagePlugin(pluginFunctions);
		// const ottoPlugin = createOttoPlugin(pluginFunctions);
		this.plugins = [inlineToolbarPlugin, focusPlugin, alignmentPlugin, equationEntryPlugin,
			tableEntryPlugin, codeBlockPlugin, textBoxPlugin, imagePlugin];

		this.letDefaultTab = React.createRef(false);
	}

	onContentChange = (newContent, changeType="change-block-data") => {
		const newEditorState = EditorState.push(this.props.editorState, newContent, changeType);
		this.onChange(newEditorState);
	}

	checkScrollPosition = () => {
		if (!window.getSelection() || (!window.getSelection().focusNode && !window.getSelection().anchorNode)) {
			return;
		}
		const nodeBoi = window.getSelection().focusNode.parentElement || window.getSelection().anchorNode.parentElement;
		if (nodeBoi && !isElementInViewport(nodeBoi) && !this.state.editingAtomic) {
			// nodeBoi.scrollIntoView(true);
		}
	}

	onChange = (newState) => {
		this.props.onChange(newState)
	}

	cleanUpOtto = () => {
		this.ottoSuggestion = '';
		if (wordManager.ottoNode) {
			wordManager.ottoNode.innerText = '';
			wordManager.ottoNode.remove();
		}
	}

	// This function lets us override/add functionality to certain key inputs
	//	- it returns a string that gets passed to handleKeyCommand below as "command" paramter
	//	- see more at https://draftjs.org/docs/advanced-topics-key-bindings
	keyBindingOverride = (event) => {
		const { editorState } = this.props;
		if (this.props.userLimitReached.storage && event.keyCode !== 8) {
			return 'eat-key';
		}

		// this is to disable shift+ctrl+del which would cause a crash, instead it acts as ctrl+del
		if (event.keyCode === 46 && event.shiftKey && KeyBindingUtil.hasCommandModifier(event)) {	// 46 for DEL
			return 'delete-word';
		}

		if (event.keyCode === 9) {	// 9 for TAB
			event.preventDefault();	// maintain focus on the editor
			if (this.ottoSuggestion.length > 0) {	// if the tab is meant to complete otto suggestion
				Mixpanel.track(EVENTS.autocomplete);
				return 'complete-otto';
			}
			return `handle${event.shiftKey ? '-shift' : ''}-tab`;
		}

		const selectionIsAtEndOfBlock = editorState.getSelection().getEndOffset() === editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getEndKey()).getLength();
		const ottoHookNode = document.getElementById(`otto-span-${this.props.editorState.getSelection().getEndKey()}`);
		if (
			selectionIsAtEndOfBlock
			&& this.props.preferences.enableOtto
			&& ottoHookNode
			&& ((event.keyCode >= 97 && event.keyCode <= 122) || (event.keyCode >= 65 && event.keyCode <= 90))) {
			const prefix = ottoHookNode.innerText + event.key;	// pull out the text the user has entered so far
			const suggestion = wordManager.autoComplete(prefix.toLowerCase());	// get the suggestion
			if (suggestion && suggestion.length > 0) {
				let compound = prefix + suggestion;
				this.ottoSuggestion = compound;
				wordManager.ottoNode.innerText = suggestion;	// update the text of teh ottoNode to display suggestion
				const adjustedFontSize = ottoHookNode.firstChild ? window.getComputedStyle(ottoHookNode.firstChild).fontSize : '16px';	// get font size of where user is typing
				wordManager.ottoNode.style.fontSize = adjustedFontSize
				wordManager.ottoNode.setAttribute('readonly', true)
				wordManager.ottoNode.contentEditable = false
				// ottoNode may cause complications with the fact that it's inside the Editor but managed externally by us
				ottoHookNode.parentElement.insertBefore(wordManager.ottoNode, ottoHookNode.nextSibling);	// place ottoNode next to where user is typing
			} else {
				this.cleanUpOtto();
			}
		} else {
			this.cleanUpOtto();
		}
		
		if (event.keyCode === 13) {	// 13 for ENTER
			// event.preventDefault();
			const block = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getEndKey());
			if (this.props.editorState.getSelection().isCollapsed()) {
				if (block.getLength() === 0 && getModifiedDepth(block) > -1) {
					return 'handle-shift-tab';
				}
			}
		}

		if (event.keyCode === 83 && KeyBindingUtil.hasCommandModifier(event)) {	// 83 for 's'
			event.preventDefault();	// stop it from default saving the webpage
			return 'handle-save';
		}

		// if we don't override what happens we get the default command to be passed to handleKeyCommand
		return getDefaultKeyBinding(event);
	}

	// do stuff based on the command determined by keyBindingOverride
	handleKeyCommand = (command, editorState) => {
		var newState;

		switch(command) {
			case 'eat-key':
				return 'handled';
			case 'do-nothing':
				break;
			case 'handle-tab':
				newState = keyHandlers.handleTab(editorState, false);
				break;
			case 'handle-shift-tab':
				newState = keyHandlers.handleTab(editorState, true);
				break;
			case 'complete-otto':
				// set new state here
				// otto.enforceWord(this.state.ottoSuggestion);
				newState = completePrefix(editorState, this.ottoSuggestion);
				this.cleanUpOtto();
				break;
			// case 'backspace':
			// 	const currentBlock = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey());
			// 	if (['unordered-list-item', 'ordered-list-item', 'unordered-list-item-hyphen'].includes(currentBlock?.getType())) {
			// 		this.letDefaultTab.current = true;
			// 	}
			// case 'handle-save':
			// 	handleSaveContent();
			// 	return 'handled';	// not changing the editor state but it is handled
			default:
				newState = RichUtils.handleKeyCommand(editorState, command);
		}

		// if the state was changed update it
		if (newState) {
			const enrichedState = enrichState(newState, command, this.props.profile.enrichmentPrefs);	// before we update the state we enrich it
			this.onChange(enrichedState);
			return 'handled';	// return that we handeled the update ourself
		}

		// indicate that we did not handle anything
		return 'not-handled';
	}

	handleBeforeInput = (chars, editorState, eventTimeStamp) => {
		if (!editorState.getSelection().isCollapsed()) {
			return;	// only handle collapsed selections for now
		}

		const enabledKeys = this.props.disableEnrichments ? {} : this.props.profile.enrichmentPrefs;
		let possiblyEnrichedState;

		try {
			switch (chars) {
				case '-':
					possiblyEnrichedState = takeHyphen(editorState, enabledKeys);
					break;
				case ' ':
					possiblyEnrichedState = takeSpace(editorState, enabledKeys, this.props.preferences?.enableAutoCorrect);
					break;
				case ':':
					possiblyEnrichedState = takeColon(editorState, enabledKeys);
					break;
				default:
					if (/[a-zA-Z]/.test(chars)) {
						possiblyEnrichedState = takeLetter(editorState, enabledKeys, chars)
					}
					break;
			}
	
			if (possiblyEnrichedState) {
				this.onChange(possiblyEnrichedState);
				return 'handled';
			}
	
			return 'not-handled'
		} catch (error) {
			logger.log("ERROR enriching: ", error)
			Bugsnag.notify(error);
			return 'not-handled';
		}
		
	}

	handleReturn = (_event, editorState) => {
		if (editorState.getSelection().isCollapsed()) {
			const enabledKeys = this.props.profile.enrichmentPrefs;
			const block = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getEndKey());
			if (block.getType() !== 'atomic' && block.getLength() > 0) {
				wordManager.extractAndAddWordsFromText(block.getText());
			}

			// handle the return
			this.cleanUpOtto();
			let newContentState = Modifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
			let newEditorState = EditorState.push(editorState, newContentState, 'split-block');
			//let ns = RichUtils.insertSoftNewline(editorState);
			if (enabledKeys['reset-style-on-enter']) {
				const currentFontSize = determineCurrentFontSize(newEditorState);
				newEditorState = EditorState.setInlineStyleOverride(newEditorState,
					currentFontSize ? ImmutableOrderedSet([`FONT_SIZE_${currentFontSize}`]) : ImmutableOrderedSet());
			}
			this.onChange(newEditorState);
			return 'handled';
		}
	}
	
	getNotebookBackgroundClass = () => {
		switch(this.props.preferences.background) {
			case 'lined':
				return 'editor-lined-background';
			case 'grid':
				return 'editor-grid-background';
			case 'blank':
					return 'editor-blank-background';
			default:
				return 'editor-blank-background';
		}
	}

	handleStartEdit = () => {
		this.setState({ editingAtomic: true });
		this.props.onEditingAtomicChange && this.props.onEditingAtomicChange(true);
	}
	
	handleFinishEdit = () => {
		this.setState({ editingAtomic: false });
		this.props.onEditingAtomicChange && this.props.onEditingAtomicChange(false);
	}

	handlePastedText = (text, html, eState) => {
		Mixpanel.track(EVENTS.pasteIntoNotebook, { context: this.props.context })
		const newEditorState = injectPastedContent(text, html, eState);
		if (newEditorState) {
			this.onChange(newEditorState)
			return 'handled';
		}
	}

	handleDeleteBlock = (blockKey) => {
		const blockSelection = SelectionState.createEmpty(blockKey)
			.set('anchorOffset', 0)
			.set('focusOffset', this.props.editorState.getCurrentContent().getBlockForKey(blockKey).getLength())
			this.onChange(EditorState.push(this.props.editorState, Modifier.removeRange(this.props.editorState.getCurrentContent(), blockSelection, 'rtl'), 'remove-range'));
			this.setState({ editingAtomic: false })
	}


	focusEditor = (e) => {
		this.editorRef.current.focus();
		this.onFocus(e)
	}
	
	onFocus = (e) => {
		this.cleanUpOtto()
		this.props.onFocus && this.props.onFocus(e);
	}

	blockStyleFn = (contentBlock) => {
		if (contentBlock.getType() !== 'atomic') {
			return 'editor-lined-block'
		}
	}

	render() {
		const { editorState, preferences, readOnly, margin, height, noTopSpacer, placeholder } = this.props;
		return !editorState ? null : (
			<div style={{ height: height || 'calc(100vh - 100px)', fontFamily: preferences.fontFamily, width: '100%' }} id="editor-root" >
				<div className='editor-scroll-container' >
					{!noTopSpacer && <div style={{ height: 10 }} />}
					<div
						onClick={this.focusEditor}
						className={`editor-container ${this.getNotebookBackgroundClass()} ${margin ? 'editor-margin editor-container-side-line' : ''}`}
					>
						<PluginsEditor
							ref={(ref) => {
								this.editorRef.current = ref;
								if (this.props.editorRef?.current) {
									this.props.editorRef.current = ref;
								}
							}}
							editorState={editorState}
							onChange={this.onChange}
							handleKeyCommand={this.handleKeyCommand}
							keyBindingFn={this.keyBindingOverride}
							readOnly={this.state.editingAtomic || readOnly}
							customStyleMap={STYLE_MAP}
							blockRenderMap={BLOCK_RENDER_MAP}
							plugins={this.plugins}
							decorators={customDecorators}
							handlePastedText={this.handlePastedText}
							handleBeforeInput={this.handleBeforeInput}
							handleReturn={this.handleReturn}
							blockStyleFn={this.blockStyleFn}
							onFocus={this.onFocus}
							spellCheck
							handleDroppedFiles={this.props.handleDroppedFiles}
							placeholder={<span className='editor-placeholder'>{placeholder || "Start taking notes here..."}</span>}
						/>
						<AlignmentTool />
						<InlineToolbar />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		profile: getProfile(state),
		userLimitReached: getUserLimitReached(state),
	}
}

export default connect(mapStateToProps)(Editor);