import React from 'react';
import { useDispatch } from 'react-redux';
import Bugsnag from '@bugsnag/js';

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';

import firebase from '../../../Firebase';

import logger from '../../../utils/logger';
import { changeFlashcardsName } from '../../../data/flashcards/actions';
import { RENAME_DIALOG_KEY } from '../../../data/dialogs/keys';
import { showDialog } from '../../../data/dialogs/actions';

/*Class Prefix = prefs*/
// styles.css imported in ControlBox

const FlashcardsPreferences = function(props) {
	const { deck, courseId, courseName, onUpdate } = props;
	const { preferences: prefs, id: deckId } = deck;
	const dispatch = useDispatch()

	const handlePrefsChange = (pref, value) => (_e) => {
		onUpdate({ ...prefs, [pref]: value });
	}

	const handleRename = () => {
		dispatch(showDialog('rename-flashcards', RENAME_DIALOG_KEY, {
			currentName: deck.name,
			onSubmit: (newName) => {
				firebase.updateFlashcardsInCourse(courseId, deck.id, { name: newName }).then((resp) => {
					logger.log('updated flashcard name', resp);
					dispatch(changeFlashcardsName(deck.id, newName));
				}).catch((err) => {
					logger.error("Error updating flaschards name: ", err);
					Bugsnag.notify(err);
				});
			}
		}))
	}

	return (
		<div className="flex-column-center prefs-root" >
			<div className="flex-row-center" >
				<p className="prefs-name-header" >{deck.name}</p>
				&nbsp;&nbsp;
				<Tooltip title="edit name" >
					<IconButton onClick={handleRename} color='secondary' >
						<EditIcon />
					</IconButton>
				</Tooltip>
			</div>
			<div className="prefs-content" >
			</div>
		</div>);
}

export default FlashcardsPreferences;