import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import { compose } from 'recompose';
import Bugsnag from '@bugsnag/js';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import firebase from '../../Firebase';
import { DEPLOYMENT_VERSION_NUMBER } from '../../Firebase/firebase';

import * as Routes from '../../constants/routes';

import NotebookPreferences from './preferenceComponents/NotebookPreferences';
import FlashcardsPreferences from './preferenceComponents/FlashcardsPreferences';
import './preferenceComponents/styles.css'

/*Class Prefix = cb*/
import './styles.css';
/*Class Prefix = prefs*/
import './preferenceComponents/styles.css';

import logger from '../../utils/logger';
import { getProfile } from '../../data/profile/selectors';
import { getCoursesById } from '../../data/courses/selectors';
import { getFlashcardsById } from '../../data/flashcards/selectors';
import { getNotebooksById } from '../../data/notebooks/selectors';
import { updateMetaData } from '../../data/metaData/actions';
import { signOut, updateProfile } from '../../data/profile/actions';
import { NOTEBOOK_TAB_KEY } from '../../data/tabs/keys';
import { getActiveTab } from '../../data/tabs/selectors';
import { showDialog } from '../../data/dialogs/actions';
import { FEEDBACK_DIALOG_KEY } from '../../data/dialogs/keys';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.primary.main,
	},
	name: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: '100%',
		lineHeight: 1,
	},
}))

const ControlBox = function(props) {
	const { history } = props;	// extracting props
	const profile = useSelector(getProfile);
	const courses = useSelector(getCoursesById);
	const flashcards = useSelector(getFlashcardsById);
	const notebooks = useSelector(getNotebooksById);
	const activeTab = useSelector(getActiveTab);
	const dispatch = useDispatch();
	const [hidden, setHidden] = useState(!profile.controlBoxOpen);
	const classes = useStyles();
	const location = useLocation();

	const handleLogout = useCallback(() => {
		firebase.doSignOut();	// tells the firebase object to sign the user out
		dispatch(signOut());
		logger.log("logged out")
		history.push(Routes.SIGN_IN)	// redirect to sign in page
	}, [history]);

	// when called with a route returns a callback that pushes to the route
	const handleNavigate = useCallback((route) => (_e) => {history.push(route);}, [history])

	const toggleHide = useCallback(() => {
		setHidden(!hidden);
		firebase.updateProfile('controlBoxOpen', hidden).then((resp) => {
			dispatch(updateProfile({ controlBoxOpen: hidden }));
		});
	}, [hidden, dispatch]);

	const handleRefresh = useCallback(() => {
		return;
		firebase.initializeUser(true).then((_resp) => {
			firebase.loadStorageMetaData().then((resp) => logger.log(resp));
		});
	}, [])

	// preferencesObj should be the object to store under metaData.customMetaData.preferences
	const handleUpdatePreferences = useCallback((courseId, subsection, fileId) => (preferencesObj) => {
		const newMetaData = { 
			customMetadata: {
				...preferencesObj,
			}
		}
		firebase.updateFileMetaData(courseId, subsection, fileId, newMetaData).then((metaData) => {
			logger.log("updated meta data successfully");
			dispatch(updateMetaData(courseId, subsection, fileId, metaData));
		}).catch((error) => {
			Bugsnag.notify(error);
			logger.log(error);
		});
	}, [dispatch]);

	const handleOpenFeedback = () => {
		dispatch(showDialog('control-box-feedback', FEEDBACK_DIALOG_KEY));
	}

	const handleClickLogo = () => {
		
	}

	const activePreferences = useMemo(() => {	// for accessing the preferences of the opened file
		if (location.pathname?.includes('/public') || location.pathname?.includes('/published'))	return null;
		if (activeTab.fileId) {
			const courseId = activeTab.courseId;
			const resourceType = activeTab.key === NOTEBOOK_TAB_KEY ? 'notebooks' : 'flashcards';
			const resourceId = activeTab.fileId;
			switch (resourceType) {
				case 'notebooks':
					return (
						<NotebookPreferences
							notebook={notebooks[resourceId] || {}}
							onUpdate={handleUpdatePreferences(courseId, 'notebooks', resourceId)}
							courseName={courses[courseId]?.name || ''}
							courseId={courseId}
						/>);
				case 'flashcards':
					return (
						<FlashcardsPreferences
							deck={flashcards[resourceId] || {}}
							onUpdate={handleUpdatePreferences(courseId, 'flashcards', resourceId)}
							courseName={courses[courseId].name}
							courseId={courseId}
						/>);
				default:
					return null;
			}
		} else {
			return (
					<div className="prefs-root" >
						<div className="prefs-content" >
							{/* <div className="cb-settings-text-button" onClick={handleNavigate(Routes.ACCOUNT)}>Account Page</div> */}
							{/* <div className="cb-settings-text-button" onClick={handleRefresh} >Refresh</div> */}
						</div>
					</div>);
		}
	}, [activeTab, profile, courses, handleNavigate, handleRefresh, handleUpdatePreferences, flashcards, notebooks, location]);


	if (!profile)	return <p>error</p>;

	return (
		<Box className={`cb-root ${hidden ? 'cb-root-hidden' : ''} ${classes.root}`} id="control-box" >
			<div
				className={`cb-root-toggle ${hidden ? 'cb-root-toggle-hidden' : ''}`}
				onClick={toggleHide}
			>
				{hidden ? <SettingsIcon className='cb-root-toggle-icon' /> : <ChevronLeftIcon className='cb-root-toggle-icon' />}
			</div>
			<Box height={175} minHeight={175} margin="auto" width="100%" style={{ overflow: 'hidden' }}>
				<div className={`cb-avatar-container`} >
					<img
						src={'/assets/logo-final-03.svg'}
						alt="avatar"
						className="cb-avatar-image"
						onClick={handleClickLogo}
					/>
				</div>
				<div className="cb-version-number" >v.&nbsp;{DEPLOYMENT_VERSION_NUMBER}</div>
				
			</Box>
			<Box height="calc( 100% - 175px)" style={{ overflowY: hidden ? 'hidden' : 'auto', direction: 'rtl', boxSizing: 'border-box' }} width="100%" paddingRight={hidden ? 0 : 2}>
				<div style={{ direction: 'ltr' }} >
					<div id="active-prefs-container" className="flex-column" style={{ justifyContent: 'space-between' }} >
						{activePreferences}
					</div>
					{Object.keys(courses).length === 0 &&
						<>
							<h2 className='cb-welcome-header' >
								Welcome to Jotly!
							</h2>
							<p className='cb-welcome-message' >
								Thanks for signing up for our beta
							</p>
							<br />
							<p className='cb-welcome-message' >
								If you have any questions or comments use the feedback tool in the bottom right or email us at jotlynotes@gmail.com
							</p>
						</>
					}
				</div>
			</Box>

		</Box>
	);
}

export default compose(	// lets us chain together HOC
	withRouter	// injects routing props i.e. history, match
)(ControlBox);