import Deque from 'collections/deque';

//Demarou Levenshtein, which includes transpositions, unlike basic bitch regular Levenshtein
//This isn't worth commenting as it's not worth reading, really
function demLevenshtein(word, otherWord) {
	if (!word || !otherWord || word.length === 0|| otherWord.length === 0) 
		return 10000; //This shouldn't happen but just incase

	let m = word.length;
	let n = otherWord.length;
	let max = m+n;
	let score = new Array(m+2)
	let sd = {};
	
	for (let i = 0; i < m+2; i++)
		score[i] = new Array(n+2);
	
	score[0][0] = max;
	
	for (let i = 0; i <= m; i++){
		score[i+1][1] = i;
		score[i+1][0] = max;
		sd[word[i]] = 0;
	}

	for (let j = 0; j <= n; j++){
		score[1][j+1] = j;
		score[0][j+1] = max;
		sd[otherWord[j]] = 0;
	}

	for (let i = 1; i <= m; i++){
		let tracker = 0;
		for (let j = 1; j <= n; j++){
			let i1 = sd[otherWord[j-1]]
			let j1 = tracker;
			if (word[i-1] === otherWord[j-1]) {
				score[i+1][j+1] = score[i][j];
				tracker = j;
			}else{
				score[i+1][j+1] = Math.min(score[i][j], Math.min(score[i+1][j], score[i][j+1])) + 1;
			}
			score[i+1][j+1] = Math.min(score[i+1][j+1], score[i1] ? score[i1][j1] + (i-i1-1) + 1 + (j-j1-1) : Infinity);
		}
		sd[word[i-1]] = i;
	}

	return score[m+1][n+1];
}


//=========================================================================================//
//        (spellcheck dady)          BK-Tree      (im finna busst)                         //
//=========================================================================================//

const tolerance = 3; //The max or min edit distance

class BKNode { 
	constructor(word){
		this.word = word;
		this.editDistance = -1; //From parent to this node. Start at -1
		this.children = []; //Array of BKNodes, where their edit distance is from word here to their word
	}
}

/*
* BKTree 
* 	Amount of nodes = amount of words
*	Child nodes are unique based on edit-distance
*	When inserting, if that edit-distance already exists, it's propogated down
*	until it can be a child where it's a unique edit-distance
*/
class BKTree {
	constructor(){
		this.root = null;
	}

	//Add a single word to our tree
	add(word){
		//Edge case: root doesn't exist yet
		if(this.root === null){
			this.root = new BKNode(word);
			return;
		}

		//Always start from the root
		let currentWord = this.root.word;
		let children = this.root.children;

		while(true){
			let dist = demLevenshtein(word, currentWord); //edit-distance
			let target = null; //Where we are thinking of placing the new node
			
			//We want to put the node in it's unique position based on edit-distance
			//So, look at our current children, see if that position is open
			children.forEach(childNode => {
				if(childNode.editDistance === dist)
					target = childNode;
			});

			//Finally found a child position that's open, place the new word here
			if(target == null){
				let newNode = new BKNode(word);
				newNode.editDistance = dist;
				children.push(newNode);
				break;
			}

			//That spot was taken, so take the children of the taken spot, and try again
			currentWord = target.word;
			children = target.children;
		}
	}

	//Returns a spellcheck suggestion
	getSuggestions(word){
		if(this.root === null)
			return "" 
		
		let suggestions = [] //The batch of words we'll pick our best suggestion from
		//Candidates = path down the tree = suggestion candidates
		//deque = double que = can pop from the front and back
		let candidates = new Deque([this.root]); 

		while(candidates.length > 0){
			let currentNode = candidates.shift(); //Take from the front of the que
			let currentWord = currentNode.word;
			let children = currentNode.children;
			let dist = demLevenshtein(word, currentWord);

			//It's very similar to our word, add it as a suggestion
			if(dist <= tolerance)
				suggestions.push({word: currentWord, editDistance: dist}) 
			
			//Min and max are how we navigate the tree
			let min = dist - tolerance;
			let max = dist + tolerance;

			/*
			* If the dist (edit distance) between current word and given word is high, 
			* that mean's we're far off, so our min to max range will be high (like 5-9).
			* With a higher range, we only observe words that are very different than our current word
			* as candidate words in an attempt to put us back on the right path.
			*
			* If the dist is low, that mean's we're on the right path.
			* So, min and max will have a lower range (like 1-4), 
			* allowing us to only collect candidate words similar to our given word
			*/
			children.forEach(childNode => {
				if(min <= childNode.editDistance && childNode.editDistance < max && childNode.editDistance !== -1){
					candidates.push(childNode);
				}
			});
		}
		
		let bestSuggestions = [];
		
		let bestEditDistance = Number.POSITIVE_INFINITY;
		
		//Now that we have a bunch of valid suggestions that are very similar to our word,
		//find the best one using edit distance again
		suggestions.forEach(suggestionObj => {
			if(suggestionObj.editDistance < bestEditDistance){
				//We only want suggestions that are all tied for lowest edit distance
				bestEditDistance = suggestionObj.editDistance;
				bestSuggestions = [];
				bestSuggestions.push(suggestionObj.word);
			}else if (suggestionObj.editDistance === bestEditDistance){
				bestSuggestions.push(suggestionObj.word);
			}
		})
		
		return bestSuggestions;
	}
}

export { BKTree as spellCheck };