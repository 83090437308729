import Bugsnag from '@bugsnag/js';
import { useLocation } from "react-router-dom";

// reads the user data out of local storage and converts it to JSON
export const readUserDataFromLocalStorage = () => {
	const data = window.localStorage.getItem('userData');
	if (data)
		return JSON.parse(window.localStorage.getItem('userData'));
	return null;
}

// saves userData JSON object to local storage
export const saveUserDataToLocalStorage = (userData) => {
	window.localStorage.setItem('userData', JSON.stringify(userData));
}

// returns true if a given characterMetaData has some sort of emphasis
//	- at the time it checks for the following: UNDERLINE, BOLD, HIGHLIGHT
export const characterHasEmphasis = (characterMetaData) => {
	return characterMetaData.hasStyle('UNDERLINE') ||
		characterMetaData.hasStyle('BOLD') ||
		characterMetaData.hasStyle('HIGHLIGHT');
}

export const isDev = () => {
	return process.env.NODE_ENV === "development";
}

export const isProd = () => {
	return process.env.NODE_ENV === "production";
}

export const serverURL = () => {
	return isProd() ? process.env.REACT_APP_SERVER_ADDRESS : 'http://localhost:8000';
}

export const isElementInViewport = (el) => {
	const rect = el.getBoundingClientRect();
	const heightCompare = (window.innerHeight ?? document.documentElement.clientHeight) - 64;	// -64 for the tabs bar
	const widthCompare = window.innerWidth ?? document.documentElement.clientWidth;
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= heightCompare &&
    rect.right <= widthCompare
	);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function isFileImage(file) {
	try {
		return file && file['type'].split('/')[0] === 'image'
	} catch (e) {
		Bugsnag.notify(e);
		return false;
	}
}

export function componentLoader(lazyComponent, attemptsLeft) {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft-1).then(resolve, reject);
				}, 1500);	// 1500ms pause
			})
	})
}
